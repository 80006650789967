import type { FunctionComponent } from 'react'
import React from 'react'
import { Grid, GridItem, Hr, PromoCard, Typography } from '@which/seatbelt'

import classnames from 'classnames'

import type { ImageWithSources } from '../../../../generated/frontend'
import styles from './AdditionalContent.module.scss'

export const AdditionalContent: FunctionComponent<AdditionalContentProps> = ({
  id,
  heading,
  content,
  borderBlock,
  additionalContentLinks,
}) => (
  <section
    aria-labelledby={id}
    className={classnames(styles.additionalContentWrapper, {
      [styles.additionalContentWrapperBorderBlock]: borderBlock,
    })}
  >
    <Grid className={styles.grid}>
      <GridItem>
        {borderBlock && <Hr className={styles.hr} />}
        <div className={styles.additionalContent}>
          {heading && (
            <Typography tag="h2" textStyle="title-600" id={id}>
              {heading}
            </Typography>
          )}
          {additionalContentLinks &&
            additionalContentLinks.map((additionalContentLinksSection, index) => (
              <div
                className={classnames(
                  styles.additionalContentLinksSection,
                  `width-${additionalContentLinksSection.links.length}`
                )}
                key={index}
              >
                {additionalContentLinksSection.subheading && (
                  <Typography tag="h3" textStyle="title-400" className={styles.subheading}>
                    {additionalContentLinksSection.subheading}
                  </Typography>
                )}
                <div className={styles.links}>
                  {additionalContentLinksSection.links.map((link, linkIndex) => (
                    <div className={styles.link} key={linkIndex}>
                      <PromoCard
                        {...link}
                        imageAspectRatio="two-to-one"
                        gtmProps={{
                          'data-which-id': 'producthub-card',
                          'data-section': `${heading}`,
                          'data-subsection': `${additionalContentLinksSection.subheading}`,
                          'data-index': linkIndex + 1,
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          {content && (
            <div className={styles.content} dangerouslySetInnerHTML={{ __html: content }} />
          )}
        </div>
      </GridItem>
    </Grid>
  </section>
)

///////// IMPLEMENTATION /////////

export type AdditionalContentLinks = {
  subheading?: string
  links: {
    href: string
    title: string
    image: ImageWithSources
  }[]
}[]

export type AdditionalContentProps = {
  id: string
  content?: string
  heading?: string
  borderBlock?: boolean
  additionalContentLinks?: AdditionalContentLinks
}
