/* istanbul ignore file */

import React from 'react'

import { Redirect } from '../../shared'
import type { WCDRouteProps } from '..'
import { Page } from '../components/Page'

export const moneyRoutes: WCDRouteProps = [
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(money)',
    component: () => (
      <Page pagePath="vertical/VerticalPage" template="Reviews Vertical" context="money" />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(money)/:subVerticalSlug',
    component: () => (
      <Page pagePath="sub-vertical/SubVerticalPage" template="Subvertical" context="money" />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/money/insurance/car-insurance',
    component: () => (
      <Redirect to="/money/insurance/car-insurance/car-insurance/best-and-worst-car-insurance-a3PuA5f3sB6q" />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(money)/:subVerticalSlug/:categorySlug',
    component: () => (
      <Page
        pagePath="article-listing/ArticleListingPage"
        template="Reviews Article Listing"
        context="money"
      />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(money)/:subVerticalSlug/:categorySlug/:collectionSlug/:articleSlug',
    component: () => (
      <Page pagePath="article/ArticlePage" template="Article Advice Multi Page" context="money" />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(money)/:subVerticalSlug/:categorySlug/:articleSlug',
    component: () => (
      <Page
        pagePath="article/ArticlePage"
        template="Article Advice Single Page"
        context="money"
      ></Page>
    ),
  },
]
