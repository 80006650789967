import type { FunctionComponent } from 'react'
import React from 'react'
import { NoAnswerIcon, Typography } from '@which/seatbelt'
import type { Rest } from '@which/shared'

import { AddProducts } from '..'
import styles from './EmptyCompareLandingPage.module.scss'

export const EmptyCompareLandingPage: FunctionComponent<Props> = ({
  taxonomySlug,
  numberOfProducts,
  ...rest
}) => (
  <div className={styles.emptyCompareContainer} {...rest}>
    <NoAnswerIcon data-testid="no-answer-icon" className={styles.noAnswerIcon} />
    <Typography textStyle="title-500" className={styles.heading}>
      You’ve not yet added any {taxonomySlug.replace(/-/g, ' ')} to compare.
    </Typography>
    <Typography textStyle="body" className={styles.hardcodedText}>
      Choose products to see test results and tech specs side by side.
    </Typography>
    <AddProducts
      taxonomySlug={taxonomySlug}
      data-testid="add-products"
      numberOfProducts={numberOfProducts}
    />
  </div>
)

type Props = {
  taxonomySlug: string
  numberOfProducts: number
} & Rest
