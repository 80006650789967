import React from 'react'
import { ButtonLink, ExternalLinkIcon, Typography } from '@which/seatbelt'

import type { PromoBlock as PromoBlockType } from '../../../generated/frontend'
import styles from './PromoBlock.module.scss'

export const PromoBlock = ({ title, description, ctaLabel, ctaLink }: PromoBlockType) => {
  if (!ctaLabel && !ctaLink) {
    return null
  }

  return (
    <div className="promo-block" data-testid="promo-block">
      {title && (
        <Typography tag="h2" textStyle="title-400" className={styles.PromoTitle}>
          {title}
        </Typography>
      )}
      {description && <Typography textStyle="small-print-compact">{description}</Typography>}
      <ButtonLink
        href={ctaLink}
        className={styles.PromoCta}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        <ExternalLinkIcon />
        {ctaLabel}
      </ButtonLink>
    </div>
  )
}
