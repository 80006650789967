import type { FunctionComponent } from 'react'
import React from 'react'
import { ClockIcon, Typography } from '@which/seatbelt'

import styles from './PIArticleDate.module.scss'

export const PIArticleDate: FunctionComponent<Props> = ({ estimatedReadingTime, date }) => (
  <div className={styles.piArticleDate}>
    <div className={styles.piArticleDateType}>
      <Typography textStyle="very-small-print" data-testid="article-date">
        {date}
      </Typography>
      <div className={styles.piArticleDateReading}>
        <ClockIcon
          className={styles.piArticleDateIcon}
          data-testid="article-card-text-heading-icon"
        />
        <Typography textStyle="very-small-print" data-testid="article-estimated-reading">
          {estimatedReadingTime}
        </Typography>
      </div>
    </div>
  </div>
)

type Props = {
  date: string
  estimatedReadingTime: string
}
