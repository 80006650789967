import type { FunctionComponent } from 'react'
import React from 'react'
import { Typography } from '@which/seatbelt'

import classnames from 'classnames'

import type { ArticleReviewsPageData } from '../../article-page-types'
import styles from './Header.module.scss'

const newsArticle = 'newsArticle' as const

type Props = ArticleReviewsPageData['header'] & {
  type?: typeof newsArticle
}
export const Header: FunctionComponent<Props> = ({
  addMarginOffsetClass,
  date,
  headline,
  standfirst,
  type,
}) => {
  return (
    <div className={styles.wHeaderWrapper}>
      <div
        className={classnames(styles.wInnerWrapper, type && styles[type])}
        data-testid="inner-wrapper"
      >
        {date.rendered && (
          <div className={styles.wHeaderWrapperDate} data-testid="article-date">
            <Typography tag="time" textStyle="very-small-print" dateTime={date.iso}>
              {date.rendered}
            </Typography>
          </div>
        )}

        {headline && (
          <Typography
            className={styles.headline}
            data-testid="headline"
            tag="h1"
            textStyle="title-650"
          >
            {headline}
          </Typography>
        )}

        {standfirst && (
          <div
            data-testid="standfirst-wrapper"
            className={classnames(styles.wHeaderStandfirst, {
              [styles.wHeaderStandfirstMarginOffset]: addMarginOffsetClass,
            })}
          >
            <Typography data-testid="standfirst" tag="span" textStyle="standfirst">
              {standfirst}
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}
