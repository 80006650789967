import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useToggle } from 'react-use'
import {
  BaseLink,
  ChevronDownIcon,
  CrossIcon,
  NavigationalHomeIcon,
  NavigationLink,
  Typography,
} from '@which/seatbelt'
import type { Breadcrumb as BreadcrumbType, BreadcrumbLink, LocalNavigation } from '@which/shared'

import classnames from 'classnames'
import isNil from 'lodash/isNil'

import { useFullUrl } from '../../hooks/useFullUrl'
import { checkSessionStorageExists } from '../../utils/checkSessionStorageExists'
import styles from './Breadcrumb.module.scss'
import { BreadcrumbMenu } from './BreadcrumbMenu'
import { breadcrumbJsonLd } from './utils/breadcrumbJsonLd'

export const Breadcrumb: FunctionComponent<Props> = ({
  heading,
  links,
  currentPage,
  baseUrl,
  localNavProps = null,
  className = '',
}) => {
  const { getFullUrl } = useFullUrl()
  const urlSplit = getFullUrl().split('/')

  const [urlLink, setUrlLink] = useState<BreadcrumbLink[]>(links)
  const [open, toggleOpen] = useToggle(false)
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined)
  const Icon = open ? CrossIcon : ChevronDownIcon
  const headerOnly = !urlLink || !urlLink?.length
  const menuNavProps = mapNavigation(localNavProps, { url: getFullUrl() })

  useEffect(() => {
    const policyAndInsightPage = checkSessionStorageExists('policyAndInsightPage')
      ? sessionStorage.getItem('policyAndInsightPage')
      : null

    const previousUrl = checkSessionStorageExists('policyAndInsightPage')
      ? sessionStorage.getItem('pageUrl')
      : null

    if (
      urlSplit[3] &&
      urlSplit[3] === 'policy-and-insight' &&
      policyAndInsightPage &&
      policyAndInsightPage === 'search' &&
      previousUrl &&
      !urlSplit[4].includes('search')
    ) {
      setUrlLink([{ text: 'Back to search results', href: previousUrl }])

      if (checkSessionStorageExists('policyAndInsightPage')) {
        sessionStorage.removeItem('policyAndInsightPage')
      }

      if (checkSessionStorageExists('pageUrl')) {
        sessionStorage.removeItem('pageUrl')
      }
    }
  }, [urlSplit])

  return (
    <section className={classnames(className, styles.breadcrumb)} data-testid="breadcrumb">
      {heading && (
        <header className={classnames(styles.breadcrumb, className)}>
          <Helmet>
            <script type="application/ld+json">
              {breadcrumbJsonLd({
                heading: heading,
                links: urlLink,
                currentPage: currentPage,
                baseUrl: baseUrl,
              })}
            </script>
          </Helmet>
          <div className={styles.breadcrumbWrapper}>
            <nav aria-label="Breadcrumb" className={styles.breadcrumbNav}>
              <ol className={styles.breadcrumbList}>
                <li data-testid="link-home" className={styles.hideOnMobile}>
                  <HomeIcon />
                </li>
                {heading.text && (
                  <>
                    {heading.href ? (
                      <li
                        key={heading.text}
                        className={classnames(
                          styles.breadcrumbItem,
                          urlLink && urlLink?.length && styles.hideOnMobile
                        )}
                        data-testid={`link-${heading.text.replace(/\s/g, '').toLowerCase()}`}
                      >
                        <NavigationLink
                          href={heading.href}
                          linkStyle="primary"
                          textStyle="small-print-compact"
                          data-testid="breadcrumb-header-title-link"
                          data-baseurl={baseUrl}
                          data-which-id="breadcrumb"
                          className={styles.breadcrumbHeaderLink}
                          includeAnimation={true}
                          {...(headerOnly && { icon: 'arrowLeft' })}
                        >
                          {heading.text}
                        </NavigationLink>
                      </li>
                    ) : (
                      <>
                        {headerOnly && (
                          <li data-testid="link-home" className={styles.breadcrumbHomeMobile}>
                            <HomeIcon />
                          </li>
                        )}
                        <li
                          key={heading.text}
                          data-testid={`link-${heading.text.replace(/\s/g, '').toLowerCase()}`}
                          className={classnames(
                            urlLink.length ? styles.hideOnMobile : '',
                            styles.breadcrumbItemVertical
                          )}
                        >
                          <Typography
                            textStyle="small-print-compact"
                            tag="span"
                            data-testid="breadcrumb-header-title-no-link"
                            className={styles.breadcrumbHeaderLink}
                          >
                            {heading.text}
                          </Typography>
                        </li>
                      </>
                    )}{' '}
                  </>
                )}
                {urlLink && (
                  <>
                    {urlLink.map(({ href, text }, index) => (
                      <li
                        key={text}
                        className={classnames(
                          styles.breadcrumbItem,
                          !shouldShowUrlLinkOnMobile(index, urlLink.length) && styles.hideOnMobile
                        )}
                        data-testid={`link-${text.replace(/\s/g, '').toLowerCase()}`}
                      >
                        <NavigationLink
                          href={href}
                          linkStyle="primary"
                          textStyle="small-print-compact"
                          data-baseurl={baseUrl && getBaseUrl(href, baseUrl)}
                          data-which-id="breadcrumb"
                          data-testid="breadcrumb-link"
                          className={styles.breadcrumbItemLink}
                          {...(shouldShowUrlLinkOnMobile(index, urlLink.length) && {
                            icon: 'arrowLeft',
                          })}
                        >
                          {text}
                        </NavigationLink>
                      </li>
                    ))}
                    {
                      // Note: The below current page <li> element does not render in the UI
                      // It's disabled via CSS display: none and only enabled for printing
                      currentPage?.text && (
                        <li
                          aria-hidden
                          className={classnames(
                            styles.breadcrumbItem,
                            styles.breadcrumbCurrentPage
                          )}
                          data-testid="current-page-link"
                        >
                          <NavigationLink
                            href={currentPage.href}
                            appearance="secondary"
                            textStyle="small-print-compact"
                            includeIcon={false}
                            data-baseurl={getBaseUrl(currentPage.href, baseUrl || '')}
                          >
                            {currentPage.text}
                          </NavigationLink>
                        </li>
                      )
                    }
                  </>
                )}
              </ol>
            </nav>
            {menuNavProps && (
              <button
                aria-expanded={open}
                className={classnames(styles.breadcrumbButton, open && styles.breadcrumbButtonOpen)}
                onClick={toggleOpen}
                type="button"
                data-testid="breadcrumb-menu-button"
              >
                <Typography
                  textStyle="body-intro"
                  tag="span"
                  className={styles.breadcrumbButtonText}
                >
                  Menu
                </Typography>
                <Icon className={styles.breadcrumbButtonIcon} />
              </button>
            )}
          </div>
        </header>
      )}
      {open && menuNavProps && (
        <>
          <div className={styles.breadcrumbScrim} data-testid="breadcrumb-header-scrim" />
          {typeof selectedIndex !== 'undefined' && <div className={styles.breadcrumbGradient} />}
          <BreadcrumbMenu
            className={styles.breadcrumbBreadcrumbMenu}
            {...menuNavProps}
            {...{
              toggleOpen,
              selectedIndex,
              setSelectedIndex,
            }}
          />
        </>
      )}
    </section>
  )
}

///////// IMPLEMENTATION /////////

type Props = BreadcrumbType & {
  className?: string
  localNavProps?: null | LocalNavigation[]
}

export type BreadcrumbProps = {
  heading?: {
    href?: string
    text: string
  }
  links?: BreadcrumbLink[]
  currentPage?: BreadcrumbLink | null
  baseUrl?: string
  className?: string
}

const mapNavigation = (navigation: null | LocalNavigation[], options: { url: string }) => {
  if (isNil(navigation)) {
    return null
  }

  const sectionLinks = navigation.filter((item) => !isNil(item.items))
  const navFooterLink = navigation.find((item) => isNil(item.items))

  return {
    verticalName: 'Consumer Rights',
    currentUrl: options.url,
    sections: sectionLinks?.map((item) => ({
      label: item.text,
      breadCrumbMenuContentProps: {
        links: item.items?.links?.map(({ text, href }) => ({ label: text, href })) ?? [],
        viewAllLink: isNil(item.items?.viewAllLink)
          ? null
          : {
              label: item?.items?.viewAllLink?.text ?? '',
              href: item?.items?.viewAllLink?.href ?? '',
            },
      },
    })),
    navFooterLink: isNil(navFooterLink)
      ? null
      : {
          linkText: navFooterLink?.text,
          linkHref: navFooterLink?.href,
          iconCategory: 'navigational',
          iconName: 'phone',
        },
  }
}

const getBaseUrl = (href: string, baseUrl: string) => (!href.startsWith(baseUrl) ? baseUrl : '')

const HomeIcon: FunctionComponent = () => (
  <BaseLink href={'/'}>
    <NavigationalHomeIcon />
    <span className={styles.breadcrumbHomeAriaText}>Home</span>
  </BaseLink>
)

//TODO CDS-63: Better solution for this
const shouldShowUrlLinkOnMobile = (index: number, numItems: number) => {
  const isProductHubArticleOrProductPage = numItems === 3

  if (isProductHubArticleOrProductPage) {
    return index === numItems - 2
  } else {
    return index === numItems - 1
  }
}
