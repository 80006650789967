import React, { useState } from 'react'
import type { ProductColour, TestScoreProps } from '@which/seatbelt'
import {
  AnimationWrapper,
  ColourSwatchGroup,
  Link,
  ProductScoreGauge,
  StandardLink,
  Typography,
} from '@which/seatbelt'
import { AMAZON_AFFILIATE_TAG, dynamicDatalayerPush, getTaggedAmazonUrl } from '@which/shared'

import classnames from 'classnames'

import type { ProductOffer } from '../../../../../../generated/frontend'
import { compareProductsStorageName } from '../../../../../../shared'
import { useCompareTray } from '../../../../../../shared/hooks/useCompareTray'
import { formatRetailers } from '../../../../../../shared/utils/formatRetailers'
import { ArticleTrackonomicsLink } from '../../../../../article/components'
import { ProductBadges } from '../../../../shared/components/ProductBadges/ProductBadges'
import { convertPriceValueToFloat } from '../../../../utils/convert-price-value-to-float'
import comparisonTableStyles from '../ComparisonTable.module.scss'
import styles from './ProductDetails.module.scss'
import { ProductDetailThumbnail } from './ProductDetailThumbnail'

export const Product: React.FC<ProductProps> = ({
  alertType,
  badges,
  businessKey,
  hasOneProductBadge,
  image,
  productScoreGauge,
  price,
  slug,
  taxonomySlug,
  tooltipOpenHandler,
  variants,
  offers = [],
}) => {
  const [selectedVariant, setSelectedVariant] = useState(variants?.[0])
  const { testScoreGauge, variant } = productScoreGauge
  const renderBadgesSpacer = hasOneProductBadge && !badges?.length
  const validOffers = formatRetailers(offers)
  const { compareTrayItems } = useCompareTray(compareProductsStorageName, taxonomySlug)
  const compareItem = compareTrayItems?.find((item) => item.businessKey === businessKey)

  const handleAffiliateClick = (validOffer: ProductOffer) => {
    const item_spec = new URL(validOffer.url).hostname

    dynamicDatalayerPush({
      eventCategory: 'Where to Buy',
      eventAction: 'Go to Retailer',
      eventLabel: `${
        validOffer.retailer.name
      } | ${compareItem?.manufacturer} ${compareItem?.model} | ${convertPriceValueToFloat(
        validOffer.priceValue,
        true
      )}`,
      eventValue: Math.floor(Number(convertPriceValueToFloat(validOffer.priceValue))),
      item_url: validOffer.url,
      item_spec,
      item_group: 'compare tray',
    })
  }

  return (
    <td
      key={`product-details-${businessKey}`}
      className={classnames(styles.tableData, comparisonTableStyles.tableDataRowValue)}
    >
      <ProductBadges badges={badges} />

      {renderBadgesSpacer && (
        <div className={styles.badgeSpacer} data-testid="product-details-badge-spacer"></div>
      )}
      <Link href={slug}>
        <ProductDetailThumbnail image={image} alertType={alertType} />
      </Link>
      <div className={styles.productScoreGauge}>
        <ProductScoreGauge
          {...productScoreGauge}
          testScoreGauge={testScoreGauge}
          variant={variant}
          tooltip={{ ...productScoreGauge.tooltip, openHandler: tooltipOpenHandler }}
        />
      </div>

      {price && validOffers.length === 0 && (
        <Typography textStyle="body-intro" tag={'p'} className={styles.price}>
          {price}
          <Typography
            tag="span"
            textStyle="small-print-medium"
            className={styles.typicalPriceLabel}
          >
            Typical price
          </Typography>
        </Typography>
      )}

      <ol className={styles.offersList}>
        {validOffers.map((validOffer) => {
          const { retailer, url, isTrackable, formattedPrice } = validOffer
          return (
            <li key={retailer.name} className={styles.offersListItem}>
              {isTrackable ? (
                <ArticleTrackonomicsLink
                  href={url}
                  contentType={'article'}
                  optionalTracking={{
                    item_group: 'compare tray',
                  }}
                  onClick={() => handleAffiliateClick(validOffer)}
                >
                  <Typography tag="span" textStyle="small-print-medium">
                    <AnimationWrapper>{`${formattedPrice} from ${retailer.name}`}</AnimationWrapper>
                  </Typography>
                </ArticleTrackonomicsLink>
              ) : (
                <StandardLink
                  href={getTaggedAmazonUrl(url, AMAZON_AFFILIATE_TAG)}
                  onClick={() => handleAffiliateClick(validOffer)}
                  className={styles.link}
                  aria-label={`Buy from ${retailer.name} at ${formattedPrice}`}
                  data-which-id="affiliate-link"
                  target="_blank"
                  rel="nofollow"
                >
                  <Typography tag="span" textStyle="small-print-medium">
                    <AnimationWrapper>{`${formattedPrice} from ${retailer.name}`}</AnimationWrapper>
                  </Typography>
                </StandardLink>
              )}
            </li>
          )
        })}
      </ol>

      {variants && selectedVariant && (
        <ColourSwatchGroup
          classNameRadioGroup={styles.radioGroup}
          name={`colour-swatch-group-${businessKey}`}
          onChange={setSelectedVariant}
          selectedVariant={selectedVariant}
          variants={variants}
        />
      )}
    </td>
  )
}

type ProductProps = {
  alertType: string
  badges: string[]
  businessKey?: string
  hasOneProductBadge: boolean
  image?: {
    alt: string
    caption: string
    dimensions: {
      width: string
      height: string
    }
    id: string
    renditions: string[]
    src: string
    sources: {
      media: string
      sizes: string
      srcset: string
      type: string
    }[]
  }
  productScoreGauge: {
    className?: string
    label?: string
    tooltip: {
      contents: string
      ariaLabel: string
      title?: string
    }
    testScoreGauge: TestScoreProps
    variant?: TestScoreProps['variant']
  }
  price?: string
  slug: string
  taxonomySlug: string
  tooltipOpenHandler?: () => void
  variants?: ProductColour[][] | null
  offers?: ProductOffer[]
}
