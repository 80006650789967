import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'
import { ButtonLink, CrossIcon, Label } from '@which/seatbelt'

import classnames from 'classnames'

import style from './FileUpload.module.scss'

export const FileUpload: FunctionComponent<Props> = ({
  label = '',
  buttonText = 'Click to add files or drop files here',
  maxFileSizeInBytes,
  updateFilesCallBack,
  numAttachmentsAllowed = 1,
  acceptedFileTypes = 'image/jpeg, image/jpg, image/png',
  clearFiles,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [files, setFiles] = useState({})
  const [dragActive, setDragActive] = useState(false)
  const [errorText, setErrorText] = useState('')
  const convertBytesToKB = (bytes) => Math.round(bytes / 1000)

  useEffect(() => {
    if (clearFiles) {
      setFiles({})
    }
  }, [clearFiles])

  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (numAttachmentsAllowed === Object.keys(files).length) {
      return setErrorText(
        `Already upload the max amount of attachments of ${numAttachmentsAllowed}`
      )
    }
    const prevFileCount = Object.keys(files).length
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const updatedFiles = addNewFiles(e.dataTransfer.files)

      if (
        prevFileCount === Object.keys(updatedFiles).length ||
        e.dataTransfer.files.length !== Object.keys(updatedFiles).length
      ) {
        setErrorText(
          `Please ensure all files are the correct file format & are not over ${
            maxFileSizeInBytes / 1000000
          }mb`
        )
      }
      setFiles(updatedFiles)
      updateFilesCallBack(updatedFiles)
    }
  }

  const addNewFiles = (newFiles) => {
    for (const file of newFiles) {
      if (file.size < maxFileSizeInBytes) {
        if (numAttachmentsAllowed === 1) {
          return { file }
        }
        files[file.name] = file
      }
    }
    return { ...files }
  }

  const handleNewFileUpload = (e) => {
    e.preventDefault()
    if (numAttachmentsAllowed === Object.keys(files).length) {
      return setErrorText(
        `Already upload the max amount of attachments of ${numAttachmentsAllowed}`
      )
    }
    const prevFileCount = Object.keys(files).length
    const { files: newFiles } = e.target
    if (newFiles.length) {
      const updatedFiles = addNewFiles(newFiles)
      setFiles(updatedFiles)
      updateFilesCallBack(updatedFiles)
    }

    if (prevFileCount === Object.keys(files).length) {
      setErrorText(
        'Ensure the file you are trying to attach is not one you have already attached and does not exceed the size limit'
      )
    }
  }

  const removeFile = (fileName) => {
    delete files[fileName]
    setFiles({ ...files })
    updateFilesCallBack({ ...files })
  }

  const onButtonClick = (e) => {
    e.preventDefault()
    if (inputRef.current !== null) {
      inputRef.current.click()
    }
  }

  return (
    <>
      <Label labelText={label} />
      <div
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        onSubmit={(e) => e.preventDefault()}
        className={classnames(style.uploadDropzone, dragActive ? 'drag-active' : '')}
      >
        <input
          ref={inputRef}
          type="file"
          title=""
          value=""
          onChange={handleNewFileUpload}
          accept={acceptedFileTypes}
          className="input-file-upload"
        />
        <label className="label-file-upload">
          <ButtonLink onClick={onButtonClick} className={style.uploadButton}>
            {buttonText}
          </ButtonLink>
        </label>
      </div>

      <span>{errorText}</span>

      <article className={style.uploadPoolArticle}>
        <section className={style.uploadPoolArticlefirstSection}>
          {Object.keys(files).map((fileName, i) => {
            const file = files[fileName]
            const isImageFile = file.type.split('/')[0] === 'image'
            return (
              <section className={style.uploadPoolArticlefirstSectionanotherSection} key={i}>
                <section>
                  {isImageFile && (
                    <img src={URL.createObjectURL(file)} alt={`file preview ${fileName}`} />
                  )}

                  <section className={style.uploadPoolArticlefirstSectionanotherSectionPreviewDiv}>
                    <span className={style.fileNameText}>{file.name}</span>
                    <aside>
                      <span>{convertBytesToKB(file.size)} kb</span>
                      <CrossIcon onClick={() => removeFile(fileName)} customColour="#FFF" />
                    </aside>
                  </section>
                </section>
              </section>
            )
          })}
        </section>
      </article>
    </>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  label: string
  buttonText: string
  maxFileSizeInBytes: number
  updateFilesCallBack: (files: any) => void
  numAttachmentsAllowed: number
  acceptedFileTypes?: string
  clearFiles?: boolean
}
