import type { FunctionComponent } from 'react'
import React, { useState } from 'react'
import { Dropdown, Label, Typography } from '@which/seatbelt'

import { renderArticleElements } from '../../renderers/article-element-renderer'
import { CsvTable } from '../CsvTable'
import styles from './CsvTableList.module.scss'

export const CsvTableList: FunctionComponent<Props> = (props) => {
  const { label, tables = [], enableSearch, notes } = props
  const [selectedTable, setSelectedTable] = useState(tables[0])

  if (!tables || !tables.length) {
    return null
  }

  const handleTableChange = (name) => {
    const targetTable = tables.find((table) => table.name === name)

    targetTable && setSelectedTable(targetTable)
  }

  return (
    <div className={styles.csvList}>
      <div className={styles.csvListControls}>
        <Label labelText={label} htmlFor="csv-table-selector" className={styles.csvListLabel} />
        <Dropdown
          id="csv-table-selector"
          disabled={false}
          callback={handleTableChange}
          options={tables.map((table) => ({
            label: table.name,
            value: table.name,
          }))}
          variant="regular"
          wrapperClassName={styles.csvListDropDown}
          aria-controls="selected-csv-table"
        />
      </div>

      {selectedTable && (
        <div id="selected-csv-table" role="region" aria-live="polite">
          <Typography tag="h2" textStyle="title-600" className={styles.csvListLabel}>
            {selectedTable.name}
          </Typography>
          <CsvTable
            {...selectedTable}
            renderArticleElements={renderArticleElements}
            enableSearch={enableSearch}
            key={selectedTable.name}
          />
        </div>
      )}

      {notes && (
        <div
          data-testid="csv-table-note"
          className={styles.tableNotes}
          dangerouslySetInnerHTML={{ __html: notes || '' }}
        ></div>
      )}
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  enableSearch: boolean
  label: string
  tables: Table[]
  notes?: string
}
type ArticleElement = {
  sortableValue?: number | string
  tagName: string
  props?: Record<string, unknown>
  innerText?: string
  children?: ArticleElement[]
}
type TableBodyContent = string | ArticleElement
type Table = {
  id: string
  headings: string[]
  columnDataTypes: SortType[]
  tableBody: TableBodyContent[][]
  name: string
  notes?: string
}

type SortType = 'text' | 'stars' | 'percentage'
