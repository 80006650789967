import type { FunctionComponent } from 'react'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { ButtonLink as SBButton, Typography } from '@which/seatbelt'
import { dynamicDatalayerPush } from '@which/shared'

import { formatToolName } from '../../utils/format-tool-name'
import { hasValidCRToolEntryOptions } from '../../utils/has-valid-cr-tool-entry-options'
import { tryParseJSON } from '../../utils/try-parse-json'
import styles from './CRToolEntryPoint.module.scss'

export const CRToolEntryPoint: FunctionComponent<Props> = ({
  datalayer,
  options_json,
  title,
  button_text,
  standfirst,
  question_text,
  tool_name,
  tool_url,
}) => {
  const [selection, setSelection] = useState('product')
  const buttonText = button_text
  const questionText = question_text
  const toolName = formatToolName(tool_name)
  if (
    (options_json && !hasValidCRToolEntryOptions(tryParseJSON(options_json))) ||
    !Array.isArray(tryParseJSON(standfirst))
  ) {
    return null
  }
  const standfirstOptions: string[] = JSON.parse(standfirst)

  let href = tool_url
  const options = options_json ? JSON.parse(options_json) : []
  if (options_json) {
    const chosenOption = options.filter(({ value }) => value === selection)
    if (chosenOption[0].external) {
      href = chosenOption[0].link
    } else {
      href = `${tool_url}?type=${chosenOption[0].link}`
    }
  }

  const handleClick = () => {
    dynamicDatalayerPush({
      eventCategory: 'problem resolution tools',
      eventAction: toolName,
      eventLabel: `question | product-type | ${selection}`,
      eventValue: 0,
      eventInteractive: false,
      event: 'tEvent',
    })
  }

  const shouldAddDataLayer = () => {
    if (!datalayer) {
      return
    }

    return (
      <Helmet>
        <script>
          {`window.dataLayer = window.dataLayer || []

          window.dataLayer.push({
            'toolSite':'consumer-rights',
            'vertical':'consumer-rights',
            'prtToolName':'faulty goods',
            'content_type':'problem resolution tools',
            'topicName':'Tools',
            'subtopicName':'faulty goods',
          });
        `}
        </script>
      </Helmet>
    )
  }

  return (
    <div className={styles.CRToolEntryPoint} data-testid="cr-tool-entry-point">
      {shouldAddDataLayer()}
      <Typography className={styles.CRToolEntryPointTitle} textStyle="title-400">
        {title}
      </Typography>
      {standfirstOptions.map((standFirstItem) => {
        return (
          <Typography key={standFirstItem} textStyle="body">
            {standFirstItem}
          </Typography>
        )
      })}
      {options_json && (
        <>
          <Typography className={styles.CRToolEntryPointQuestion} textStyle="body-intro">
            {questionText}
          </Typography>
          <select
            name="tool-choice"
            id="tool-choice"
            data-testid="cr-tool-datalist-items"
            className={styles.CRToolEntryPointDropdown}
            onChange={(e) => {
              setSelection(e.target.value)
            }}
          >
            {options.map((option) => {
              const { label, value } = option
              return (
                <option key={value} value={value}>
                  {label}
                </option>
              )
            })}
          </select>
        </>
      )}
      <SBButton
        data-testid="cr-tool-link"
        href={href}
        className={styles.CRToolEntryPointButton}
        onClick={handleClick}
      >
        {buttonText}
      </SBButton>
    </div>
  )
}

type Props = {
  datalayer: boolean
  options_json: string
  title: string
  button_text: string
  standfirst: string
  question_text: string
  tool_name: string
  tool_url: string
}
