import type { FunctionComponent, ReactNode } from 'react'
import React from 'react'
import { Typography } from '@which/seatbelt'

import styles from './PIArticleSection.module.scss'

export const PIArticleSection: FunctionComponent<Props> = ({
  children,
  sectionHeader,
  sectionText,
}) => {
  return (
    <section data-testid="ea-article-section" className={styles.articleSectionWrapper}>
      <div className={styles.articleSection}>
        <div>
          <Typography
            data-testid="ea-article-heading"
            tag="h1"
            textStyle="title-600"
            className={styles.articleSectionHeader}
          >
            {sectionHeader}
          </Typography>
          <Typography className={styles.articleSectionText} data-testid="ea-article-text">
            {sectionText}
          </Typography>
        </div>

        <div data-testid="ea-article-articles" className={styles.articleSectionArticles}>
          {children}
        </div>
      </div>
    </section>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  children?: ReactNode
  sectionHeader: string
  sectionText: string
}
