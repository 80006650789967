import type { FunctionComponent } from 'react'
import React from 'react'
import { Grid, GridItem, Picture, Typography } from '@which/seatbelt'

import classnames from 'classnames'

import { PIRole } from '../PIRole'
import { PISearchBox } from '../PISearchBox'
import styles from './PIHero.module.scss'

export const PIHero: FunctionComponent<Props> = ({
  title,
  standfirst,
  searchBar,
  basic = false,
  subtext = '',
  buttonsRole = [{ id: '', displayText: '', href: '' }],
  image,
}) => {
  return (
    <Grid
      className={classnames(styles.heroWrapper, { [styles.heroWrapperBasic]: basic })}
      data-testid="ea-hero"
    >
      <GridItem
        span={{ large: basic ? 12 : 6 }}
        columnStart={{ large: 1 }}
        className={styles.content}
      >
        {!basic && (
          <>
            <Typography tag="h1" textStyle="title-700" data-testid="ea-hero-title">
              {title}
            </Typography>
            <Typography
              tag="p"
              className={styles.standfirst}
              textStyle="body"
              data-testid="ea-hero-standfirst"
            >
              {standfirst}
            </Typography>
          </>
        )}
        <PISearchBox {...searchBar} />
        {!basic && <PIRole headingText={subtext} buttons={buttonsRole} />}
      </GridItem>

      {!basic && (
        <GridItem
          span={{ large: 6 }}
          columnStart={{ large: 7 }}
          className={styles.heroWrapperImageContainer}
        >
          <Picture src={image} aspectRatioMain="three-to-one" />
        </GridItem>
      )}
    </Grid>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  title: string
  standfirst: string
  searchBar: { placeholderText: string; buttonText: string }
  basic?: boolean
  subtext?: string | ''
  buttonsRole?: Array<{ id: string; displayText: string; href: string }>
  image: string
}
