import type { FunctionComponent } from 'react'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import type { ApolloError } from '@apollo/client'

import { ThemeProvider } from '../../providers'
import { usePageProps } from '../../usePageProps'
import { getErrorStatusCode } from '../../utils'
import { GenericError } from '../GenericError'
import { News404Error } from '../News404Error'

export const Error: FunctionComponent<Props> = ({ error }) => {
  const { context } = usePageProps()
  const errorCode = getErrorStatusCode(error)

  logToConsole(error)

  return (
    <>
      <ThemeProvider>
        <Helmet title={getPageTitle(errorCode)} />
        {context === 'news' && errorCode === '404' ? (
          <News404Error message={error.message} />
        ) : (
          <GenericError error={error} />
        )}
      </ThemeProvider>
    </>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  error: ApolloError
}

const logToConsole = (error: ApolloError): void => {
  const { graphQLErrors, networkError, message } = error

  if (networkError) {
    console.error(`NETWORK ERROR: ${message}`)
  } else {
    console.error('APPLICATION ERROR:', graphQLErrors[0].extensions)
  }
}

const getPageTitle = (errorCode: string | number) => {
  return (
    {
      '404': 'Which? Page not found',
      '500': 'Which? Something went wrong',
    }[errorCode] || 'Which? Something went wrong'
  )
}
