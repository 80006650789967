import type { FunctionComponent } from 'react'
import React, { useEffect } from 'react'
import { AnimationWrapper, Button, Link, PhoneIcon } from '@which/seatbelt'

import { isProd, loadScript } from '../../../../shared/utils'

export const CallUs: FunctionComponent<Props> = ({
  type,
  liveChat,
  linkUrl,
  linkId,
  buttonText,
}) => {
  useEffect(() => {
    if (liveChat) {
      if (!isProd()) {
        loadScript({
          src: 'https://www.which.co.uk/static/deliver/livechat.js',
        })
      }
      loadScript({
        src: 'https://www.which.co.uk/static/deliver/livechatButton.js',
      })
    }
  }, [liveChat])

  // @TODO: phone icon needs adding to LinkIcon mapper in SB then we can just pass in icon prop to Link
  return (
    <div data-testid="call-us">
      {liveChat ? (
        <Button appearance={type} id={linkId}>
          {buttonText}
        </Button>
      ) : (
        <Link href={linkUrl} noAnimation={true}>
          <PhoneIcon /> <AnimationWrapper>{buttonText}</AnimationWrapper>
        </Link>
      )}
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  type: 'primary' | 'secondary'
  buttonText: string
  linkUrl: string
  liveChat?: boolean
  linkId?: string
}
