import type { ComponentPropsWithoutRef, FunctionComponent } from 'react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import type { ProductScoreGaugeProps } from '@which/seatbelt'
import {
  ButtonLink,
  CardRow,
  ChevronRightIcon,
  Grid,
  GridItem,
  ProductCardPortrait,
  SolidPadlockIcon,
  StandardLink,
  Typography,
} from '@which/seatbelt'
import { dynamicGa4DataLayerPush } from '@which/shared'

import classnames from 'classnames'

import type { Link, ProductHubPage } from '../../../../generated/frontend'
import { useMatchMedia } from '../../../../shared/hooks/useMatchMedia'
import styles from './ProductHubReviews.module.scss'

export const ProductHubReviews: FunctionComponent<ProductHubReviewsProps> = ({
  isLoggedIn,
  products,
  productSlug,
  heading,
  description,
  buttonCTAText,
  buttonCTAHref,
  memberExclusiveCard,
}) => {
  const isTabletOrAbove = useMatchMedia('(min-width: 768px)')
  const history = useHistory()

  return (
    <section aria-labelledby="product-reviews" className={styles.reviewsWrapper}>
      <Grid className={styles.grid}>
        <GridItem>
          <Typography
            id="product-reviews"
            tag="h2"
            textStyle="title-600"
            className={styles.reviewsHeading}
          >
            {heading}
          </Typography>
          <Typography textStyle="standfirst" className={styles.reviewsStandfirst}>
            {description}
          </Typography>
        </GridItem>
        <GridItem
          span={{
            small: 'full-bleed',
            medium: 'full-bleed',
            large: 'full-bleed',
            xlarge: 'full-bleed',
          }}
        >
          {!isLoggedIn && !isTabletOrAbove && (
            <MemberExclusiveCard
              {...memberExclusiveCard}
              className={styles.reviewsMobileMemberCard}
            />
          )}
          <CardRow className={styles.reviewsCardRow}>
            {!isLoggedIn && isTabletOrAbove && (
              <MemberExclusiveCard
                {...memberExclusiveCard}
                className={styles.reviewsDesktopMemberCard}
              />
            )}
            {products.map(
              (
                { image, manufacturer, model, price, tags, retailers, productScoreGauge, slug },
                index
              ) => {
                const url = `/reviews/${productSlug}/${slug}`

                return (
                  <ProductCardPortrait
                    key={index}
                    className={styles.reviewsProductCard}
                    image={image}
                    manufacturer={manufacturer}
                    model={model}
                    price={price}
                    retailers={retailers}
                    slug={slug}
                    tags={tags}
                    productScoreGauge={productScoreGauge as ProductScoreGaugeProps}
                    cardCallback={() => {
                      dynamicGa4DataLayerPush({
                        event: 'click_card',
                        item_text: `${manufacturer} ${model}`,
                        item_url: url,
                        item_parent_text: 'Product Reviews',
                        item_index: index + 1,
                      })
                      history.push(url)
                    }}
                    productUrl={`/reviews/${productSlug}/${slug}`}
                    taxonomySlug={productSlug}
                    businessKey={''}
                    handleAddCompare={() => null}
                    handleRemoveCompare={() => null}
                  />
                )
              }
            )}
          </CardRow>
          <div className={styles.reviewsButtonWrapper}>
            <ButtonLink
              href={buttonCTAHref}
              appearance="secondary"
              data-which-id="producthub-button"
            >
              <ChevronRightIcon />
              {buttonCTAText}
            </ButtonLink>
          </div>
        </GridItem>
      </Grid>
    </section>
  )
}

///////// IMPLEMENTATION /////////

const MemberExclusiveCard: FunctionComponent<MemberExclusiveCardProps> = ({
  heading,
  description,
  joinLink,
  loginLink,
  disclaimer,
  className,
  ...rest
}) => (
  <article
    aria-labelledby="member-exclusive-card-heading"
    className={classnames(className, styles.exclusiveCard)}
    {...rest}
  >
    <Typography
      tag="h3"
      id="member-exclusive-card-heading"
      textStyle="title-400"
      className={styles.exclusiveCardHeading}
    >
      {heading}
    </Typography>
    <Typography className={styles.exclusiveCardText}>{description}</Typography>
    <div className={styles.exclusiveCardLinkWrapper}>
      <ButtonLink
        href={joinLink.href}
        className={styles.exclusiveCardJoinLink}
        data-which-id="producthub-cta"
      >
        <SolidPadlockIcon data-testid="padlock-icon" width={15} height={15} viewBox="0 0 15 15" />
        {joinLink.text}
      </ButtonLink>
      <Typography tag="span" className={styles.exclusiveCardJoinText}>
        {' '}
        or{' '}
      </Typography>
      <StandardLink className={styles.exclusiveCardLoginLink} href={loginLink.href}>
        {loginLink.text}
      </StandardLink>
    </div>
    <Typography>{disclaimer}</Typography>
  </article>
)

export type ProductHubReviewsProps = ProductHubReviewsHardcodedProps &
  Pick<ProductHubPage, 'isLoggedIn' | 'products'>

export type ProductHubReviewsHardcodedProps = {
  productSlug: string
  heading: string
  description: string
  buttonCTAText: string
  buttonCTAHref: string
  memberExclusiveCard: MemberExclusiveCardProps
}

type MemberExclusiveCardProps = {
  heading: string
  description: string
  joinLink: Link
  loginLink: Link
  disclaimer: string
} & ComponentPropsWithoutRef<'article'>
