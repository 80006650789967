import type { FunctionComponent } from 'react'
import React from 'react'
import { Grid, GridItem, StandardLink, Typography } from '@which/seatbelt'

import styles from './ProductHubRights.module.scss'

export const ProductHubRights: FunctionComponent<ProductHubRightsProps> = ({
  heading,
  content,
}) => (
  <section aria-labelledby="your-rights">
    <Typography tag="h2" textStyle="title-600" id="your-rights">
      {heading}
    </Typography>

    <Grid includeGutters={false}>
      {content.map((block) => {
        const key = block.heading.replace(/\s+/g, '-').toLowerCase()
        return (
          <GridItem
            key={key}
            className={styles.rightsContentBlock}
            columnStart={{ medium: 1, large: 1 }}
            span={{ medium: 8, large: 8 }}
          >
            <Typography tag="h3" textStyle="title-400">
              {block.heading}
            </Typography>
            <p>{block.text}</p>

            <ul className={styles.linkList}>
              {block.links.map((link, index) => (
                <li key={link.href}>
                  <StandardLink
                    href={link.href}
                    data-which-id="producthub-link"
                    data-section={heading.toLowerCase()}
                    data-subsection={block.heading.toLowerCase()}
                    data-index={index + 1}
                  >
                    {link.text}
                  </StandardLink>
                </li>
              ))}
            </ul>
          </GridItem>
        )
      })}
    </Grid>
  </section>
)

///////// IMPLEMENTATION /////////

export type ProductHubRightsProps = {
  heading: string
  content: ProductHubRightsContent[]
}

type ProductHubRightsContent = {
  heading: string
  text: string
  links: Array<{
    text: string
    href: string
  }>
}
