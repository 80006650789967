export const getTaggedAmazonUrl = (url, tag) => {
  const isAmazonLinkMissingValidTag = new RegExp(`^(?!.*(?:[?&]tag=${tag}(?:&|$)))(https?:\/\/(?:www\.)?amazon\.(?:com|co\.uk)\/(?:[^/?#]*\/)?[^/?#]+)`).test(url);
  if (isAmazonLinkMissingValidTag) {
    try {
      const amazonUrl = new URL(url);
      amazonUrl.searchParams.set('tag', tag);
      return amazonUrl.toString();
    } catch {
      /* istanbul ignore next */
      return url;
    }
  }
  return url;
};