import type { FunctionComponent } from 'react'
import React, { useState } from 'react'
import { Button, CardRow, Modal, Picture, SmallSearchIcon } from '@which/seatbelt'

import styles from './PICarousel.module.scss'

export const PICarousel: FunctionComponent<Props> = ({ slides, label }) => {
  const [modalOpen, SetModalOpen] = useState(false)
  const [lightboxImageObj, setLightboxImageObj] = useState({ src: '', alt: '' })
  const cardRowTitle = label ? label : 'Visual data in article'

  const openModalHandler = (src: string, alt: string) => {
    setLightboxImageObj({ src, alt })
    SetModalOpen(true)
  }

  const closeModalHandler = () => {
    SetModalOpen(false)
  }

  if (!slides?.length) {
    return null
  }

  const renderSlides = () =>
    slides?.map(({ src, alt }) => (
      <article className={styles.imageWrapper} key={src} data-testid="ea-carousel-slide">
        <Picture src={src} alt={alt} aspectRatioMain="four-to-three" />
        <Button
          data-testid="ea-carousel-lightbox-button"
          appearance="secondary"
          className={styles.iconWrapper}
          onClick={() => openModalHandler(src, alt)}
        >
          <SmallSearchIcon />
        </Button>
      </article>
    ))

  return (
    <div className={styles.visualDataCarousel} data-testid="ea-visual-data-carousel">
      <CardRow title={cardRowTitle}>{renderSlides()}</CardRow>
      {modalOpen && (
        <Modal closeModal={closeModalHandler}>
          <Picture
            alt={lightboxImageObj.alt}
            aspectRatioMain="four-to-three"
            className={styles.pictureContainer}
            src={lightboxImageObj.src}
          />
        </Modal>
      )}
    </div>
  )
}

type Props = {
  label?: string
  slides?: {
    src: string
    alt: string
  }[]
}
