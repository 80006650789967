import type { FunctionComponent } from 'react'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import type { NewsletterSource } from '@which/glide-ts-types'
import { Loader, TickIcon, Typography } from '@which/seatbelt'
import { dynamicDatalayerPush } from '@which/shared'

import classnames from 'classnames'

import { sourceMap } from '../../constants/newsletter-source-map'
import { getEnvironment, isLocal } from '../../utils'
import styles from './NewsletterSignUp.module.scss'
import { NewsletterSignUpForm } from './NewsletterSignUpForm'

export const NewsletterSignUp: FunctionComponent<Props> = ({ className, source }) => {
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  if (!sourceMap[source]) {
    return null
  }

  const {
    header,
    description,
    successHeader,
    successText,
    unsubscribeText,
    buttonText = 'Get newsletter',
    withAllFields,
  } = sourceMap[source]

  const onSignUp = async (data: NewsletterFormData) => {
    const errorMessage = 'Failed to subscribe you, please try again later.'

    setLoading(true)

    const finalData = removeEmpty(data)

    try {
      const fetchResponse = await fetch(`${getAPIEndPoint()}subscribe`, {
        method: 'post',
        body: JSON.stringify({
          ...finalData,
          source: source.toUpperCase(),
          status: 'ACTIVE',
        }),
      })

      if (fetchResponse.ok) {
        setLoading(false)
        setSuccess(true)
        handleTracking(source)
      }

      if (fetchResponse.status > 300) {
        setLoading(false)
        setErrorMsg(errorMessage)
      }
    } catch (err) {
      setLoading(false)
      setErrorMsg(errorMessage)
    }
  }

  return (
    <div
      className={classnames(
        className,
        styles.newsletterSignUp,
        styles[`newsletterSignUpWrapper-${source}`]
      )}
      data-testid="newsletter-sign-up"
      id={`${source}-newsletter-sign-up`}
    >
      <Helmet>
        <script>{initialiseDataLayer(source)}</script>
      </Helmet>
      {loading && (
        <div className={styles.processing}>
          <div className={styles.processingLoader}>
            <p>Processing...</p>
            <Loader />
          </div>
        </div>
      )}
      {success && (
        <div className={styles.successContainer}>
          <div>
            <div className={styles.successHeaderContainer}>
              <div className={styles.successHeaderIcon}>
                <TickIcon />
              </div>
              <Typography className={styles.successHeader}>Congratulations</Typography>
            </div>
            <Typography className={styles.successSubHeader}>{successHeader}</Typography>
            <div className={styles.successText}>{successText}</div>
          </div>
        </div>
      )}
      {!loading && !success && (
        <NewsletterSignUpForm
          onSignUp={onSignUp}
          buttonText={buttonText}
          withAllFields={withAllFields}
          description={description}
          unsubscribeText={unsubscribeText}
          header={header}
          onFocus={onFocus}
          errorMsg={errorMsg}
        />
      )}
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  className?: string
  source: NewsletterSource
}

const onFocus = () => {
  fetch(`${getAPIEndPoint()}status`)
}

const getAPIEndPoint = () => {
  const testUrl = 'https://test-marketingcloud-newsletter-api.components.product.which.co.uk/api/'

  if (isLocal()) {
    return testUrl
  }

  switch (getEnvironment()) {
    case 'prod':
      return 'https://prod-marketingcloud-newsletter-api.components.product.which.co.uk/api/'
    case 'preprod':
      return 'https://preprod-marketingcloud-newsletter-api.components.product.which.co.uk/api/'
    default:
      // test to be used for anything non-prod
      return testUrl
  }
}

const removeEmpty = (data) => {
  return Object.keys(data)
    .filter((key) => data[key] !== '')
    .reduce((acc, key) => ({ ...acc, [key]: data[key] }), {})
}

const handleTracking = (source: string) =>
  dynamicDatalayerPush({
    eventCategory: 'Newsletters',
    eventAction: source,
    eventLabel: 'Widget | Signup',
  })

const initialiseDataLayer = (source: string) => {
  return `
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({"newsletterWidget": "${source}"})
  `
}

export type NewsletterFormData = {
  email: string
  firstName?: string
  lastName?: string
  postcode?: string
}
