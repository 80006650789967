import type { FunctionComponent } from 'react'
import React from 'react'
import { ButtonLink, Typography } from '@which/seatbelt'
import { dynamicDatalayerPush } from '@which/shared'

import styles from './HeroImageWithText.module.scss'

export const HeroImageWithText: FunctionComponent<Props> = ({
  title,
  image,
  link,
  triggerCtaTracking,
}) => {
  const handleTriggerCtaTracking = () => {
    dynamicDatalayerPush({
      eventCategory: 'problem resolution tools',
      eventAction: 'used car',
      eventLabel: 'cta - click',
      eventValue: 0,
      eventInteractive: false,
      event: 'tEvent',
    })
  }

  return (
    <section className={styles.root}>
      <img data-testid="img" src={image.src} alt="" loading="lazy" />
      <div className={styles.content}>
        <Typography textStyle="title-700" tag="h1" data-testid="title-hero-image-with-text">
          {title}
        </Typography>
        <ButtonLink
          href={link.href}
          {...(triggerCtaTracking && { onClick: () => handleTriggerCtaTracking() })}
        >
          {link.text}
        </ButtonLink>
      </div>
    </section>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  title: string
  image: {
    src: string
  }
  link: {
    href: string
    text: string
  }
  triggerCtaTracking: boolean
}
