import type { FunctionComponent, KeyboardEvent } from 'react'
import React from 'react'
import { ButtonLink, Grid, GridItem, LinkButton } from '@which/seatbelt'

import classnames from 'classnames'

import { AddProducts } from '../../../../pages/reviews/product-comparison/components'
import { useTabLoop } from '../../../hooks/useTabLoop'
import { usePageProps } from '../../../usePageProps'
import { SkipButton } from '../..'
import { CompareCard } from '../CompareCard/CompareCard'
import type { Props as CompareTrayProps } from '../ReviewsCompareTray'
import styles from '../ReviewsCompareTray.module.scss'

export const Tray: FunctionComponent<Props> = ({
  className,
  compareHandler,
  handleEnterPress,
  products = [],
  removeAllHandler,
  removeHandler,
  taxonomySlug,
}) => {
  const { firstElementRef, lastElementRef, containerTabEventHandler } = useTabLoop()
  const { template } = usePageProps()
  const isProductPage = template === 'Reviews Product Page'

  return (
    <div
      className={classnames(className, styles.baseTray)}
      onKeyDown={containerTabEventHandler}
      data-testid="reviews-compare-tray"
    >
      <Grid className={styles.trayGrid}>
        <GridItem span={{ medium: 12, large: 10 }}>
          <ul className={styles.list}>
            {products.map(({ manufacturer, model, slug, businessKey }, index) => {
              return (
                <li key={businessKey}>
                  <CompareCard
                    {...{
                      handleEnterPress,
                      manufacturer,
                      model,
                      removeItem: () => removeHandler(businessKey),
                      slug,
                    }}
                    ref={index === 0 ? firstElementRef : undefined}
                  />
                </li>
              )
            })}
            {products.length < 4 && isProductPage && (
              <li>
                <AddProducts
                  taxonomySlug={taxonomySlug}
                  numberOfProducts={products?.length || 0}
                  className={styles.addProducts}
                  data-testid="add-products"
                />
              </li>
            )}
          </ul>
        </GridItem>
        <GridItem
          className={styles.buttonWrapper}
          span={{ medium: 12, large: 2 }}
          columnStart={{ large: 11 }}
        >
          <ButtonLink
            className={products.length > 1 ? styles.compareButton : styles.compareButtonDisabled}
            href={`/reviews/${taxonomySlug}/compare`}
            onClick={compareHandler}
            disabled={products.length < 2}
            data-testid="reviews-compare-button"
          >
            Compare
          </ButtonLink>
          <SkipButton
            className={styles.compareTraySkipButton}
            selector='article[data-testid="sb-product-card"]'
          >
            Skip to Products
          </SkipButton>
          <SkipButton
            className={styles.compareTraySkipButton}
            selector='button[aria-label*="current page"]'
          >
            Skip to end of content
          </SkipButton>
          <LinkButton
            onClick={removeAllHandler}
            onKeyPress={handleEnterPress(removeAllHandler)}
            tabIndex={0}
            data-testid="reviews-compare-remove-all-button"
            forwardRef={lastElementRef}
          >
            Remove all
          </LinkButton>
        </GridItem>
      </Grid>
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = CompareTrayProps & {
  handleEnterPress: (callback: (() => void) | undefined) => (e: KeyboardEvent) => void
}
