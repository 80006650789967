import type { FunctionComponent, KeyboardEvent, Ref } from 'react'
import React, { forwardRef } from 'react'
import { CrossIcon, Typography } from '@which/seatbelt'

import classnames from 'classnames'

import styles from './CompareCard.module.scss'

export const CompareCard: FunctionComponent<Props> = forwardRef(
  ({ className, handleEnterPress, manufacturer, model, removeItem, slug }, ref) => (
    <div className={classnames(className, styles.item)} data-testid="reviews-compare-product">
      <Typography
        className={classnames(styles.manufacturer, styles.cardText)}
        tag="p"
        textStyle="body-intro"
      >
        {manufacturer}
      </Typography>
      <Typography tag="p" textStyle="small-print-compact" className={styles.cardText}>
        {model}
      </Typography>
      <button
        aria-label={`Remove ${manufacturer} ${model}`}
        className={styles.removeButton}
        onClick={removeItem}
        onKeyPress={handleEnterPress(removeItem)}
        data-testid={`compare-tray-remove-${slug}`}
        ref={ref}
      >
        <CrossIcon />
      </button>
    </div>
  )
)

//////IMPLEMENTATION//////

type Props = {
  className?: string
  handleEnterPress: (callback: (() => void) | undefined) => (e: KeyboardEvent) => void
  manufacturer: string
  model: string
  removeItem: () => void
  slug: string
  ref?: Ref<HTMLButtonElement>
}
