import { dynamicGa4DataLayerPush } from '@which/shared'

import { GrowthBook } from '@growthbook/growthbook-react'

import { isLocal, isProd } from '../../../utils'

const trackingCallbackServer = (experiment, result) => {
  console.log('Viewed Experiments', {
    experimentId: experiment.key,
    variationId: result.key,
  })
}

const trackingCallbackClient = (experiment, result) => {
  dynamicGa4DataLayerPush({
    event: 'experiment',
    experiment_id: experiment.key,
    variation_id: result.key,
  })
  console.log('Viewed Experiments on Client', {
    experimentId: experiment.key,
    variationId: result.key,
  })
}

export const getExperimentInstanceServer = async (expAttributes: ExpAttributes) => {
  try {
    const { gbUserId, wmCookie } = expAttributes
    const experimentAttributes = { id: gbUserId, wmCookie: wmCookie }

    if (
      process.env.GROWTHBOOK_CLIENT_KEY === undefined ||
      process.env.GROWTHBOOK_API_HOST === undefined
    ) {
      throw Error('Growthbook Env vars unset, experiments may not give the correct treatments')
    }

    const gbInstance = new GrowthBook({
      apiHost: process.env.GROWTHBOOK_API_HOST,
      clientKey: process.env.GROWTHBOOK_CLIENT_KEY,
      attributes: experimentAttributes,
      enableDevMode: !isProd() || isLocal(),
      trackingCallback: trackingCallbackServer,
    })

    await gbInstance.loadFeatures({ autoRefresh: true })

    return gbInstance
  } catch (error) {
    console.error('Error connecting to Growthbook - using a minimal GB instance')
    console.error(error)

    return new GrowthBook({
      features: {},
      attributes: {},
      enableDevMode: false,
      trackingCallback: trackingCallbackServer,
    })
  }
}

export const getExperimentInstanceClient = (props: {
  featuresStr?: string
  attributesStr?: string
}) => {
  const features = parseStringSafely(props.featuresStr)
  const attributes = parseStringSafely(props.attributesStr)

  const gbInstance = new GrowthBook({
    features,
    attributes,
    enableDevMode: !isProd() || isLocal(),
    trackingCallback: trackingCallbackClient,
  })

  return gbInstance
}

/**
 * We want to handle errors gracefully - e.g. swallow any exception and log an error.
 * Growthbook issues should not bring down WCD
 */
const parseStringSafely = (jsonString?: string) => {
  try {
    if (jsonString === undefined) {
      console.warn('Unable to parse Growthbook string - Experiments may not behave as expected')
      return {}
    }

    const parsed = JSON.parse(jsonString)

    return parsed
  } catch (err) {
    console.error(err)
    return {}
  }
}

type ExpAttributes = {
  gbUserId: string
  wmCookie: string
}
