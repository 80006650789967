import type { FunctionComponent } from 'react'
import React from 'react'
import { Typography } from '@which/seatbelt'
import type { ApolloError } from '@apollo/client'

import { useRegex } from '../../hooks/useRegex'
import { getErrorStatusCode } from '../../utils'
import { Status } from '../StatusContext'
import styles from './GenericError.module.scss'
import imagePNG from './Toast.png'
import imageWEBP from './Toast.webp'

export const GenericError: FunctionComponent<Props> = ({ error }) => {
  const { message } = error
  const errorCode = getErrorStatusCode(error)
  const errorMessage =
    errorCode === '404'
      ? 'The page you’re looking for isn’t here.'
      : "There's a problem with our website."
  const { isCRurl } = useRegex()

  return (
    <Status code={errorCode}>
      <div className={styles.wrapper} data-testid={isCRurl() ? 'consumer-rights-error' : 'error'}>
        <div className={styles.container}>
          <Typography tag="h1" textStyle="title-600" className={styles.heading}>
            Oops!
          </Typography>
          <Typography textStyle="small-print-compact" className={styles.code} data-error={message}>
            {errorCode + ' error'}
          </Typography>
          <Typography textStyle="body" className={styles.text}>
            {errorMessage}
          </Typography>
        </div>
        <picture>
          <source srcSet={imageWEBP} type="image/webp" />
          <source srcSet={imagePNG} type="image/png" />
          <img
            className={styles.image}
            alt="Toaster with burnt toast."
            src={imagePNG}
            loading="lazy"
          />
        </picture>
      </div>
    </Status>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  error: ApolloError
}
