import type { FunctionComponent } from 'react'
import React from 'react'
import { Link, StandardLink, Typography } from '@which/seatbelt'
import type { LinkTextStyles } from '@which/seatbelt-shared'

import styles from './LinkPromo.module.scss'

export const LinkPromo: FunctionComponent<Props> = ({
  linkUrl,
  linkLabel,
  promoHeader,
  promoDescription,
  externalLink,
  textStyle,
}) => {
  return (
    <div data-testid="promo-link" className={styles.wrapper}>
      <div className={styles.linkWrapper}>
        {externalLink ? (
          <StandardLink href={linkUrl} target="_blank">
            {linkLabel}
          </StandardLink>
        ) : (
          <Link appearance="primary" containIcon={true} href={linkUrl} textStyle={textStyle}>
            {linkLabel}
          </Link>
        )}
      </div>
      {promoHeader && (
        <Typography className={styles.promoHeader} textStyle="title-300">
          {promoHeader}
        </Typography>
      )}
      <Typography textStyle="body">{promoDescription}</Typography>
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  linkUrl: string
  linkLabel?: string
  promoHeader?: string
  promoDescription?: string
  externalLink?: boolean
  textStyle?: LinkTextStyles
}
