import type { FunctionComponent } from 'react'
import React from 'react'
import type { SocialIconName } from '@which/seatbelt'
import { LocalFooter as SBLocalFooter } from '@which/seatbelt'

import isNil from 'lodash/isNil'

import styles from './LocalFooter.module.scss'

export const LocalFooter: FunctionComponent<Props> = ({ data }) => {
  if (isNil(data)) {
    return null
  }

  return (
    <SBLocalFooter
      className={styles.localFooter}
      strapline={data.description ?? ''}
      accordionProps={data.items.map((item) => ({
        label: item.title,
        links: item.links,
      }))}
      followLinks={data.social.map((item) => ({
        linkText: item.text,
        href: item.href,
        icon: item.type as SocialIconName,
      }))}
    />
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  data: null | ConsumerRightsFooter
}

type ConsumerRightsFooter = {
  title?: string
  description: null | string
  items: ConsumerRightsFooterItem[]
  social: {
    text: string
    href: string
    type: string
  }[]
}

type ConsumerRightsFooterItem = {
  title: string
  links: {
    text: string
    href: string
  }[]
}
