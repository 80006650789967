import type { FormEvent, FunctionComponent } from 'react'
import React, { useState } from 'react'
import { Button, FormField, Input, Picture, Typography } from '@which/seatbelt'
import type { ImageWithSources } from '@which/shared'

import styles from './BroadbandSwitchCTA.module.scss'
import { defaultImage } from './constants'

interface Props {
  title?: string
  description?: string
  image?: ImageWithSources
}

export const BroadbandSwitchCTA: FunctionComponent<Props> = ({
  title = 'Compare broadband deals',
  description = 'Use Which? to search for faster, more reliable broadband services',
  image,
}) => {
  const [postcode, setPostcode] = useState<string>('')
  const imageData = image ?? defaultImage

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    window.open(
      `https://broadband.which.co.uk/broadband-deals?postcode=${postcode}&utm_source=which&utm_medium=advice&utm_campaign=postcodetool`
    )
  }

  return (
    <article aria-labelledby="broadband-cta-heading" className={styles.container}>
      <div className={styles.content}>
        <Typography
          id="broadband-cta-heading"
          tag="h2"
          textStyle="title-400"
          className={styles.heading}
        >
          {title}
        </Typography>
        <Typography className={styles.bodyText}>{description}</Typography>
        <form
          className={styles.form}
          data-which-id="compare-broadband-widget-form"
          name="broadband-switch"
          onSubmit={handleSubmit}
          title={title}
        >
          <FormField
            label="Postcode"
            labelFor="broadband-switch-postcode"
            showRequiredText={false}
            className={styles.postcodeField}
          >
            <Input
              id="broadband-switch-postcode"
              name="broadband-switch-postcode"
              placeholder="eg. NW1 4DF"
              value={postcode}
              onChange={(event) => setPostcode(event.target.value)}
            />
          </FormField>
          <Button type="submit">Compare</Button>
        </form>
      </div>
      {imageData && (
        <Picture
          src={imageData.src}
          alt={imageData.alt}
          sources={imageData.sources}
          className={styles.imageWrapper}
          imageClassName={styles.image}
        />
      )}
    </article>
  )
}
