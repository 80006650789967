import type { FunctionComponent } from 'react'
import React, { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { SignUp } from '@which/seatbelt'

import { useFeatureValue } from '@growthbook/growthbook-react'
import classnames from 'classnames'

// Reusing the images from the reviews pages for this test
// TODO: Move to shared location where both reviews and tables can use it
import { modalImageProps } from '../../../pages/reviews/shared/fixtures/signup'
import { checkUrlExistsInList } from '../../utils/checkUrlExistsInList'
import { ShowMore } from '../ShowMore'
import { SinglePadlockCta } from '../SinglePadlockCta'
import styles from './Table.module.scss'
import { formatTableChildren, mapScrollableTable } from './Table.utils'
import { getVariantSettings, variationTableRenderer } from './Variation/utils'

export const Table: FunctionComponent<Props> = (props) => {
  const { pathname } = useLocation()
  const includePageInTest = checkUrlExistsInList(pathname)

  return includePageInTest ? <ConditionalTableRender {...props} /> : <TableRender {...props} />
}

const ConditionalTableRender: FunctionComponent<Props> = (props) => {
  // Check loaded page is part of the URLs for testing the new variant
  // Comes from Growthbook (control, cta-to-offers-page, cta-to-modal)
  const featureTableVariation = useFeatureValue(
    'ces-locked-table-variation-string',
    'control'
  ) as featureTableVariation

  return <TableRender {...{ ...props, featureTableVariation }} />
}

const TableRender: FunctionComponent<Props & { featureTableVariation?: featureTableVariation }> = ({
  className,
  children,
  featureTableVariation = 'control',
  ...props
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const tableNoScrolls = String(className).includes('non-scrollable')
  const formattedChildren = formatTableChildren(children)

  const variationSettings = getVariantSettings(formattedChildren)
  const hasVariation = !!variationSettings?.type && featureTableVariation !== 'control'

  const wrapTable = useCallback(
    () => (
      <div className={classnames(styles.tableWrapper, styles.tableControl)}>
        <div className={styles.scrollTableWrapper}>
          <table {...props}>{children}</table>
        </div>
        <span className={styles.tableBorder} />
      </div>
    ),
    [children, props]
  )
  const tableRenderer = tableNoScrolls ? wrapTable : mapScrollableTable
  const showMoreHandler = () => {
    const loadDynSquirrelEvent = new Event('LoadDynSquirrel', { bubbles: true, cancelable: true })

    document.dispatchEvent(loadDynSquirrelEvent)
  }

  return hasVariation ? (
    <>
      {variationTableRenderer({
        formattedChildren,
        variationSettings,
        setModalOpen,
        featureTableVariation,
      })}
      <SinglePadlockCta setModalOpen={setModalOpen} />
      {modalOpen && (
        <SignUp
          className={styles.modal}
          image={modalImageProps}
          overline="Recommended"
          title="Full access"
          closeModal={() => setModalOpen(false)}
          data-testid="sign-up-modal"
          modalLinkDatalayerTrackingId="nstunlockedprovider"
        />
      )}
    </>
  ) : (
    <ShowMore
      buttonLabel="Show all rows"
      contentRenderer={tableRenderer}
      callback={showMoreHandler}
      className={styles.showMoreWrapper}
    >
      {formattedChildren}
    </ShowMore>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  className: string
}

type featureTableVariation = 'control' | 'cta-to-offers-page' | 'cta-to-modal'
