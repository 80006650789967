import type { FunctionComponent } from 'react'
import React from 'react'
import { PromoCard, Typography } from '@which/seatbelt'

import type { ImageWithSources } from '../../../../generated/frontend'
import styles from './RelatedProducts.module.scss'

export const RelatedProducts: FunctionComponent<RelatedProductsProps> = ({
  className,
  relatedProducts,
}) => {
  return (
    <section aria-labelledby="other-reviews" data-testid="related-products" className={className}>
      <Typography tag="h2" textStyle="title-600" id="other-reviews" className={styles.heading}>
        Other reviews
      </Typography>
      <div className={styles.container}>
        {relatedProducts &&
          relatedProducts.map((relatedProduct, index) => (
            <PromoCard
              {...relatedProduct}
              className={styles.productCard}
              imageAspectRatio="two-to-one"
              key={relatedProduct.href}
              data-testid="related-product-card"
              gtmProps={{
                'data-which-id': 'producthub-card',
                'data-section': 'related products',
                'data-index': index + 1,
              }}
              titleTag="span"
            />
          ))}
      </div>
    </section>
  )
}

export type RelatedProducts = {
  href: string
  strapline: string
  title: string
  image: ImageWithSources
}[]

type RelatedProductsProps = {
  className?: string
  relatedProducts: RelatedProducts
}
