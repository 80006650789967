import type { FunctionComponent } from 'react'
import React from 'react'
import { Loader as SBLoader } from '@which/seatbelt'

import classnames from 'classnames'

import { ThemeProvider } from '../../providers'
import styles from './Loader.module.scss'

export const Loader: FunctionComponent<LoaderProps> = ({ className }) => {
  return (
    <ThemeProvider>
      <div data-testid="loader-wrapper" className={classnames(styles.loaderWrapper, className)}>
        <div className="sr-only">Loading content</div>
        <SBLoader className={styles.loaderSvg} />
      </div>
    </ThemeProvider>
  )
}

/////IMPLEMENTATION//////

type LoaderProps = {
  className?: string
}
