import type { FunctionComponent } from 'react'
import React from 'react'

import type { ArticlePage } from '../../../../generated/frontend'
import { Brightcove, HeroImage, Vimeo, YouTube } from '../../../../shared'
import styles from './ArticleHero.module.scss'

export const ArticleHero: FunctionComponent<Props> = ({ heroImage, heroVideo }) => {
  if (!heroVideo && !heroImage) {
    return null
  }

  if (heroVideo) {
    return (
      <div className={styles.articleHeroWrapper}>{getVideoComponent(heroVideo, heroImage)}</div>
    )
  }

  return <HeroImage {...heroImage} />
}

///////// IMPLEMENTATION /////////

type Props = {
  heroImage: ArticlePage['heroImage']
  heroVideo?: ArticlePage['heroVideo']
}

const getVideoComponent = (
  heroVideo: ArticlePage['heroVideo'],
  heroImage: ArticlePage['heroImage']
) => {
  switch (heroVideo.videoClient) {
    case 'brightcove':
      return (
        <Brightcove
          videoId={heroVideo.videoId}
          accountId={heroVideo.brightcoveAccountId}
          playlist={false}
        />
      )
    case 'youtube':
      return <YouTube videoId={heroVideo.videoId} />
    case 'vimeo':
      return <Vimeo videoId={heroVideo.videoId} />
    default:
      return <HeroImage {...heroImage} />
  }
}
