import type { FunctionComponent } from 'react'
import React from 'react'
import { Typography } from '@which/seatbelt'

import styles from './StarRating.module.scss'

export const StarRating: FunctionComponent<StarRatingProps> = ({ value }) => {
  return (
    <Typography className={styles.stars} textStyle="small-print-compact" tag="span">
      {value}
    </Typography>
  )
}

export type StarRatingProps = {
  value: string
}
