import React, { useState } from 'react'

import style from '../PasswordField/PasswordFieldView.module.scss'
import eyeClose from './eye-close.gif'
import eyeOpen from './eye-open.gif'
import placeHolder from './placeholder.gif'

export const AnimateEyeGif = ({ className, eyeTransition, name }: Props) => {
  const [source, setSource] = useState<string>(placeHolder)

  const animate = () => {
    switch (source) {
      case placeHolder:
        setSource(eyeOpen)
        break
      case eyeClose:
        setSource(eyeOpen)
        break
      case eyeOpen:
        setSource(eyeClose)
        break
    }

    eyeTransition()
  }
  const assistText = source !== eyeOpen ? 'Show password' : 'Hide password'

  return (
    <div className={className}>
      <label htmlFor={name} title={assistText}>
        <input
          type="checkbox"
          onClick={() => animate()}
          name={name}
          id={name}
          className={style.srOnly}
        />
        <img height={24} src={source} alt={assistText} />
      </label>
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  className: string
  eyeTransition: () => void
  name: string
}
