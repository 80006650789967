import type { FunctionComponent } from 'react'
import React from 'react'
import { Typography } from '@which/seatbelt'

import classnames from 'classnames'

import styles from './ArticleListingHeader.module.scss'

export const ArticleListingHeader: FunctionComponent<Props> = ({
  count = 0,
  name,
  description,
  className = '',
  articleTypeDescriptor = '',
}) => (
  <div
    className={classnames(className, styles.articleListingHeader)}
    data-testid="article-listing-header"
  >
    <Typography
      className={styles.articleListingTitle}
      data-testid="article-listing-title"
      tag="h1"
      textStyle="title-650"
    >
      {`${name}${articleTypeDescriptor ? ` ${articleTypeDescriptor}` : ''}`}
    </Typography>
    {description && (
      <div className={styles.articleListingSubHeading} data-testid="article-listing-sub-heading">
        <Typography textStyle="standfirst">{description}</Typography>
      </div>
    )}
    {count > 0 && (
      <div className={styles.articleListingCount} data-testid="article-listing-count">
        <Typography textStyle="body-intro">{`${count} article${count > 1 ? 's' : ''}`}</Typography>
      </div>
    )}
  </div>
)

///////// IMPLEMENTATION /////////

type Props = {
  name: string
  description?: string
  className?: string
  count?: number
  articleTypeDescriptor?: string
}
