import React, { useEffect, useState } from 'react'

import classnames from 'classnames'

import { getEnvironment } from '../../utils/env-checks'
import type { BuildData } from './BuildStatus'
import { BuildStatus } from './BuildStatus'
import type { CacheData } from './CacheStatus'
import { CacheStatus } from './CacheStatus'
import styles from './WCDStatus.module.scss'

export const WCDStatus = () => {
  const [statusData, setStatusData] = useState<StatusData>({
    cache: {},
    deliver: {},
    dataProvider: {},
  })
  const [viewStatus, setViewStatus] = useState(false)
  const [refreshStatus, setRefreshStatus] = useState(false)
  const { cacheUrl, deliverStatusUrl, dataProviderStatusUrl } = getUrls()

  useEffect(() => {
    const getData = async () => {
      const [cacheRes, deliverRes, dataRes] = await Promise.allSettled([
        fetch(cacheUrl),
        fetch(deliverStatusUrl),
        fetch(dataProviderStatusUrl),
      ])
      const cache = await parseData(cacheRes)
      const deliver = await parseData(deliverRes)
      const dataProvider = await parseData(dataRes)
      setStatusData({ cache, deliver, dataProvider })
    }
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshStatus])

  const onToggleClick = () => {
    setViewStatus((prev) => !prev)
  }

  const onToggleRefresh = () => {
    setRefreshStatus((prev) => !prev)
  }

  return (
    <>
      <span
        data-testid="wcd-status-button"
        className={classnames(styles.chevron, !viewStatus || styles.right)}
        onClick={onToggleClick}
      >
        WCD status
      </span>
      {viewStatus && (
        <div data-testid="wcd-status-panel" className={styles.wcdStatus}>
          <div className={styles.controls}>
            <button data-testid="refresh-wcd-status-button" onClick={onToggleRefresh}>
              &#x27F3; Refresh
            </button>
            <button data-testid="close-wcd-status-button" onClick={onToggleClick}>
              &#10006;
            </button>
          </div>
          <BuildStatus label="deliver" buildData={statusData.deliver} />
          <BuildStatus label="data-provider" buildData={statusData.dataProvider} />
          <CacheStatus cacheData={statusData.cache} />
        </div>
      )}
    </>
  )
}

///////// IMPLEMENTATION /////////

type StatusData = {
  cache: CacheData
  deliver: BuildData
  dataProvider: BuildData
}

const getUrls = () => {
  const env = getEnvironment()
  const statusQuery = '?build=true&checks=true'

  return window?.location?.hostname === 'localhost'
    ? {
        cacheUrl: 'http://localhost:3300/status',
        deliverStatusUrl: `http://localhost:3200/status${statusQuery}`,
        dataProviderStatusUrl: `http://localhost:3100/status${statusQuery}`,
      }
    : {
        cacheUrl: `https://${env}-wcd-cache-control.components.product.which.co.uk/status`,
        deliverStatusUrl: `https://${env}-deliver.components.product.which.co.uk/status${statusQuery}`,
        dataProviderStatusUrl: `https://${env}-data-provider.components.product.which.co.uk/status${statusQuery}`,
      }
}

const parseData = async (res) =>
  res.status === 'fulfilled'
    ? await res.value.json()
    : { error: res.reason?.message || 'Error fetching data' }
