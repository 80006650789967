import type { FunctionComponent } from 'react'
import React from 'react'
import { Typography } from '@which/seatbelt'

import classnames from 'classnames'

import type { ArticleReviewsPageData } from '../../article-page-types'
import styles from './HeaderCentered.module.scss'

export const HeaderCentered: FunctionComponent<Props> = ({
  addMarginOffsetClass,
  date,
  headline,
  standfirst,
}) => (
  <div className={styles.wHeaderWrapper}>
    {date && (
      <div className={styles.wHeaderWrapperDate} data-testid="article-date">
        <Typography className={styles.wHeaderWrapper} tag="span" textStyle="small-print-bold">
          {date}
        </Typography>
      </div>
    )}

    {headline && (
      <Typography
        className={date ? '' : styles.headline}
        data-testid="headline"
        tag="h1"
        textStyle="title-700"
      >
        {headline}
      </Typography>
    )}

    {standfirst && (
      <div
        data-testid="standfirst-wrapper"
        className={classnames(styles.wHeaderStandfirst, {
          [styles.wHeaderStandfirstMarginOffset]: addMarginOffsetClass,
        })}
      >
        <Typography data-testid="standfirst" tag="span" textStyle="standfirst">
          {standfirst}
        </Typography>
      </div>
    )}
  </div>
)

///////// IMPLEMENTATION /////////

type Props = Omit<ArticleReviewsPageData['header'], 'date'> & { date?: string }
