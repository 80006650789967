import type { FunctionComponent } from 'react'
import React from 'react'
import { ApolloError } from '@apollo/client'

import { getGraphQLError } from '../../utils'
import { Error } from './Error'

export const PageError: FunctionComponent<Props> = ({ pageName }) => {
  const errorMessage = `No data returned for ${pageName}`
  return (
    <Error
      error={
        new ApolloError({
          graphQLErrors: [getGraphQLError(errorMessage, '404')],
        })
      }
    />
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  pageName: string
}
