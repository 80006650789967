import React from 'react'

import styles from './WCDStatus.module.scss'

export const BuildStatus = ({ buildData, label }: Props) => (
  <details>
    <summary>Build - {label}</summary>
    <div className={styles.content}>
      {buildData?.error ? (
        <div className={styles.error}>
          Error fetching from {label} status endpoint: {buildData.error}
        </div>
      ) : (
        <>
          <div>
            Branch - <strong>{buildData?.build?.branch || 'unknown'}</strong>
          </div>
          <div>
            Commit -
            {buildData?.build?.buildUrl ? (
              <a href={buildData.build.buildUrl} target="_blank">
                {buildData.build.commit}
              </a>
            ) : (
              <strong>{buildData?.build?.commit || 'unknown'}</strong>
            )}
          </div>
          <div>
            <strong>Services:</strong>
            {buildData?.checks?.map((service) => (
              <div key={service.name}>
                {service.name} - <strong>{service.status}</strong>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  </details>
)

///////// IMPLEMENTATION /////////

type Props = {
  buildData: BuildData
  label: string
}

export type BuildData = {
  error?: string
  build?: {
    branch: string
    commit: string
    buildUrl: string
  }
  checks?: {
    name: string
    status: string
  }[]
}
