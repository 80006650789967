import type { Dispatch, FunctionComponent, SetStateAction } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { CustomDropdown, Grid, GridItem, Typography } from '@which/seatbelt'

import classNames from 'classnames'

import styles from './JumpLinkHeaderBar.module.scss'

export const JumpLinkHeaderBar: FunctionComponent<Props> = ({
  articleType,
  title,
  links = [],
  jumpLinkBarHeightCallback,
  children,
}) => {
  const [navBarHeight, setNavBarHeight] = useState<number>(500)
  const [navBarVisible, setNavBarVisible] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState<number>(0)
  const navBarRef = useRef<HTMLElement | null>(null)
  const jumpLinksRef = useRef<HTMLDivElement | null>(null)
  const spacingAboveSection = 10

  useEffect(() => {
    if (!navBarVisible) {
      setSelectedOption(0)
    }
  }, [navBarVisible])

  useEffect(() => {
    if (navBarRef.current) {
      const barHeight = navBarRef.current.getBoundingClientRect().height
      setNavBarHeight(barHeight)
      jumpLinkBarHeightCallback(barHeight + spacingAboveSection)
    }
  }, [jumpLinkBarHeightCallback, navBarRef])

  useEffect(() => {
    const rootMarginTop = -50

    const jumpLinksObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setNavBarVisible(false)
        } else if (entry.boundingClientRect.top < 0) {
          setNavBarVisible(true)
        }
      },
      { rootMargin: `${rootMarginTop}px 0px 0px 0px` }
    )

    if (jumpLinksRef.current) {
      jumpLinksObserver.observe(jumpLinksRef.current)
    }

    return () => jumpLinksObserver.disconnect()
  }, [])

  useEffect(() => {
    if (!navBarHeight) {
      return
    }

    const rootMarginBottom =
      Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) - navBarHeight
    let elementsToObserve: Array<Element | null> = []

    for (const { slug } of links) {
      elementsToObserve = [...elementsToObserve, document.querySelector(`#${slug}`)]
    }

    const sectionObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const observedElementsIndex = elementsToObserve.indexOf(entry.target)
          setSelectedOption(observedElementsIndex + 1)
        }
      },
      { rootMargin: `0px 0px -${rootMarginBottom - spacingAboveSection}px 0px` }
    )

    elementsToObserve.forEach((element) => {
      if (element) {
        sectionObserver.observe(element)
      }
    })

    return () => sectionObserver.disconnect()
  }, [navBarHeight, links])

  if (links.length <= 3) {
    return null
  }

  const scrollToSectionHandler = ({ value }) => {
    const scrollTarget = value ? document.querySelector(value) : null

    if (!scrollTarget) {
      return false
    }

    const scrollTargetText = value.replace('#', '').replaceAll('-', ' ')
    const scrollPos =
      window.scrollY + scrollTarget.getBoundingClientRect().top - navBarHeight - spacingAboveSection

    window.scrollTo({
      top: scrollPos,
      behavior: 'smooth',
    })

    window.history.pushState(null, '', value)

    window.dataLayer.push({
      event: 'click_inarticle_nav',
      item_text: scrollTargetText,
    })
  }

  const buildJumpLinks = () => {
    const jumplinkOptions = links.map(({ name, slug }) => ({
      label: name,
      value: `#${slug}`,
    }))

    jumplinkOptions.unshift({ label: 'Please select', value: 'placeholder' })

    return jumplinkOptions
  }

  const jumpLinks = buildJumpLinks()

  const barVisibilityStyle = {
    transform: `translateY(${navBarVisible ? '0' : -navBarHeight}px)`,
  }

  return (
    <>
      <div ref={jumpLinksRef} className={styles.jumpLinksWrapper}>
        {children}
      </div>

      <nav
        ref={navBarRef}
        className={styles.jumplinksBar}
        style={barVisibilityStyle}
        data-testid="jumplink-header-bar"
        aria-hidden={navBarVisible ? false : true}
        aria-label="article heading navigation"
      >
        <div className={styles.gridContainer}>
          <Grid span={{ medium: 12, large: 12 }}>
            <GridItem
              className={styles.jumplinksBarItem}
              span={{ medium: 5, large: 5 }}
              columnStart={{ medium: 2, large: 2 }}
            >
              <Typography className={styles.jumplinksBarType} textStyle={'small-print-compact'}>
                {articleType}
              </Typography>
              <Typography className={styles.jumplinksBarTitle} tag="h3" textStyle={'title-400'}>
                {title}
              </Typography>
            </GridItem>

            <GridItem
              className={classNames(styles.jumplinksBarItem, styles.jumplinksBarItemDropdown)}
              span={{ medium: 5, large: 4 }}
              columnStart={{ medium: 8, large: 9 }}
            >
              <CustomDropdown
                className={styles.dropdownContainer}
                classNameSelectedContainer={styles.selectedContainer}
                classNameOptionsContainer={styles.optionsContainer}
                options={jumpLinks}
                handleSortByCallback={scrollToSectionHandler}
                data-testid="jump-links-dropdown"
                aria-controls="jump-links-dropdown"
                defaultOption={selectedOption}
                key={`nav-bar-${selectedOption}`}
              />
            </GridItem>
          </Grid>
        </div>
      </nav>
    </>
  )
}

///////// IMPLEMENTATION /////////

export type JumpLink = {
  id?: number | string
  name: string
  prefix?: string
  slug?: string
}

type Props = {
  articleType?: string
  title: string
  links: JumpLink[]
  visible?: boolean
  jumpLinkBarHeightCallback: Dispatch<SetStateAction<number>>
}
