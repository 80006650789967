import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'
import { Link, Modal, Typography } from '@which/seatbelt'

import style from './ErrorModal.module.scss'

export const ErrorModal: FunctionComponent<Props> = ({
  httpErrorCode,
  emailAddress,
  showModal,
}) => {
  const [modalOpen, setModalOpen] = useState(showModal)
  useEffect(() => {
    if (showModal) {
      setModalOpen(true)
    }
  }, [showModal])

  const supportParagraph = (
    <Typography tag="p">
      If the problem persists, please contact Which? member services on{' '}
      <Link href="tel:+442922670000">029 2267 0000</Link> to sign up (Mon - Fri: 8:30am to 6pm; Sat:
      9am to 1pm; Standard call rates apply) or email{' '}
      <Link href="mailto:support@which.co.uk">support@which.co.uk</Link>
    </Typography>
  )

  const renderIntro = (introText) => {
    return (
      <>
        <Typography tag="h3">{introText}</Typography>
        {httpErrorCode && <Typography tag="p">Error {httpErrorCode}</Typography>}
      </>
    )
  }

  const tryAgain = <Typography tag="p">Please try again.</Typography>

  const modalContent = (errorCode: string) => {
    switch (errorCode) {
      case '202':
        return (
          <>
            <Typography tag="h3">Your password has been reset</Typography>
            <Typography tag="p">
              In order to secure your account we have sent a password reset email to the inbox for{' '}
              {emailAddress}.
            </Typography>
            <Typography tag="p">
              Please use the link in that email to set a new secure password for your account, log
              in, then return here to sign up.
            </Typography>
          </>
        )

      case '409':
        return (
          <>
            {renderIntro("There's already an account with this email.")}
            <Typography tag="p">
              You already have an account but the password you have given is incorrect.
            </Typography>
            <Typography tag="p">
              Please <Link href="/login">log in</Link> or{' '}
              <Link href="/forgot-password">reset your password</Link> to proceed.
            </Typography>
          </>
        )

      default:
        // 400,500,504'
        return (
          <>
            {renderIntro('Sorry, a server error occurred')}
            {tryAgain}
            {supportParagraph}
          </>
        )
    }
  }

  const closeModalHandler = () => {
    setModalOpen(false)
  }

  if (modalOpen) {
    return (
      <Modal closeModal={closeModalHandler} className={style.errorModal}>
        <div className={style.errorModalContent}>{modalContent(httpErrorCode)}</div>
      </Modal>
    )
  }
  return <></>
}

///////// IMPLEMENTATION /////////
type Props = {
  httpErrorCode: string
  showModal: boolean
  emailAddress?: string
}
