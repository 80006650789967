import type { Component } from '@which/glide-ts-types'
import { InPageAnchorLink, StandardLink, Typography } from '@which/seatbelt'

import * as articlePageComponents from '../../pages/article/components'

type LocalComponent = keyof typeof localComponent
import * as localComponent from '../components'

export const componentMap: Record<Component | LocalComponent, unknown> = {
  ...localComponent,
  ...articlePageComponents,
  AcastPodcast: localComponent.IframeEmbed,
  StandardLink,
  InPageAnchorLink,
  Typography,
  PICategoriesSection: localComponent.PICategories,
  PITopicsSection: localComponent.PITrendingTopicsSection,
  PILatestReleasesSection: undefined,
  PIHeader: localComponent.PIHeader,
  PISearchContent: localComponent.PISearchContent,
}
