import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'
import { ButtonLink, Typography } from '@which/seatbelt'

import classNames from 'classnames'
import DOMPurify from 'dompurify'

import { getBlaize } from '../../utils/getBlaize'
import { getEntitlements } from '../../utils/getEntitlements/getEntitlements'
import styles from './ABToolEntryPoint.module.scss'

const renderStandfirst = (standfirst?: string) => {
  if (typeof window === 'undefined') {
    return null
  }
  const standfirstEl = document.createElement('div')
  standfirstEl.innerHTML = standfirst as string
  const links = standfirstEl.querySelectorAll('a')

  links.forEach((link) => {
    const spanEl = document.createElement('span')
    spanEl.textContent = link.textContent
    spanEl.classList.add('sb-link-animation-wrapper')
    link.textContent = ''
    link.appendChild(spanEl)
    link.classList.add('sb-link-primary')
  })

  return Array.from(standfirstEl.children, (child) => {
    return (
      <div
        key={child.textContent}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(child.outerHTML, { ADD_ATTR: ['target'] }),
        }}
      />
    )
  })
}

const decideUrl = (service: string) => {
  switch (service) {
    case 'tech':
      return '?type=tech'
    case 'money':
      return '?type=money'
    case 'legal':
      return '?type=legal'
    default:
      return ''
  }
}

const decideContent = ({
  service,
  title,
  standfirst,
  button_text,
  logged_out_title,
  logged_out_standfirst,
  logged_out_button_text,
  upsell_standfirst,
  upsell_title,
  upsell_button_text,
  upsell_button_link,
  isEntitled,
}: TFToolEntryProps): {
  standfirst: JSX.Element[] | null
  buttonLink: string
  buttonText: string
  title: string
} => {
  if (!getBlaize()) {
    return {
      standfirst: renderStandfirst(logged_out_standfirst),
      buttonLink: `/tool/book-appointment${decideUrl(service)}`,
      buttonText: logged_out_button_text,
      title: logged_out_title,
    }
  } else if (!isEntitled) {
    return {
      standfirst: renderStandfirst(upsell_standfirst),
      buttonLink: upsell_button_link,
      buttonText: upsell_button_text,
      title: upsell_title,
    }
  }
  return {
    standfirst: renderStandfirst(standfirst),
    buttonLink: `/tool/book-appointment${decideUrl(service)}`,
    buttonText: button_text,
    title: title,
  }
}

export const ABToolEntryPoint: FunctionComponent<TFToolEntryProps> = ({
  service,
  title,
  standfirst,
  button_text,
  logged_out_title,
  logged_out_standfirst,
  logged_out_button_text,
  upsell_standfirst,
  upsell_title,
  upsell_button_text,
  upsell_button_link,
}) => {
  const [isEntitled, setIsEntitled] = useState(false)
  const [checkedEntitlements, setCheckedEntitlements] = useState(false)

  useEffect(() => {
    if (service && !checkedEntitlements) {
      const fetchEntitlement = async () => {
        const entitlement = await getEntitlements()
        switch (service) {
          case 'money':
            setIsEntitled(entitlement.money)
            break
          case 'tech':
            setIsEntitled(entitlement.tech)
            break
          case 'legal':
            setIsEntitled(Object.values(entitlement.legal).some(Boolean))
            break
        }
        setCheckedEntitlements(true)
      }
      fetchEntitlement()
    }
  }, [checkedEntitlements, service])

  if (!checkedEntitlements) {
    return null
  }

  const content = decideContent({
    service,
    title,
    standfirst,
    button_text,
    logged_out_title,
    logged_out_standfirst,
    logged_out_button_text,
    upsell_standfirst,
    upsell_title,
    upsell_button_text,
    upsell_button_link,
    isEntitled,
  })

  return (
    <div className={classNames(styles.ABToolEntryPoint)} data-testid="ab-tool-entry-point">
      <Typography
        className={styles.ABToolEntryPointTitle}
        textStyle="title-400"
        data-testid="ab-tool-title"
      >
        {content.title}
      </Typography>
      <div className={styles.ABToolEntryPointBodyWrapper}>
        <div className={styles.ABToolEntryPointContent}>
          {content.standfirst}
          <ButtonLink data-testid="ab-tool-link" href={content.buttonLink}>
            {content.buttonText}
          </ButtonLink>
        </div>
      </div>
    </div>
  )
}

type TFToolEntryProps = {
  service: string
  title: string
  standfirst?: string
  button_text: string
  logged_out_title: string
  logged_out_standfirst?: string
  logged_out_button_text: string
  upsell_standfirst?: string
  upsell_title: string
  upsell_button_text: string
  upsell_button_link: string
  isEntitled?: boolean
}
