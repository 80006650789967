/* istanbul ignore file */
/**
 * This file has been excluded from coverage reports as it consumes the useCompareTableScroll hook.
 * This hook relies on the ResizeObserver to work correctly, which is currently not able to be
 * implemented within Testing library or js-dom, so we are unable to fully test this component's
 * functionality in this environment.
 */
import type { FunctionComponent, SyntheticEvent } from 'react'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useIntersection } from 'react-use'
import {
  Button,
  ChevronLeftIcon,
  ChevronRightIcon,
  GridItem,
  TraverseButton,
} from '@which/seatbelt'
import { dynamicDatalayerPush } from '@which/shared'

import classnames from 'classnames'

import { compareProductsStorageName, Loader, ReviewsCompareTray } from '../../../../../shared'
import { useCompareTray } from '../../../../../shared/hooks/useCompareTray'
import { usePaths } from '../../../../../shared/hooks/usePaths'
import { ComparisonTableContext } from '../../ComparisonTableContext'
import { compareTableColumnWidth } from '../../hooks/constants'
import { useCompareTableScroll } from '../../hooks/useCompareTableScroll'
import { useLayoutScroll } from '../../hooks/useLayoutScroll'
import {
  HIDE_TABLE_SIDEBAR,
  REMOVE_TECH_SPECS_COLUMN,
} from '../../reducers/comparisonTableActionTypes'
import { EmptyCompareLandingPage } from '../EmptyCompareLandingPage/EmptyCompareLandingPage'
import styles from './ComparisonTable.module.scss'
import { ComparisonTableData } from './ComparisonTableData'
import { ProductDetails } from './ProductDetails'

export const ComparisonTable: FunctionComponent = () => {
  const {
    state: { isSidebarHidden },
    dispatch,
  } = useContext(ComparisonTableContext)
  const { categorySlug } = usePaths()
  const taxonomySlug = categorySlug || ''
  const { compareTrayItems, removeProduct_CompareTrayHandler } = useCompareTray(
    compareProductsStorageName,
    taxonomySlug
  )
  const [tableOffsetLeft, setTableOffsetLeft] = useState<boolean>(false)
  const intersectionRef = useRef(null)
  const tableBottom = useRef(null)
  const scrollRef = useRef(null)
  const tableHeadRef = useRef<HTMLTableSectionElement>(null)
  const { x, isScrollable } = useLayoutScroll(scrollRef.current)
  const intersectionTableTop = useIntersection(intersectionRef, {
    threshold: 1,
  })
  const intersectionTableBottom = useIntersection(tableBottom, {
    threshold: 0,
  })
  const isSticky = intersectionTableTop && intersectionTableTop.boundingClientRect.top < 0
  const isAtBottom =
    intersectionTableBottom &&
    (intersectionTableBottom.isIntersecting || intersectionTableBottom.boundingClientRect.top < 0)

  const Icon = isSidebarHidden ? ChevronRightIcon : ChevronLeftIcon

  const toggleColumn = () => {
    dynamicDatalayerPush({
      eventCategory: 'Product Compare',
      eventAction: 'Refine Results',
      eventLabel: `${isSidebarHidden ? 'Show' : 'Hide'} Filter Names`,
    })

    if (!isSidebarHidden) {
      const table = scrollRef?.current as HTMLElement | null
      if (table) {
        const { scrollLeft, scrollWidth, offsetWidth } = table
        if (scrollLeft + offsetWidth > scrollWidth - compareTableColumnWidth - 1) {
          scrollTable('previous')
        }
      }
    }

    dispatch({
      type: HIDE_TABLE_SIDEBAR,
      isSidebarHidden: !isSidebarHidden,
    })
  }

  useEffect(() => {
    if (isSidebarHidden && !isScrollable) {
      dispatch({
        type: HIDE_TABLE_SIDEBAR,
        isSidebarHidden: false,
      })
    }
  }, [isScrollable, dispatch, isSidebarHidden])

  const {
    handleWrapperScroll,
    leftButtonDisabled,
    rightButtonDisabled,
    scrollTable,
    startScroll,
    stopScroll,
  } = useCompareTableScroll(scrollRef, isSidebarHidden)

  const removeProductColumn = (businessKey: string, index?: number) => {
    removeProduct_CompareTrayHandler(businessKey, 'Remove From Compare - Compare Page')
    dispatch({
      type: REMOVE_TECH_SPECS_COLUMN,
      index,
    })
  }

  const onTableTransitionEnd = (e: SyntheticEvent) => {
    const element = e.target as HTMLElement
    if (element?.nodeName === 'TABLE') {
      setTableOffsetLeft(!tableOffsetLeft)
    }
  }

  if (!compareTrayItems) {
    return <Loader />
  }

  return (
    <GridItem span={{ small: 'full-bleed', medium: 'full-bleed' }}>
      {compareTrayItems.length ? (
        <>
          <div
            id="io-element"
            data-testid="reviews-compare-intersection"
            ref={intersectionRef}
            style={
              tableHeadRef.current && isSticky
                ? { height: `${tableHeadRef.current?.clientHeight}px` }
                : { height: 0 }
            }
          />
          <div
            className={classnames(
              styles.tableScrollWrapper,
              isSticky && styles.isSticky,
              isAtBottom && styles.isAtBottom,
              isSidebarHidden && styles.hiddenColumn,
              isScrollable && styles.tableIsScrollable,
              tableOffsetLeft && styles.tableOffsetLeft
            )}
            ref={scrollRef}
            onScroll={handleWrapperScroll}
            data-testid="comparison-table-wrapper"
          >
            <Button
              buttonAppearance="primary"
              onClick={toggleColumn}
              className={styles.toggleBtn}
              data-testid="toggle-column-button"
            >
              <Icon className={styles.toggleBtnIcon} />
            </Button>
            <TraverseButton
              disabled={!isScrollable || leftButtonDisabled}
              className={styles.traverseButtonLeft}
              direction="previous"
              onClick={() => scrollTable('previous')}
              onMouseDown={() => startScroll('previous')}
              onMouseUp={stopScroll}
              onMouseLeave={stopScroll}
              onTouchStart={() => startScroll('previous')}
              onTouchEnd={stopScroll}
            />
            <TraverseButton
              disabled={!isScrollable || rightButtonDisabled}
              className={styles.traverseButtonRight}
              direction="next"
              onClick={() => scrollTable('next')}
              onMouseDown={() => startScroll('next')}
              onMouseUp={stopScroll}
              onMouseLeave={stopScroll}
              onTouchStart={() => startScroll('next')}
              onTouchEnd={stopScroll}
            />
            <table
              className={classnames(styles.comparisonTable)}
              onTransitionEnd={onTableTransitionEnd}
            >
              <thead
                ref={tableHeadRef}
                style={{ left: -x }}
                className={styles.comparisonTableHead}
                data-testid="comparison-table-head"
              >
                <ReviewsCompareTray
                  className={styles.compareTrayHeadRow}
                  taxonomySlug={taxonomySlug}
                  products={compareTrayItems}
                  removeHandler={removeProductColumn}
                  variant="header"
                  scrollX={isSticky && isScrollable ? x : 0}
                />
              </thead>
              <tbody data-testid="comparison-table-body">
                <ProductDetails taxonomySlug={taxonomySlug} />
                <ComparisonTableData taxonomySlug={taxonomySlug} />
              </tbody>
            </table>
          </div>
          <div ref={tableBottom} data-testid="io-element-bottom"></div>
        </>
      ) : (
        <EmptyCompareLandingPage
          data-testid="emptyCompareLandingPage"
          taxonomySlug={taxonomySlug}
          numberOfProducts={compareTrayItems.length}
        />
      )}
    </GridItem>
  )
}

//////IMPLEMENTATION//////

export const totalProductColumns = 4
