import type { FunctionComponent } from 'react'
import React from 'react'
import {
  DataArticleIcon,
  InsightArticleIcon,
  PageIcon,
  PolicyArticleIcon,
  PolicyPaperArticleIcon,
  PolicyResearchPaperArticleIcon,
  PolicySubmissionArticleIcon,
  PressReleaseArticleIcon,
  PressStatementArticleIcon,
  Typography,
} from '@which/seatbelt'

import styles from './PIArticleInfo.module.scss'

export const PIArticleInfo: FunctionComponent<Props> = ({ articleType }) => {
  const PIIcon = mapIconType(articleType)

  return (
    <div className={styles.piArticleInfo} data-testid="ea-article-info">
      <div className={styles.piArticleInfoType}>
        <PIIcon className={styles.piArticleInfoIcon} data-testid="article-card-text-heading-icon" />
        <Typography textStyle="small-print-compact" data-testid="article-info-type">
          {articleType}
        </Typography>
      </div>
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  articleType: string
}

export const mapIconType = (topicName: string) => iconMap[topicName] || PolicyPaperArticleIcon

const iconMap = {
  'Press release': PressReleaseArticleIcon,
  'Press statement': PressStatementArticleIcon,
  'Policy paper': PolicyPaperArticleIcon,
  'Policy submission': PolicySubmissionArticleIcon,
  'Insight article': InsightArticleIcon,
  Data: DataArticleIcon,
  Page: PageIcon,
  'Policy research paper': PolicyResearchPaperArticleIcon,
  'Policy article': PolicyArticleIcon,
}
