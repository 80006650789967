import type { FunctionComponent, ReactElement } from 'react'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { useFeatureValue } from '@growthbook/growthbook-react'

import { checkUrlExistsInList } from '../../utils/checkUrlExistsInList'
import { CsvTableOriginal } from './CsvTableOriginal'
import { CsvTableVariation } from './Variation/CsvTableVariation'

export const CsvTable: FunctionComponent<Props> = (props) => {
  const { pathname } = useLocation()
  const includePageInTest = checkUrlExistsInList(pathname)

  return includePageInTest ? <ConditionalTableRender {...props} /> : <CsvTableRender {...props} />
}

const ConditionalTableRender: FunctionComponent<Props> = (props) => {
  // Check loaded page is part of the URLs for testing the new variant
  // Comes from Growthbook (control, cta-to-offers-page, cta-to-modal)
  const featureTableVariation = useFeatureValue(
    'ces-locked-table-variation-string',
    'control'
  ) as featureTableVariation

  return <CsvTableRender {...{ ...props, featureTableVariation }} />
}

const CsvTableRender: FunctionComponent<
  Props & { featureTableVariation?: featureTableVariation }
> = (props) => {
  // Comes from CSV widget in Glide
  const { testVariant, featureTableVariation = 'control' } = props

  const hasVariation = !!testVariant && featureTableVariation !== 'control'

  const csvTable = hasVariation
    ? { component: CsvTableVariation, props }
    : { component: CsvTableOriginal, props }

  return <csvTable.component {...csvTable.props} />
}

///////// IMPLEMENTATION /////////

type ArticleElement = {
  sortableValue?: number | string
  tagName: string
  props?: Record<string, unknown>
  innerText?: string
  children?: ArticleElement[]
}

type TableBodyContent = string | ArticleElement

type SortType = 'text' | 'stars' | 'percentage' | 'rich_cell'

type Props = {
  headings: string[]
  columnDataTypes: SortType[]
  tableBody: TableBodyContent[][]
  renderArticleElements: (x: ArticleElement[]) => ReactElement
  enableSearch?: boolean
  id?: string
  notes?: string
  testVariant?: 'scoresLocked' | 'providersLocked'
}

export type featureTableVariation = 'control' | 'cta-to-offers-page' | 'cta-to-modal'
