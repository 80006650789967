import type { FunctionComponent } from 'react'
import React from 'react'
import { Typography } from '@which/seatbelt'

import classnames from 'classnames'

import styles from './InfoBanner.module.scss'

export const InfoBanner: FunctionComponent<Props> = ({
  context = 'search-alert',
  title,
  content,
  className,
}) => {
  if (!title && !content) {
    return null
  }

  return (
    <div
      data-testid="infoBanner-container"
      className={classnames(styles.infoBannerContainer, styles[context], className)}
    >
      {title && (
        <Typography
          data-testid="infoBanner-title"
          textStyle="title-300"
          tag="h2"
          className={styles.infoBannerTitle}
        >
          {title}
        </Typography>
      )}
      {content && (
        <div
          data-testid="infoBanner-content"
          className={styles.infoBannerContent}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
    </div>
  )
}

export type Props = {
  context: 'search-alert' | 'error' | 'correct'
  title?: string
  content?: string
  className?: string
}
