import type { FunctionComponent, ReactElement } from 'react'
import React from 'react'
import { Helmet } from 'react-helmet-async'

import classnames from 'classnames'

import { ThemeProvider } from '..'

export const PageTemplateSimple: FunctionComponent<Props> = ({
  children,
  metaTags,
  includePageWrapper = true,
  className,
}) => {
  return (
    <ThemeProvider>
      <Helmet>{metaTags}</Helmet>
      <main
        id="main-content"
        className={classnames(includePageWrapper && 'w-page-wrapper', className)}
      >
        {children}
      </main>
    </ThemeProvider>
  )
}

///////// IMPLEMENTATION /////////

interface Props {
  metaTags?: ReactElement[]
  includePageWrapper?: boolean
  className?: string
}
