import React from 'react'
import { Typography } from '@which/seatbelt'

import type { AnnualRunningCost } from '../../../generated/frontend'
import { Badge } from '../../../shared/components/Badge/Badge'
import styles from './map-annual-running-cost.module.scss'

export const mapAnnualRunningCost = ({
  annualRunningCost,
  loginStatus,
}: MappedAnnualRunningCost) => {
  const { priceLabel, tooltip, ranking, rankingTotal } = annualRunningCost

  if (loginStatus === 'LoggedOut') {
    return {
      priceLabel: (
        <span data-testid="arc-price-placeholder">
          £
          <span className={styles.hiddenARC} />
          to run per year
        </span>
      ),
      tooltip,
    }
  }

  return {
    priceLabel,
    tooltip,
    ranking: (
      <Typography tag="span" textStyle="small-print-bold" className={styles.centre}>
        <Badge colour="white" backgroundColour="dark">
          {ranking}
        </Badge>
        <Typography tag="span" textStyle="small-print-compact">
          out of {rankingTotal} products for running cost
        </Typography>
      </Typography>
    ),
  }
}

type MappedAnnualRunningCost = {
  annualRunningCost: AnnualRunningCost
  loginStatus?: string
}
