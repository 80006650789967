import type { FunctionComponent } from 'react'
import React from 'react'
import { CardRow, RelatedProductsCard } from '@which/seatbelt'

import { filterBadgeThemes } from '../../../../shared'
import type { ArticleReviewsPageData } from '../../article-page-types'

export const RelatedProducts: FunctionComponent<Props> = ({ relatedProducts }) => {
  if (!relatedProducts) {
    return null
  }

  const { categoryLabelSingular, categorySlug, products } = relatedProducts
  const href = `/reviews/${categorySlug}`
  const productList = (products?.length > 3 && products.slice(0, 3)) || products
  const link = {
    text: `View ${categoryLabelSingular} reviews`,
    href,
  }

  return products?.length > 2 ? (
    <CardRow link={link}>
      {productList.map(
        ({
          slug,
          model,
          badges,
          overallScore,
          manufacturer,
          hasRetailers,
          imageSrc,
          price,
          label,
          textVariation,
          toolTip,
        }) => (
          <RelatedProductsCard
            model={model}
            href={`${href}/${slug}`}
            key={model}
            badges={filterBadgeThemes(badges)}
            overallScore={overallScore}
            manufacturer={manufacturer}
            hasRetailers={hasRetailers}
            imageSrc={imageSrc}
            price={price}
            label={label}
            textVariation={textVariation as 'obfuscated' | undefined}
            toolTip={toolTip}
          />
        )
      )}
    </CardRow>
  ) : null
}

///////// IMPLEMENTATION /////////

type Props = {
  relatedProducts: ArticleReviewsPageData['relatedProducts']
}
