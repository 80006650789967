import type { FunctionComponent } from 'react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Typography } from '@which/seatbelt'
import { dynamicDatalayerPush } from '@which/shared'

import classnames from 'classnames'

import { SVGUtil } from '../../../../shared/components/SVGUtil'
import { SVGName } from '../../../../shared/components/SVGUtil/svgs'
import styles from './CRCarToolIntro.module.scss'

export const CRCarToolIntro: FunctionComponent<Props> = ({ className = '' }) => {
  const history = useHistory()
  const handleFindOutMyRights = () => {
    dynamicDatalayerPush({
      eventCategory: 'problem resolution tools',
      eventAction: 'used car',
      eventLabel: 'cta - click',
      eventValue: 0,
      eventInteractive: false,
      event: 'tEvent',
    })
    history.push('/tool/car-problem')
  }

  return (
    <div
      className={classnames(styles.container, styles.wrapper, styles.white, className)}
      data-testid="CRCarToolIntro"
    >
      <Typography tag="h2" textStyle="standfirst" className={styles.heading}>
        If your car has developed an unexpected fault or you have been misled by the seller we can
        help you understand your rights
      </Typography>
      <div className={styles.features}>
        <div className={styles.feature}>
          <SVGUtil
            name={SVGName.RightsCR}
            width={200}
            height={200}
            viewBox="0 0 125 110"
            title="Rights icon"
            className={styles.image}
          />
          <Typography textStyle="body-intro" className={styles.text}>
            Check what your legal rights are
          </Typography>
        </div>
        <div className={styles.feature}>
          <SVGUtil
            name={SVGName.ComplaintCR}
            width={200}
            height={200}
            viewBox="0 0 125 110"
            title="Complaint icon"
            className={styles.image}
          />
          <Typography textStyle="body-intro" className={styles.text}>
            Create a complaint letter to send to the car dealer
          </Typography>
        </div>
        <div className={styles.feature}>
          <SVGUtil
            name={SVGName.EscalateCR}
            width={200}
            height={200}
            viewBox="0 0 125 110"
            title="Escalate icon"
            className={styles.image}
          />
          <Typography textStyle="body-intro" className={styles.text}>
            Escalate your problem further, if needed
          </Typography>
        </div>
      </div>
      <div className={styles.cta}>
        <a onClick={handleFindOutMyRights} className={styles.button}>
          Find out my rights
        </a>
      </div>
    </div>
  )
}

////////// IMPLEMENTATION /////////

type Props = {
  className?: string
}
