import type { HomePageQuery } from '../../../generated/frontend'
import { getDataLayerScripts } from '../../../shared/data-layer'
import { getDFPScripts } from '../../../shared/dfp'
import { useBaseMetaTags } from '../../../shared/hooks/useBaseMetaTags'
import { useFullUrl } from '../../../shared/hooks/useFullUrl'
import type { PageInfo } from '../../../shared/types'
import { removeFalsy, removeUrlQueryStringAndFragment } from '../../../shared/utils'

export const useProductHubPageMetaTags = () => {
  const { getBaseMetaTags } = useBaseMetaTags()
  const { getDataLayerItems } = useDataLayerItems()
  const { getFullUrl } = useFullUrl()

  return {
    getMetaTags: ({
      meta,
      imageUrl,
      imageAlt,
      twitterCard,
    }: {
      meta: Meta
      imageUrl?: string
      imageAlt?: string
      twitterCard?: string
    }) => [
      ...getBaseMetaTags({ meta, imageUrl, imageAlt, twitterCard }),
      ...getDataLayerItems(meta, getFullUrl()),
    ],
  }
}

///////// IMPLEMENTATION /////////

type Meta = HomePageQuery['homePage']['meta']

const useDataLayerItems = () => ({
  getDataLayerItems(meta: Meta, fullUrl: string) {
    const { dataLayer = [] } = meta || {}
    const pageInfo: PageInfo = {
      vertical: meta ? meta.taxonomyHierarchy.vertical : '',
      content_type: 'product-hub',
      wcdPageUrl: removeUrlQueryStringAndFragment(fullUrl),
    }

    return [
      ...getDataLayerScripts([...dataLayer, removeFalsy(pageInfo)]),
      ...getDFPScripts(dataLayer, pageInfo),
    ]
  },
})
