import type { FunctionComponent } from 'react'
import React from 'react'
import { Link } from '@which/seatbelt'

export const Mention: FunctionComponent<Props> = ({ href, innerText }) => (
  <Link href={href}>{innerText}</Link>
)

///////// IMPLEMENTATION /////////

type Props = {
  href: string
  innerText: string
}
