import React from 'react'
import type { Component as ComponentType } from '@which/glide-ts-types'
import { componentName } from '@which/glide-ts-types'
import { Grid, GridItem } from '@which/seatbelt'
import type { ImageWithSources } from '@which/shared'

import { BroadbandSwitchCTA, ToolCTA } from '../../../../shared/components'
import styles from './OnwardJourney.module.scss'

interface Props {
  component: string
  props: {
    title: string
    description: string
    image?: ImageWithSources
  }
}

const componentMapper: Record<
  Extract<ComponentType, 'ToolCTA' | 'BroadbandSwitchCTA'>,
  React.FC<any>
> = {
  ToolCTA: ToolCTA,
  BroadbandSwitchCTA: BroadbandSwitchCTA,
}

export const OnwardJourney: React.FC<Props> = ({ component, props }) => {
  if (!componentName.includes(component as ComponentType)) {
    return null
  }

  const Component = componentMapper[component]

  return (
    <Grid className={styles.container} includeGutters={false}>
      <GridItem span={{ medium: 10, large: 7 }} columnStart={{ medium: 2, large: 2 }}>
        <Component {...props} />
      </GridItem>
    </Grid>
  )
}
