import type { FunctionComponent } from 'react'
import React from 'react'
import type { ValidTypographyElements } from '@which/seatbelt'
import { Typography } from '@which/seatbelt'

import classnames from 'classnames'

export const ArticleTypography: FunctionComponent<Props> = ({ tag, children, ...rest }) => {
  const elementType = `${tag}-article`

  return (
    <Typography
      tag={tag}
      className={classnames(elementType)}
      textStyle={setTextStyle(tag)}
      {...rest}
    >
      {children}
    </Typography>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  tag: ValidTypographyElements
}

const setTextStyle = (tag: Props['tag']) => {
  switch (tag) {
    case 'h1':
      return 'title-650'
    case 'h2':
      return 'title-600'
    case 'h3':
      return 'title-500'
    case 'h4':
    case 'h5':
    case 'h6':
      return 'title-300'
    case 'p':
    case 'span':
    case 'li':
      return 'body'
    default:
      return undefined
  }
}
