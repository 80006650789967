import type { TooltipWithButtonProps } from '@which/seatbelt'

import type { AnnualRunningCost } from '../../../generated/frontend'

export const addHandlerToRunningCostTooltip = (
  annualRunningCost: FormattedRunningCost | undefined,
  handler: TooltipWithButtonProps['openHandler']
) => {
  if (annualRunningCost?.tooltip) {
    const transformedRunningCost = {
      ...annualRunningCost,
      tooltip: {
        ...annualRunningCost.tooltip,
        openHandler: handler,
      },
    }

    return transformedRunningCost
  }

  return annualRunningCost
}

type FormattedRunningCost = {
  priceLabel: string | JSX.Element
  ranking?: JSX.Element
  tooltip: FormattedTooltip
}

type FormattedTooltip = AnnualRunningCost['tooltip'] & {
  openHandler?: TooltipWithButtonProps['openHandler']
}
