import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Grid, GridItem, Link, List, ListItem, Typography } from '@which/seatbelt'
import { dynamicGa4DataLayerPush } from '@which/shared'

import type { Meta } from '../../../generated/frontend'
import { useForgotPasswordMutation } from '../../../generated/frontend'
import { Error } from '../../../shared'
import { useBaseMetaTags } from '../../../shared/hooks/useBaseMetaTags'
import { ThemeProvider } from '../../../shared/providers'
import { FormFields } from '../components/FormFields'
import { QuestionAccordion } from '../components/QuestionAccordion'
import { forgotPasswordEmailField, Links } from '../fixtures/account-fields'
import { accountPagesDataLayer } from '../utils'
import styles from './ForgotPasswordPage.module.scss'

const ForgotPasswordPage: FunctionComponent = () => {
  const [emailInput, setEmailInput] = useState('')
  const [displayOneMoreStep, setDisplayOneMoreStep] = useState(false)

  const { getBaseMetaTags } = useBaseMetaTags()
  const methods = useForm({ mode: 'onBlur' })
  const { handleSubmit } = methods

  const [forgotPasswordMutation, { data, error, loading }] = useForgotPasswordMutation({
    variables: {
      email: emailInput,
    },
  })

  const getMetaTags = ({ meta, imageUrl, twitterCard }: ForgotPasswordMetaArgs) => {
    return [...getBaseMetaTags({ meta, imageUrl, twitterCard })]
  }
  const metaTagObj = {
    canonical: 'https://www.which.co.uk/forgot-password',
    title: 'Forgotten password | Can’t login to which.co.uk',
    fbAppId: '320949621749168',
    desc: "Enter the email address you use for your which.co.uk account and we'll email you instructions on how to reset your password. Please be aware that the email to reset your password can take up to 10 minutes to reach your email account.",
  }

  useEffect(() => {
    if (data && data.forgotPassword) {
      const { forgotPassword } = data

      switch (forgotPassword.__typename) {
        case 'ForgotPasswordSuccess':
          setDisplayOneMoreStep(true)

          break
        case 'ForgotPasswordError':
          setDisplayOneMoreStep(false)
          break
      }
    }
  }, [data, displayOneMoreStep])

  const needHelpContent = (
    <Typography>
      If you’re still having problems resetting your password please visit our{' '}
      <Link href={`${Links.helpCenter}`}>help centre.</Link>
    </Typography>
  )

  const cantFindEmailContent = (
    <List>
      <ListItem appearance="number">
        <Typography>Check your spam or junk folder.</Typography>
      </ListItem>
      <ListItem appearance="number">
        <Typography>
          Still no email?
          <br />
          <Link href={`${Links.forgotPassword}`}>Send the email again</Link>
        </Typography>
      </ListItem>
      <ListItem appearance="number">
        <Typography>
          Still no luck?
          <br />
          Call us on <Link href="tel:+ 0292 2670000">0292 2670000</Link> (Mon-Fri 08:30-18:00, Sat
          09:00-13:00) at the standard network rate - or email us at{' '}
          <Link href="mailto:support@which.co.uk">support@which.co.uk.</Link>
        </Typography>
      </ListItem>
    </List>
  )

  const handleOnSubmit = (formObj) => {
    dynamicGa4DataLayerPush({
      event: 'click_button',
      item_text: 'Reset my password',
    })

    setEmailInput(formObj.email)
    forgotPasswordMutation()
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <ThemeProvider>
      <Helmet>
        {getMetaTags({
          meta: metaTagObj,
          imageUrl: 'https://www.which.co.uk/forgot-password',
          twitterCard: 'summary_large_image',
        })}
        <script>
          {accountPagesDataLayer({
            content_type: 'Forgot Password',
          })}
        </script>
      </Helmet>
      <Grid className={styles.forgotPasswordPage}>
        {!displayOneMoreStep && (
          <GridItem span={{ medium: 6, large: 6 }} columnStart={{ medium: 4, large: 4 }}>
            <Typography tag="h1" textStyle="title-650" className={styles.forgotPasswordPageTitle}>
              Reset your password
            </Typography>
            <Typography className={styles.forgotPasswordPageTitleCopy}>
              Enter the email address you use for your which.co.uk account and we'll email you
              instructions on how to reset your password.
            </Typography>
            <FormProvider {...methods}>
              <form>
                <FormFields fields={forgotPasswordEmailField} />
                <div className={styles.forgotPasswordPageFormFooter}>
                  <Button
                    data-testid="forgot-password-button"
                    enableSpinner={loading}
                    onClick={handleSubmit((formObj) => handleOnSubmit(formObj))}
                    className={styles.forgotPasswordPageSubmit}
                  >
                    Reset my password
                  </Button>
                  <div className={styles.forgotPasswordPageReturnUrl}>
                    <Link
                      appearance="primary"
                      href={Links.returnUrl}
                      textStyle="body"
                      data-which-id="link"
                      className={styles.forgotPasswordPageReturnUrlLink}
                    >
                      Cancel and return to login
                    </Link>
                  </div>
                </div>
              </form>
            </FormProvider>
            <QuestionAccordion
              label="Need help?"
              content={needHelpContent}
              calledFrom="forgotPassword"
            />
          </GridItem>
        )}
        {displayOneMoreStep && (
          <GridItem span={{ medium: 6, large: 6 }} columnStart={{ medium: 4, large: 4 }}>
            <Typography tag="h1" textStyle="title-650" className={styles.forgotPasswordPageTitle}>
              One more step - check your inbox
            </Typography>
            <Typography className={styles.forgotPasswordPageTitleCopy}>
              If <b>{emailInput}</b> exists in our records we have sent an email to that address
              containing a link to reset your password. <br />
              <br />
              Please be aware that the email can take up to 10 minutes to reach your inbox.
            </Typography>
            <div className={styles.forgotPasswordPageAnotherEmail}>
              <Link
                appearance="primary"
                href={Links.forgotPassword}
                textStyle="body"
                data-which-id="link"
                className={styles.forgotPasswordPageAnotherEmailLink}
              >
                Enter another email address?
              </Link>
            </div>
            <QuestionAccordion
              label="Can't find the email?"
              content={cantFindEmailContent}
              calledFrom="forgotPassword"
            />
          </GridItem>
        )}
      </Grid>
    </ThemeProvider>
  )
}

export default ForgotPasswordPage

/////// IMPLEMENTATION /////////

type ForgotPasswordMetaArgs = {
  meta: Partial<Meta>
  imageUrl: string
  twitterCard: string
}
