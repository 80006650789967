import type { FunctionComponent } from 'react'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

import { loadScript } from '../../../../shared/utils'

export const AccordionLegacy: FunctionComponent<Props> = ({ content }) => {
  useEffect(() => {
    loadScript({
      src: 'https://www.which.co.uk/static/tools/new-reviews/js/accordion.js',
      async: true,
    })
  }, [])

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://www.which.co.uk/static/tools/new-reviews/50-ways/css/dist/application.css"
        ></link>
      </Helmet>
      <div data-testid="accordion-legacy" dangerouslySetInnerHTML={{ __html: content }} />
    </>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  content: string
}
