import type { FunctionComponent } from 'react'
import React from 'react'
import {
  Button,
  ButtonLink,
  Link,
  PadlockIcon,
  SolidPadlockIcon,
  Typography,
} from '@which/seatbelt'

import classnames from 'classnames'

import styles from './SinglePadlockCta.module.scss'

export const SinglePadlockCta: FunctionComponent<Props> = ({
  type,
  setModalOpen,
  featureVariation,
}) => (
  <div className={classnames(styles.ctaWrapper, type === 'desktop' && styles['ctaWrapperDesktop'])}>
    <div className={styles.icon}>
      <PadlockIcon />
    </div>
    <div className={styles.ctaContent}>
      <Typography textStyle="title-400">Sign up to reveal</Typography>
      <Typography className={styles.contentWrapper}>
        Get instant access to this and all our scores and recommendations.
      </Typography>
      {featureVariation === 'cta-to-offers-page' ? (
        <ButtonLink
          appearance="secondary"
          href="https://join.which.co.uk/join/subscribe"
          className={styles.cta}
          data-which-id="nstunlockedprovider-signup-cta"
        >
          <SolidPadlockIcon width={15} height={15} viewBox="0 0 15 15" />
          Unlock table
        </ButtonLink>
      ) : (
        <Button
          appearance="secondary"
          className={styles.cta}
          onClick={() => setModalOpen(true)}
          data-which-id="nstunlockedprovider-signup-cta"
        >
          <SolidPadlockIcon width={15} height={15} viewBox="0 0 15 15" />
          Unlock table
        </Button>
      )}

      <Typography textStyle="very-small-print">
        First month £5, then £10.99 per month, cancel at any time
      </Typography>
      <Typography>
        <span>Already a member? </span>
        <Link href="https://www.which.co.uk/login">Log in</Link>
      </Typography>
    </div>
  </div>
)

type Props = {
  type?: string
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  featureVariation?: 'control' | 'cta-to-offers-page' | 'cta-to-modal'
}
