import type { FunctionComponent } from 'react'
import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { RenderTool } from '@which/tool-framework'

import { findBlaizeSessionAvailable } from '../../../pages/account/utils'
import { getQueryString } from '../../utils'
import { getSessionStorageItem } from '../../utils/get-session-storage-item'
import { Loader } from '../Loader'
import { ToolFramework404Error } from '../ToolFramework404Error'

export const ToolFramework: FunctionComponent<ToolProps> = ({
  toolName,
  optionsJson,
  CRFooter,
  showFeedback,
  toolUrl,
  title,
  questionText,
  standfirst,
  buttonText,
  moneyHealthResults,
  isLoggedIn,
}) => {
  const surveySessionData = getSessionStorageItem('impactSurveyData')

  // used for the booking appointment tool only
  const blaizeId = typeof window === 'undefined' ? '' : findBlaizeSessionAvailable('blaize_session')
  const location = useLocation()
  const isRebookingAppointmentPage = location.pathname.endsWith('/tool/change-appointment')

  if (toolName === 'ImpactSurvey') {
    if (typeof window === 'undefined') {
      return null
    }
    if (
      (!surveySessionData || !surveySessionData.email || !surveySessionData.toolID) &&
      ((typeof window !== 'undefined' && !window.location.search) ||
        !getQueryString('email') ||
        !getQueryString('tool'))
    ) {
      return <ToolFramework404Error message="impact survey error" />
    }
  } else if (
    toolName !== 'EnergyCostCalculator' &&
    toolName !== 'PriceRiseCalculator' &&
    typeof isLoggedIn === 'undefined'
  ) {
    return <Loader />
  } else if (
    toolName === 'BookAppointment' &&
    !isRebookingAppointmentPage &&
    !isLoggedIn &&
    typeof isLoggedIn !== 'undefined' &&
    blaizeId === null
  ) {
    return <Redirect to={`/login?return_url=${location.pathname.slice(1)}${location.search}`} />
  }
  return RenderTool({
    toolName,
    optionsJson,
    CRFooter,
    showFeedback,
    toolUrl,
    title,
    questionText,
    standfirst,
    buttonText,
    moneyHealthResults,
  })
}

type ToolProps = {
  toolName: string
  optionsJson: string
  CRFooter: React.ReactNode
  showFeedback: boolean
  title: string
  toolUrl: string
  questionText: string
  standfirst: string
  buttonText: string
  moneyHealthResults: React.ReactNode
  isLoggedIn?: boolean
}
