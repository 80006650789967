import type { FunctionComponent, SyntheticEvent } from 'react'
import React from 'react'
import { Link, Typography } from '@which/seatbelt'
import type { Rest } from '@which/shared'
import { dynamicDatalayerPush } from '@which/shared'

import classnames from 'classnames'

import { totalProductColumns } from '../ComparisonTable'
import styles from './AddProducts.module.scss'

export const AddProducts: FunctionComponent<Props> = ({
  taxonomySlug,
  numberOfProducts,
  className,
  ...rest
}) => {
  return (
    <div className={classnames(styles.addProductsContainer, className)} {...rest}>
      <Link
        href={`/reviews/${taxonomySlug}`}
        onClick={(e: SyntheticEvent) => {
          e.stopPropagation()
          dynamicDatalayerPush({
            eventCategory: 'Product Compare',
            eventAction: 'Add Products',
            eventLabel: `Current Products - ${numberOfProducts}`,
          })
        }}
      >
        <Typography textStyle="small-print-bold">
          {numberOfProducts === totalProductColumns - 1 ? 'Add product' : 'Add products'}
        </Typography>
      </Link>
    </div>
  )
}

type Props = {
  taxonomySlug: string
  numberOfProducts: number
  className?: string
} & Rest
