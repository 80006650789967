import type { FunctionComponent } from 'react'
import React from 'react'
import { ButtonLink, Grid, GridItem, Image, Typography } from '@which/seatbelt'

import styles from './CRScamsSignUp.module.scss'
import desktopImage from './image-desktop.png'

export const CRScamsSignUp: FunctionComponent = () => {
  return (
    <Grid includeGaps={false} includeGutters={false} className={styles.wrapper}>
      <GridItem
        className={styles.textAndButton}
        columnStart={{ medium: 1, large: 1 }}
        span={{ medium: 12, large: 8 }}
      >
        <Typography tag="h2" textStyle="title-600" className={styles.signUpText}>
          Sign up for scam alerts
        </Typography>
        <Typography textStyle="body" className={styles.signUpText}>
          Our emails will alert you to scams doing the rounds, and provide practical advice to keep
          you one step ahead of fraudsters.
        </Typography>
        <ButtonLink
          href="https://campaigns.which.co.uk/scam-alert-service/"
          className={styles.button}
        >
          Sign up for scam alerts
        </ButtonLink>
      </GridItem>
      <GridItem
        columnStart={{ medium: 1, large: 9 }}
        span={{ medium: 12, large: 4 }}
        className={styles.imageContainer}
      >
        <Image
          src={desktopImage}
          alt="Sign up"
          wrapperClassName={styles.imageWrapper}
          imageClassName={styles.image}
        />
      </GridItem>
    </Grid>
  )
}
