import type { FunctionComponent } from 'react'
import React from 'react'
import { AnimationWrapper, ChevronRightIcon, StandardLink, Typography } from '@which/seatbelt'

import styles from './AdviceCards.module.scss'

export const AdviceCards: FunctionComponent<Props> = ({ heading, cards = [], className }) => (
  <div data-testid="advice-cards-wrapper" className={className}>
    {heading && (
      <Typography tag="h2" textStyle="title-600" className={'sb-margin-bottom-3'}>
        {heading}
      </Typography>
    )}
    {cards?.length > 0 && (
      <div className={styles.adviceCards}>
        {cards.map(({ title, strapline, href, linkText }, index) => (
          <StandardLink
            href={href}
            className={styles.adviceCardsLink}
            noAnimation={true}
            key={`${title}=${index}`}
          >
            <Typography tag="h2" textStyle="title-500" className={styles.adviceCardsLinkTitle}>
              {title}
            </Typography>
            <Typography textStyle="body" className={styles.adviceCardsLinkStrapline}>
              {strapline}
            </Typography>
            <ChevronRightIcon className={styles.adviceCardsLinkIcon} />
            <AnimationWrapper className={styles.adviceCardsLinkAnimation}>
              {linkText}
            </AnimationWrapper>
          </StandardLink>
        ))}
      </div>
    )}
  </div>
)

///////// IMPLEMENTATION /////////

type Props = {
  heading: string
  cards: Array<{
    href: string
    linkText: string
    strapline: string
    title: string
  }>
  className?: string
}
