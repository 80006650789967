import React, { Fragment } from 'react'
import { Grid, GridItem, StandardLink, Typography } from '@which/seatbelt'

import { ThemeProvider } from '../../shared'
import styles from './DevHomepage.module.scss'

export const DevHomepage = () => (
  <ThemeProvider>
    <Grid className="w-page-wrapper">
      <GridItem className={styles.mainGrid}>
        <Typography tag="h1" textStyle="title-600" className={styles.mainTitle}>
          Welcome to Which?
        </Typography>
        <Typography tag="h2" textStyle="title-400" className={styles.subTitle}>
          Repositories
        </Typography>

        {repos.map((repo) => (
          <div className={styles.section} key={repo.name}>
            <StandardLink
              className={styles.repoName}
              href={`https://github.com/whichdigital/${repo.slug}`}
              target="_blank"
              textStyle="title-400"
            >
              {repo.name}
            </StandardLink>
            <div>
              <StandardLink
                className={styles.ciBadge}
                href={`https://dl.circleci.com/status-badge/redirect/gh/whichdigital/${repo.slug}/tree/master`}
              >
                <img
                  src={`https://dl.circleci.com/status-badge/img/gh/whichdigital/${repo.slug}/tree/master.svg?style=svg&circle-token=${repo.token}`}
                  alt="Status badge"
                />
              </StandardLink>
              {sections.map((section) => (
                <Fragment key={section.name}>
                  {' - '}
                  <StandardLink
                    href={`https://github.com/whichdigital/${repo.slug}/${section.slug}`}
                    target="_blank"
                  >
                    {section.name}
                  </StandardLink>
                </Fragment>
              ))}
            </div>
          </div>
        ))}

        <Typography tag="h2" textStyle="title-400" className={styles.subTitle}>
          Squads
        </Typography>
        <StandardLink
          href="https://whichonline.atlassian.net/wiki/spaces/SQUAD/overview"
          target="_blank"
        >
          Squad overview
        </StandardLink>
      </GridItem>
    </Grid>
  </ThemeProvider>
)

///////// IMPLEMENTATION /////////

const sections = [
  { name: 'Pull requests', slug: 'pulls' },
  { name: 'Security alerts', slug: 'security/dependabot' },
  { name: 'Wiki', slug: 'wiki' },
  { name: 'Insights', slug: 'pulse' },
]
const repos = [
  {
    slug: 'which-content-delivery',
    name: 'Content Delivery',
    token: 'd0e37fcbae6e9ccbe60b8fb63f61355373a9c91b', // token for CircleCi badge
  },
  {
    slug: 'which-design-system',
    name: 'Seatbelt',
    token: '70e07b98e77ff402a58f0d397ffc6d3bdf54fdc0', // token for CircleCi badge
  },
]
