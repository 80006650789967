import type { FunctionComponent } from 'react'
import React from 'react'
import { GridItem } from '@which/seatbelt'
import type { OnwardJourneys } from '@which/shared'
import type { StaticToolBaseProps } from '@which/static-tools'

import loadable from '@loadable/component'

//TODO CTX-1563: Refactor to load static tools in SSR
const Component: FunctionComponent<StaticToolBaseProps> = loadable(
  (props) => import(`@which/static-tools/build/tools/${props.toolName}`),
  {
    ssr: true,
  }
)

export const StaticTool: FunctionComponent<Props> = ({ toolName, onwardJourneys }) => (
  <GridItem data-testid="static-tool">
    <Component
      data-testid="static-tool-component"
      toolName={toolName}
      onwardJourneys={onwardJourneys}
    />
  </GridItem>
)

///////// IMPLEMENTATION /////////

type Props = {
  toolName?: string
  onwardJourneys?: OnwardJourneys
}
