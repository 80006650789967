import type { FunctionComponent } from 'react'
import React from 'react'
import { Link, Picture, Typography } from '@which/seatbelt'

import classnames from 'classnames'

import styles from './StandardCard.module.scss'

export const StandardCard: FunctionComponent<Props> = ({
  body = '',
  className,
  imageAlt,
  imageSrc,
  label,
  linkText = '',
  linkUrl = '',
  standFirst,
  title,
  style,
  inlineImage,
}) => {
  return (
    <div
      key={title}
      className={classnames(className, {
        [styles.card]: style !== 'article',
        [styles.cardArticle]: style === 'article',
        [styles.cardHorizontal]: inlineImage,
      })}
      data-testid="standard-card"
    >
      <Picture
        alt={imageAlt}
        lazyLoad={false}
        src={imageSrc}
        className={classnames({
          [styles.cardArticleImage]: style === 'article',
        })}
      />
      <div
        className={classnames({
          [styles.cardArticleContent]: style === 'article',
          [styles.cardHorizontalContent]: inlineImage,
        })}
      >
        {label && (
          <div className={styles.cardLabel}>
            <Typography textStyle="very-small-print-bold">{label}</Typography>
          </div>
        )}
        <Typography tag="h3" textStyle="title-400" className={styles.cardHeader}>
          {title}
        </Typography>
        {standFirst && <Typography textStyle="body">{standFirst}</Typography>}
        {body && (
          <div
            data-testid="standard-card-body-text"
            dangerouslySetInnerHTML={{ __html: body }}
            className={classnames(styles.cardBody, {
              [styles.cardHorizontalContentBody]: inlineImage,
            })}
          />
        )}
        {linkText && linkUrl && <Link href={linkUrl}>{linkText} </Link>}
      </div>
    </div>
  )
}

type Props = {
  body?: string
  className?: string
  imageAlt: string
  imageSrc: string
  label?: string
  linkText?: string
  linkUrl?: string
  standFirst?: string
  style?: string
  title: string
  inlineImage?: boolean
}
