import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Checkbox, Grid, GridItem, Link, Typography } from '@which/seatbelt'
import { dynamicGa4DataLayerPush } from '@which/shared'

import classnames from 'classnames'

import type { Meta } from '../../../generated/frontend'
import { useLoginMutation } from '../../../generated/frontend'
import { Error } from '../../../shared'
import type { Props } from '../../../shared/components/InfoBanner'
import { InfoBanner } from '../../../shared/components/InfoBanner'
import { useBaseMetaTags } from '../../../shared/hooks/useBaseMetaTags'
import { ThemeProvider } from '../../../shared/providers'
import { FormFields } from '../components/FormFields'
import { PasswordFieldView } from '../components/PasswordField'
import { Links, LoginPageBannerText, UsernameField } from '../fixtures/account-fields'
import { accountPagesDataLayer } from '../utils'
import styles from './LoginPage.module.scss'
import { processLoginMutationResponse } from './loginUtils'

const LoginPage: FunctionComponent = () => {
  const [content, setContent] = useState('')
  const [context, setContext] = useState<Props['context']>('error')
  const [stayLoggedin, setStayLoggedIn] = useState(true)
  const [showInfoBanner, setShowInfoBanner] = useState(false)
  const [passwordInput, setPasswordInput] = useState('')
  const [title, setTitle] = useState('')
  const [usernameInput, setUSernameInput] = useState('')

  const { getBaseMetaTags } = useBaseMetaTags()
  const methods = useForm({ mode: 'onBlur' })
  const { handleSubmit } = methods

  const [loginMutation, { data, error, loading }] = useLoginMutation({
    variables: {
      username: usernameInput,
      password: passwordInput,
    },
  })

  const metaTagObj = {
    canonical: 'https://www.which.co.uk/login',
    title: "Login to which.co.uk | Discover Best Buys & Don't Buys",
    fbAppId: '320949621749168',
    desc: 'From this page members can log directly into which.co.uk. Access thousands of expertly tested independent reviews to help you make confident choices. Please have your email address or username and password ready.',
  }

  const getMetaTags = ({ meta, imageUrl, twitterCard }: LoginMetaArgs) => {
    return [...getBaseMetaTags({ meta, imageUrl, twitterCard })]
  }

  useEffect(() => {
    if (data && data.login) {
      const { login } = data

      if (login.__typename === 'AuthenticationError') {
        setShowInfoBanner(true)
        setContent(LoginPageBannerText.authErrorContentText)
        setContext('error')
        setTitle(LoginPageBannerText.authErrorText)
        dynamicGa4DataLayerPush({
          event: 'notification_error',
          action_group: 'login',
          item_text: LoginPageBannerText.authErrorText,
        })
      }
    }
  }, [data])

  useEffect(() => {
    if (document.cookie.includes('ref=reset-password')) {
      document.cookie = 'ref= ; '
      setShowInfoBanner(true)
      setContent(LoginPageBannerText.Success)
      setContext('correct')
      setTitle('')
    }
  }, [])

  const handleOnSubmit = (formObj) => {
    dynamicGa4DataLayerPush({
      event: 'click_button',
      item_text: 'Log in',
    })
    setShowInfoBanner(false)
    setPasswordInput(formObj.password)
    setUSernameInput(formObj.username)
    loginMutation()
  }

  if (error) {
    return <Error error={error} />
  }

  if (data && data.login) {
    const { login } = data

    processLoginMutationResponse({
      login,
      stayLoggedIn: stayLoggedin,
    })
  }

  return (
    <ThemeProvider>
      <Helmet>
        {getMetaTags({
          meta: metaTagObj,
          imageUrl: 'https://www.which.co.uk/login',
          twitterCard: 'summary_large_image',
        })}
        <script>
          {accountPagesDataLayer({
            content_type: 'login',
          })}
        </script>
      </Helmet>
      <Grid className={classnames(styles.loginPage, showInfoBanner && styles[context])}>
        <GridItem span={{ medium: 6, large: 4 }} columnStart={{ medium: 4, large: 5 }}>
          {showInfoBanner && <InfoBanner content={content} context={context} title={title} />}
          <Typography tag="h1" textStyle="title-650" className={styles.loginPageTitle}>
            Log into Which?
          </Typography>
          <FormProvider {...methods}>
            <form>
              <FormFields fields={UsernameField} />
              <PasswordFieldView
                displayPasswordCheckList={false}
                name="password"
                label="Password"
                rulesRequired={false}
                calledFrom="login"
                errorMessageText="Please enter your password"
                maxLength="50"
                showRequiredText={false}
                autoComplete="password"
                validation={{
                  required: true,
                }}
              />
              <div className={styles.loginPageFormFooter}>
                <Checkbox
                  type="checkbox"
                  className={styles.loginPageStayLoggedInDiv}
                  name="stay-logged-in"
                  id="stay-logged-in"
                  value=""
                  label="Stay logged in"
                  checked={stayLoggedin}
                  onChangeCallback={() => {
                    setStayLoggedIn(!stayLoggedin)
                  }}
                />
                <div className={styles.loginPageForgotPassword}>
                  <Link
                    appearance="primary"
                    href={Links.forgotPassword}
                    textStyle="body"
                    data-which-id="link"
                    className={styles.loginPageForgotPasswordLink}
                  >
                    Forgotten password?
                  </Link>
                </div>
              </div>
              <Typography textStyle="small-print-compact" className={styles.loginPageUntickText}>
                Untick if you're using a public device
              </Typography>
              <Button
                data-testid="login-button"
                enableSpinner={loading}
                onClick={handleSubmit((formObj) => handleOnSubmit(formObj))}
                className={styles.loginPageSubmit}
              >
                Log in
              </Button>
            </form>
          </FormProvider>
          <Typography textStyle="small-print-compact" className={styles.loginPageSignupLink}>
            Not a Which? member?{' '}
            <Link data-which-id="link" href={Links.join}>
              Sign up now
            </Link>
          </Typography>
        </GridItem>
      </Grid>
    </ThemeProvider>
  )
}

export default LoginPage

/////// IMPLEMENTATION /////////

type LoginMetaArgs = {
  meta: Partial<Meta>
  imageUrl: string
  twitterCard: string
}
