export const updateUrlQueryParams = (updatedValues: QueryValues): string | undefined => {
  if (typeof window === 'undefined') {
    return
  }

  const { search, pathname } = window.location

  const params = new URLSearchParams(search)

  for (const key in updatedValues) {
    const newValue = updatedValues[key]

    if (newValue !== '') {
      params.set(key, String(newValue))
    } else {
      params.delete(key)
    }
  }

  const updatedParams = params.toString()

  return updatedParams ? `${pathname}?${updatedParams}` : pathname
}

type QueryValues = {
  [key: string]: string | number
}
