/* istanbul ignore file */

import React, { useContext } from 'react'
import type { PageContext, TemplateName } from '@which/glide-ts-types'

export const PagePropsContext = React.createContext<Context>({
  template: '' as TemplateName,
})

export function usePageProps() {
  return useContext(PagePropsContext)
}

///////// IMPLEMENTATION /////////

export type Context = {
  template?: TemplateName
  rootCollectionName?: string
  context?: PageContext
}
