import type { FunctionComponent } from 'react'
import React from 'react'
import type { ColNumbers } from '@which/seatbelt'
import {
  ButtonLink,
  ChevronRightIcon,
  Grid,
  GridItem,
  StandardLink,
  Typography,
} from '@which/seatbelt'

import type { Author } from '../../../../generated/frontend'
import { Fact } from './Fact/Fact'
import styles from './ProductHubAdvice.module.scss'

export const ProductHubAdvice: FunctionComponent<ProductHubAdviceProps> = ({
  heading,
  description,
  legalCopy,
  sections,
  buttonCTAText,
  buttonCTAHref,
  expertInfo,
}) => (
  <section aria-labelledby="product-hub-advice">
    <Typography textStyle="title-600" tag="h2" id="product-hub-advice" className={styles.heading}>
      {heading}
    </Typography>
    <Typography textStyle="standfirst" className={styles.description}>
      {description}
    </Typography>
    {legalCopy && (
      <div className={styles.legalCopy} dangerouslySetInnerHTML={{ __html: legalCopy }} />
    )}
    <div className={styles.expertViewWrapper}>
      <Fact body={expertInfo.body} title={expertInfo.title} author={expertInfo.author} />
    </div>
    <Grid includeGutters={false} className={styles.grid}>
      {sections.map((section, index) => {
        const calculateStartColumn = () => {
          return [1, 5, 9][index] || 1
        }
        const key = section.title.replace(/\s+/g, '-').toLowerCase()
        return (
          <GridItem
            key={key}
            columnStart={{
              medium: calculateStartColumn() as ColNumbers,
              large: calculateStartColumn() as ColNumbers,
            }}
            span={{ medium: 4, large: 4 }}
          >
            <Typography textStyle="title-400" tag="h3" id={key} className={styles.listHeading}>
              {section.title}
            </Typography>
            <ul className={styles.list}>
              {section.links.map((link, index) => (
                <li className={styles.listItem} key={link.href}>
                  <StandardLink
                    href={link.href}
                    data-which-id="producthub-link"
                    data-section={heading.toLowerCase()}
                    data-subsection={section.title.toLowerCase()}
                    data-index={index + 1}
                  >
                    {link.text}
                  </StandardLink>
                </li>
              ))}
            </ul>
          </GridItem>
        )
      })}
    </Grid>
    <div className={styles.ctaWrapper}>
      <ButtonLink appearance="secondary" href={buttonCTAHref} data-which-id="producthub-button">
        <ChevronRightIcon />
        {buttonCTAText}
      </ButtonLink>
    </div>
  </section>
)

///////// IMPLEMENTATION /////////

export type ProductHubAdviceProps = {
  heading: string
  description: string
  legalCopy?: string
  sections: ProductHubAdviceSection[]
  buttonCTAText: string
  buttonCTAHref: string
  expertInfo: {
    author: Author
    title: string
    body: string
  }
}

export type ProductHubAdviceSection = {
  title: string
  links: Array<{
    text: string
    href: string
  }>
}
