import type { FunctionComponent } from 'react'
import { useContext, useState } from 'react'
import React from 'react'
import type { BadgeTheme, ProductColour, ProductScoreGaugeProps } from '@which/seatbelt'
import {
  AnimationWrapper,
  AnnualRunningCost,
  Badge,
  ButtonLink,
  ColourSwatchGroup,
  Link,
  ProductScoreGauge,
  SolidPadlockIcon,
  StandardLink,
  Typography,
} from '@which/seatbelt'
import {
  AMAZON_AFFILIATE_TAG,
  dynamicDatalayerPush,
  dynamicGa4DataLayerPush,
  getTaggedAmazonUrl,
} from '@which/shared'

import classnames from 'classnames'
import compact from 'lodash/compact'

import type {
  AnnualRunningCost as AnnualRunningCostType,
  ProductOffer,
} from '../../../generated/frontend'
import { ArticleTemplateContext } from '../../../pages/article/ArticleTemplateContext'
import { ArticleTrackonomicsLink } from '../../../pages/article/components/ArticleTrackonomicsLink'
import { ProductBadges } from '../../../pages/reviews/shared/components/ProductBadges/ProductBadges'
import { addHandlerToRunningCostTooltip } from '../../../pages/reviews/utils/add-handler-to-running-cost-tooltip'
import { convertPriceValueToFloat } from '../../../pages/reviews/utils/convert-price-value-to-float'
import { mapAnnualRunningCost } from '../../../pages/reviews/utils/map-annual-running-cost'
import { formatRetailers } from '../../utils/formatRetailers'
import styles from './CardedDynamicTable.module.scss'
import { LoggedOutImage } from './LoggedOutImage'
import { TopRated } from './TopRated/TopRated'

export const TableItem: FunctionComponent<TableItemProps> = ({
  dynamicTableId,
  index,
  loginStatus,
  logInCTA,
  signUpCTA,
  trackingPrefix,
  availableOffersCount = 0,
  badgeNames = [],
  categorySlug,
  description,
  image = { images: [] },
  manufacturer,
  model,
  price,
  slug,
  productScoreGauge,
  keyTestResults = [],
  annualRunningCost,
  businessKey,
  variants,
  offers = [],
  topRated,
  restricted,
  showProductFeature,
}) => {
  const [selectedVariant, setSelectedVariant] = useState(variants?.[0])
  const hasSafetyAlert = badgeNames.find((badge) => badge === 'safety alert')
  const filteredBadges = badgeNames.filter((badge) => badge !== 'safety alert')
  const manufacturerName = manufacturer?.name
  const validOffers = formatRetailers(offers)
  const restrictOptions = {
    showImage:
      (image?.images?.length && !restricted?.revealProductABTest) ||
      (restricted?.revealProductABTest && (description || showProductFeature)),
    showModel:
      (model && !restricted?.revealProductABTest) ||
      (restricted?.revealProductABTest && (description || showProductFeature)),
    showAffiliateLinks:
      (availableOffersCount > 0 && !restricted?.revealProductABTest) ||
      (restricted?.revealProductABTest && (description || showProductFeature)),
    showBadges:
      (filteredBadges && !restricted?.revealProductABTest) ||
      (restricted?.revealProductABTest && (description || !showProductFeature)),
    showKeyTestResults:
      (keyTestResults.length > 0 && !restricted?.revealProductABTest) ||
      (restricted?.revealProductABTest && (description || !showProductFeature)),
  }

  const formattedRunningCost =
    annualRunningCost &&
    addHandlerToRunningCostTooltip(mapAnnualRunningCost({ annualRunningCost, loginStatus }), () =>
      runningCostTooltipHandler(trackingPrefix)
    )

  const handleAffiliateClick = (validOffer: ProductOffer) => {
    const item_spec = new URL(validOffer.url).hostname

    dynamicDatalayerPush({
      eventCategory: 'Where to Buy',
      eventAction: 'Go to Retailer',
      eventLabel: `${
        validOffer.retailer.name
      } | ${manufacturerName} ${model} | ${convertPriceValueToFloat(validOffer.priceValue, true)}`,
      eventValue: Math.floor(Number(convertPriceValueToFloat(validOffer.priceValue))),
      item_url: validOffer.url,
      item_spec,
      item_group: trackingPrefix === 'spp' ? 'smart product picker' : 'dynamic table',
    })

    if (topRated) {
      dynamicDatalayerPush({
        eventCategory: 'ab test',
        eventAction: 'WHI302',
        eventLabel: 'Top card - where to buy clicks',
      })
    }
  }

  return (
    <li key={`${dynamicTableId}-${index}`} className={styles.listItem} data-testid="cdt-card">
      <article className={classnames(styles.card, topRated && 'topRatedVariants2366')}>
        {/* Top rated variants; https://whichonline.atlassian.net/browse/RS-2366 */}
        {topRated && <TopRated />}
        {topRated && <TopRated link={`/reviews/${categorySlug}`} />}
        <div className={styles.wrapper}>
          {restrictOptions.showBadges && (
            <div data-testid="badges">
              <ProductBadges badges={filteredBadges} className={styles.badges} />
            </div>
          )}
          <div className={styles.summary}>
            <div className={classnames(styles.imageContainer, styles.summaryImage)}>
              {hasSafetyAlert && (
                <Badge theme={'safety alert' as BadgeTheme} className={styles.safetyAlert} />
              )}
              {restrictOptions.showImage ? (
                <picture>
                  {getSources(image.images)?.map(({ srcset, type }, imageIndex) => (
                    <source key={`image-source-${imageIndex + 1}`} srcSet={srcset} type={type} />
                  ))}
                  <img
                    className={styles.productImage}
                    src={selectProductImage(image.images)}
                    alt={`${manufacturerName} ${model}`}
                  />
                </picture>
              ) : (
                <LoggedOutImage
                  data-testid="logged-out-image"
                  data-which-id={`${trackingPrefix}-product-image-signup`}
                  className={styles.productImage}
                />
              )}
            </div>
            <div className={styles.summaryDetails}>
              {restrictOptions.showModel ? (
                <div className={styles.section} data-testid="product-title">
                  <Typography tag="h3" textStyle="title-400">
                    {manufacturerName}
                  </Typography>
                  <Typography className={styles.productTitle} textStyle="body">
                    {model}
                  </Typography>
                </div>
              ) : (
                <div className={styles.section}>
                  <div className={styles.hiddenManufacturer} />
                  <div className={styles.hiddenModel} />
                </div>
              )}
              {variants && selectedVariant && (
                <div className={styles.colourSwatch}>
                  <ColourSwatchGroup
                    name={`colour-swatch-group-${businessKey}`}
                    variants={variants}
                    selectedVariant={selectedVariant}
                    onChange={setSelectedVariant}
                    classNameRadioGroup={styles.radioGroup}
                  />
                </div>
              )}
              <div className={styles.section}>
                {price && availableOffersCount === 0 && (
                  <>
                    <Typography textStyle="title-400" tag="span" className={styles.price}>
                      {price}
                    </Typography>
                    {availableOffersCount === 0 && (
                      <Typography tag="span" className={styles.typicalPriceLabel}>
                        Typical price
                      </Typography>
                    )}
                  </>
                )}
                {restrictOptions.showAffiliateLinks && (
                  <ol
                    className={styles.offersList}
                    data-which-id="cheapest-available-retailers"
                    data-testid="cheapest-available-retailers"
                  >
                    {validOffers.map((validOffer) => {
                      const { retailer, url, isTrackable, formattedPrice } = validOffer
                      return (
                        <li key={retailer.name} className={styles.offersListItem}>
                          {isTrackable ? (
                            <ArticleTrackonomicsLink
                              href={url}
                              contentType={'article'}
                              optionalTracking={{
                                item_group:
                                  trackingPrefix === 'spp'
                                    ? 'smart product picker'
                                    : 'dynamic table',
                              }}
                              onClick={() => handleAffiliateClick(validOffer)}
                            >
                              <Typography tag="span" textStyle="small-print-medium">
                                <AnimationWrapper>{`${formattedPrice} from ${retailer.name}`}</AnimationWrapper>
                              </Typography>
                            </ArticleTrackonomicsLink>
                          ) : (
                            <StandardLink
                              href={getTaggedAmazonUrl(url, AMAZON_AFFILIATE_TAG)}
                              onClick={() => handleAffiliateClick(validOffer)}
                              className={styles.link}
                              aria-label={`Buy from ${retailer.name} at ${formattedPrice}`}
                              data-which-id="affiliate-link"
                              target="_blank"
                              rel="nofollow noopener noreferrer"
                            >
                              <Typography tag="span" textStyle="small-print-medium">
                                <AnimationWrapper>{`${formattedPrice} from ${retailer.name}`}</AnimationWrapper>
                              </Typography>
                            </StandardLink>
                          )}
                        </li>
                      )
                    })}
                  </ol>
                )}
              </div>
              {restrictOptions.showKeyTestResults && (
                <ul className={styles.keyTestResultsList} data-testid="key-test-results">
                  {keyTestResults.map(({ label }) => (
                    <li key={label} className={styles.keyTestResultsListItem}>
                      <SolidPadlockIcon
                        data-testid="padlock-icon"
                        width={15}
                        height={15}
                        viewBox="0 0 15 15"
                        className={styles.padlockIcon}
                      />
                      <Typography tag="span" textStyle="body">
                        {label}
                      </Typography>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {productScoreGauge && (
              <ProductScoreGauge
                {...productScoreGauge}
                className={classnames(styles.section, styles.scoreAndBadges)}
              />
            )}
          </div>
          {description && (
            <Typography className={styles.description} textStyle="body" data-testid="description">
              {description}
            </Typography>
          )}
          <ButtonLink
            className={styles.signuplink}
            href={signUpCTA?.url || `/reviews/${categorySlug}/${slug}#review`}
            appearance="secondary"
            data-test-element="review-button"
            data-tracking-element="review-button"
            data-which-id={
              signUpCTA?.url
                ? `${trackingPrefix}-product-signup`
                : `${trackingPrefix}-review-button`
            }
            data-testid="sign-up-button"
          >
            {signUpCTA?.label || 'Read full review'}
          </ButtonLink>
          {signUpCTA?.smallPrint && (
            <Typography textStyle="very-small-print">{signUpCTA.smallPrint}</Typography>
          )}
          {logInCTA && (
            <div className={styles.loginWrapper}>
              <Typography tag="span" textStyle="small-print-compact" data-testid="login-cta">
                {logInCTA?.copy}
                <Link
                  className={styles.loginLink}
                  data-test-element="description-log-in"
                  data-which-id={`${trackingPrefix}-member-log-in-link`}
                  href={logInCTA.url}
                >
                  {logInCTA.label}
                </Link>
              </Typography>
            </div>
          )}
        </div>
        {formattedRunningCost && <AnnualRunningCost {...formattedRunningCost} />}
      </article>
    </li>
  )
}

export const CardedDynamicTable: FunctionComponent<CardedDynamicTableProps> = ({
  dynamicTableId = '',
  logInCTA,
  products,
  signUpCTA,
  title,
  trackingPrefix = 'dynamic-table',
  restricted,
  showProductFeature,
}) => {
  const { meta } = useContext(ArticleTemplateContext)
  const loginStatus = meta.dataLayer?.[1]?.loginStatus

  if (!products?.length) {
    return null
  }

  const brands = new Set()

  products.forEach((product) => brands.add(product?.manufacturer?.slug))

  const brandString = Array.from(brands).join(',')

  return (
    <div
      data-dt-product-count={products.length}
      {...(brandString && { 'data-dt-product-brands': brandString })}
      data-testid="carded-dynamic-table"
      data-dynamic-table-id={dynamicTableId}
      className={styles.container}
    >
      {title && (
        <Typography tag="h2" textStyle="title-600" className="h2-article">
          {title}
        </Typography>
      )}

      <ul className={styles.list}>
        {products.map((product, index) =>
          TableItem({
            index,
            loginStatus,
            logInCTA,
            signUpCTA,
            trackingPrefix,
            restricted,
            showProductFeature,
            ...product,
          })
        )}
      </ul>
    </div>
  )
}

///////// IMPLEMENTATION /////////

export const runningCostTooltipHandler = (trackingPrefix: 'spp' | 'dynamic-table') => {
  dynamicGa4DataLayerPush({
    item_text: 'annual running cost',
    item_parent_text: 'product card',
    event: trackingPrefix === 'dynamic-table' ? 'clickTooltipDynamic' : 'clickTooltipSpp',
  })
}

export type CardedDynamicTableProduct = {
  availableOffersCount?: number
  badgeNames?: string[]
  categorySlug?: string
  description?: string
  image?: {
    images: Image[]
  }
  manufacturer?: {
    name: string
    slug: string
  }
  model?: string
  overallScore?: number
  price?: string
  slug?: string
  testDate?: string
  productScoreGauge?: ProductScoreGaugeProps
  keyTestResults?: { label: string; tooltip: string }[]
  annualRunningCost?: AnnualRunningCostType
  businessKey?: string
  variants?: ProductColour[][] | null
  offers: ProductOffer[]
  topRated: boolean
}

type SharedProps = {
  dynamicTableId?: string
  logInCTA?: { url: string; label: string; copy: string }
  signUpCTA?: { url: string; label: string; smallPrint?: string }
  trackingPrefix: 'spp' | 'dynamic-table'
}

export type ArticleProductCardRestrictedProps = {
  product?: boolean
  score?: boolean
  revealProductABTest?: boolean
}

export type CardedDynamicTableProps = SharedProps & {
  title?: string
  products?: CardedDynamicTableProduct[]
  restricted?: ArticleProductCardRestrictedProps
  showProductFeature?: boolean
}

export type TableItemProps = SharedProps &
  CardedDynamicTableProduct & {
    index: number
    loginStatus?: any
    restricted?: ArticleProductCardRestrictedProps
    showProductFeature?: boolean
  }

export const formatSources = (image: Image) => {
  const jpg = {
    srcset: `${image.jpg}`,
    type: 'image/jpg',
  }
  const webp = image.webp
    ? {
        srcset: `${image.webp}`,
        type: 'image/webp',
      }
    : null
  return compact([webp, jpg])
}

export const getSources = (images: Image[]) => {
  const frontImage = images.find((image) => image.view === 'front')

  if (frontImage) {
    return formatSources(frontImage)
  }

  return formatSources(images[0])
}

const selectProductImage = (images: Image[]) => {
  const frontImage = images.find((image) => image.view === 'front')
  return frontImage ? frontImage?.jpg : images[0].jpg
}

type Image = {
  view: string
  jpg: string
  webp: string | null
}
