import type { FunctionComponent } from 'react'
import React from 'react'
import { ButtonLink, ChevronRightIcon, Typography } from '@which/seatbelt'

import styles from './ProductHubBrandLinks.module.scss'

export const ProductHubBrandLinks: FunctionComponent<ProductHubBrandLinksProps> = ({
  heading,
  description,
  brandLinks,
}) => (
  <section aria-labelledby="product-hub-brand-links" className={styles.productHubBrandLinks}>
    <Typography
      textStyle="title-400"
      tag="h3"
      id="product-hub-brand-links"
      className={styles.heading}
    >
      {heading}
    </Typography>
    <Typography textStyle="standfirst" className={styles.description}>
      {description}
    </Typography>

    <ul className={styles.brandLinksList}>
      {brandLinks.map(({ text, href }) => (
        <li className={styles.brandLink} key={href}>
          <ButtonLink href={href} appearance="secondary" data-which-id="producthub-button">
            <ChevronRightIcon />
            {text}
          </ButtonLink>
        </li>
      ))}
    </ul>
  </section>
)

///////// IMPLEMENTATION /////////

export type ProductHubBrandLinksProps = {
  heading: string
  description: string
  brandLinks: Array<{
    text: string
    href: string
  }>
}
