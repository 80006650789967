/* istanbul ignore file */

import React from 'react'

import type { WCDRouteProps } from '..'
import { Page } from '../components/Page'

export const picksRoutes: WCDRouteProps = [
  {
    live: true,
    exact: true,
    path: ['/:taxonomySlug(which-picks)', '/which-picks/:taxonomySlug(travel|gardening)'],
    component: () => <Page pagePath="picks/PicksPage" />,
  },
]
