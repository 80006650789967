import modelCoverImageJPG from './modal-cover.jpg'
import modelCoverImageWEBP from './modal-cover.webp'

export const modalImageProps = {
  src: modelCoverImageJPG,
  alt: 'Subscribe to Which?',
  sources: [
    {
      srcset: modelCoverImageWEBP,
      type: 'image/webp',
    },
    {
      srcset: modelCoverImageJPG,
      type: 'image/png',
    },
  ],
}
