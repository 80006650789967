export const componentName = [
    'Typography',
    'Gallery',
    'Picture',
    'PictureCaptioned',
    'StandardLink',
    'InPageAnchorLink',
    'CardedDynamicTable',
    'SmartProductPicker',
    'Download',
    'Blockquote',
    'Quote',
    'Fact',
    'Vimeo',
    'Brightcove',
    'YouTube',
    'Table',
    'Twitter',
    'Facebook',
    'Accordion',
    'AccordionItem',
    'InfoBox',
    'Letter',
    'Pym',
    'Mention',
    'ToolCTA',
    'CRCarToolIntro',
    'IframeEmbed',
    'HeroImageWithText',
    'HTMLSnippet',
    'CRScamsEmotionalSupport',
    'CRScamsRealLifeStory',
    'Callout',
    'CRScamsNavigation',
    'CRScamsSignUp',
    'CRScamsChecklist',
    'CsvTableList',
    'Riddle',
    'CoverageMap',
    'Infogram',
    'ArticleTypography',
    'AccordionLegacy',
    'SpeedCheck',
    'CRToolEntryPoint',
    'ToolFramework',
    'SwitchEmbed',
    'CsvTable',
    'NewsletterSignUp',
    'AcastPodcast',
    'LegacyCTA',
    'CurrentStory',
    'LinkPromo',
    'NewsletterSignUpWithImage',
    'SquirrelEmbed',
    'CallUs',
    'ContactUs',
    'TechSupportContactUs',
    'GardeningContactUs',
    'BrandScores',
    'ThirdPartyCTA',
    'TrustedTraders',
    'StaticTool',
    'PIVisualDataCarousel',
    'PIHero',
    'PIHeader',
    'PISearchContent',
    'PIArticleSection',
    'PICategoriesSection',
    'PITopicsSection',
    'PILatestReleasesSection',
    'PILatestStatisticsSection',
    'CookieSettingsButton',
    'TFToolEntryPoint',
    'PICarousel',
    'GamPanel',
    'BroadbandSwitchCTA',
    'GreenNCAPEmbed',
    'ProsConsList',
    'SubscriptionTiers',
    'ABToolEntryPoint',
];
