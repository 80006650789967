import type { FunctionComponent } from 'react'
import React from 'react'
import { ButtonLink, ChevronRightIcon, Picture, Typography } from '@which/seatbelt'
import { dynamicGa4DataLayerPush } from '@which/shared'

import styles from './PILatestStatisticsSection.module.scss'

export const PILatestStatisticsSection: FunctionComponent<Props> = ({
  buttonText,
  filter,
  image,
  latestStatisticsCards,
  sectionHeader,
  sectionText,
}) => {
  const dataTestId = filter?.replace('_', '-').toLowerCase()

  if (!latestStatisticsCards) {
    return null
  }

  const handleDataLayer = () => {
    dynamicGa4DataLayerPush({
      event: 'click_homepage_button',
      item_grandparent_text: sectionHeader,
      item_parent_text: latestStatisticsCards[0]?.heading,
      item_text: buttonText,
      item_index: 1,
    })
  }

  return (
    <section data-testid="ea-latest-statistics" className={styles.statisticsSectionOuterWrapper}>
      <div className={styles.statisticsSectionWrapper}>
        <div className={styles.statisticsSectionHeader}>
          <Typography
            data-testid="ea-latest-statistics-heading"
            tag="h1"
            textStyle="title-600"
            className={styles.statisticsSectionText}
          >
            {sectionHeader}
          </Typography>
          <Typography data-testid="ea-article-text">{sectionText}</Typography>
        </div>
        <div className={styles.statisticsSectionContent}>
          <div className={styles.statisticsSectionPictureWrapper}>
            {image && <Picture alt={image.alt} src={image.src} />}
          </div>
          <div className={styles.statisticsSectionArticleContentWrapper}>
            <Typography
              tag="h3"
              textStyle="title-400"
              className={styles.statisticsSectionArticleHeading}
            >
              {latestStatisticsCards[0]?.heading}
            </Typography>
            <Typography className={styles.statisticsSectionArticleContent}>
              {latestStatisticsCards[0]?.summary}
            </Typography>
            <ButtonLink
              appearance="secondary"
              data-testid={`ea-view-all-${dataTestId}`}
              href={latestStatisticsCards[0]?.url}
              onClick={() => handleDataLayer()}
            >
              <ChevronRightIcon />
              {buttonText}
            </ButtonLink>
          </div>
        </div>
      </div>
    </section>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  buttonText: string
  filter: string
  image: {
    src: string
    alt: string
  }
  latestStatisticsCards?: any
  sectionHeader: string
  sectionText: string
}
