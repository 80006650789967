import type { FunctionComponent } from 'react'
import React from 'react'
import { Grid, GridItem, Picture, Typography } from '@which/seatbelt'

import type { ImageWithSources } from '../../../../generated/frontend'
import styles from './ProductHubIntro.module.scss'

export const ProductHubIntro: FunctionComponent<ProductHubIntroProps> = ({
  heading,
  standFirst,
  image,
  className,
}) => (
  <section className={className}>
    <Grid className={styles.grid}>
      <GridItem columnStart={{ medium: 1, large: 1 }} span={{ medium: 8, large: 7 }}>
        <Typography tag="h1" className={styles.heading} textStyle="title-700" id="hub-intro">
          {heading}
        </Typography>
        <Typography textStyle="standfirst">{standFirst}</Typography>
      </GridItem>
      <GridItem
        span={{ medium: 4, large: 4, xlarge: 4 }}
        columnStart={{ medium: 9, large: 9, xlarge: 9 }}
        className={styles.image}
      >
        <Picture {...image} lazyLoad={false} imageClassName={styles.image} />
      </GridItem>
    </Grid>
  </section>
)

///////// IMPLEMENTATION /////////

export type ProductHubIntroProps = {
  heading: string
  standFirst: string
  image: ImageWithSources
  className?: string
}
