import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Grid, GridItem, Link, Typography } from '@which/seatbelt'
import { dynamicDatalayerPush } from '@which/shared'

import { useRegisterMutation } from '../../../generated/frontend'
import { Error } from '../../../shared'
import { useGetQueryString } from '../../../shared/hooks/useGetQueryString'
import { ThemeProvider } from '../../../shared/providers'
import { getEnvironment } from '../../../shared/utils'
import { ErrorModal } from '../components/ErrorModal'
import { FormFields } from '../components/FormFields'
import { MarketingPreferences } from '../components/MarketingPreferences'
import { PasswordFieldView } from '../components/PasswordField'
import { QuestionAccordion } from '../components/QuestionAccordion'
import { RegistrationFields } from '../fixtures/account-fields'
import { accountPagesDataLayer, findBlaizeSessionAvailable, findWhichDomian } from '../utils'
import style from './RegistrationPage.module.scss'

const RegistrationPage: FunctionComponent = () => {
  const registerReq = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    individual: {
      isPostOptIn: false,
      isEmailOptIn: false,
    },
  }

  const [registerReqObj, setRegisterReqObj] = useState(registerReq)
  const [sessionChecked, setSessionChecked] = useState(false)

  let httpErrorCode = ''
  let showModal = false
  const [registerMutation, { loading, data, error }] = useRegisterMutation({
    variables: {
      registerRequest: { ...registerReqObj },
    },
  })
  const methods = useForm({ mode: 'onBlur' })
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods

  const accordionContent = (
    <>
      <Typography>
        If you have any questions about joining Which? please get in touch by phone or email:
      </Typography>
      <Typography>
        <Link href="tel:+02922670000">02922670000</Link>
        <br />
        Mon - Fri, 8:30am to 6pm
        <br />
        Sat, 9am to 1pm
        <br />
        Standard call rates apply
      </Typography>
      <Link href="mailto:support@which.co.uk">support@which.co.uk</Link>
    </>
  )

  const handleOnSubmit = (formObj) => {
    const req = {
      firstName: formObj.firstName,
      lastName: formObj.lastName,
      email: formObj.email,
      password: formObj.password,
      individual: {
        isPostOptIn: formObj.postConsent === 'Yes' ? true : false,
        isEmailOptIn: formObj.emailConsent === 'Yes' ? true : false,
      },
    }
    setRegisterReqObj(req)
    registerMutation()
  }
  const limioRedirectUrl = useGetQueryString('redirect')

  useEffect(() => {
    if (
      isSubmitting &&
      errors &&
      Object.keys(errors).length !== 0 &&
      Object.getPrototypeOf(errors) === Object.prototype
    ) {
      const fields = Object.keys(errors).join(' | ')
      dynamicDatalayerPush({
        eventCategory: 'Signup',
        eventAction: 'Form Validation Errors',
        eventLabel: fields.toString(),
      })
      const elements = Object.keys(errors)
        .map((name) => document.getElementsByName(name)[0])
        .filter((el) => !!el)
      elements.sort((a, b) => b.scrollHeight - a.scrollHeight)
      elements[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [errors, isSubmitting])

  useEffect(() => {
    if (findBlaizeSessionAvailable('blaize_session')) {
      window.location.href = findWhichDomian(limioRedirectUrl)
    } else {
      setSessionChecked(true)
    }
  }, [limioRedirectUrl])

  if (data && data.register) {
    const { register } = data
    switch (register.__typename) {
      case 'Registration':
        if (register.status === '200' || register.status === '201') {
          const zephrCookies = register.headers['set-cookie']

          zephrCookies.forEach((cookie: string) => {
            if (cookie.indexOf('blaize_') > -1) {
              const domain =
                getEnvironment() === 'prod'
                  ? '; domain=.which.co.uk;'
                  : '; domain=.internal.which.co.uk;'
              document.cookie = cookie + domain
            } else {
              document.cookie = cookie
            }
          })
          window.location.href = findWhichDomian(limioRedirectUrl) || 'https://www.which.co.uk/'
        }
        break
      case 'RegistrationError':
        httpErrorCode = register.status
        showModal = true
        break
    }
  }

  if (error) {
    const { graphQLErrors } = error
    const extensionObj = graphQLErrors[0].extensions
    if (
      extensionObj?.code === '500' ||
      extensionObj?.code === '400' ||
      extensionObj?.code === '504'
    ) {
      httpErrorCode = extensionObj?.code
      showModal = true
    } else {
      return <Error error={error} />
    }
  }

  return sessionChecked ? (
    <ThemeProvider>
      <Grid className={style.registrationPage}>
        <ErrorModal
          httpErrorCode={httpErrorCode}
          emailAddress={registerReqObj?.email}
          showModal={showModal}
        />
        <GridItem span={{ medium: 6, large: 6 }} columnStart={{ medium: 4, large: 4 }}>
          <Typography tag="h1" textStyle="title-650" className={style.registrationPageTitle}>
            Create an account
          </Typography>
          <Typography textStyle="very-small-print" className={style.registrationPageLogin}>
            Already have an account? <Link href="/login">Log in to Which?</Link>
          </Typography>
          <FormProvider {...methods}>
            <form>
              <FormFields fields={RegistrationFields} />
              <PasswordFieldView
                displayPasswordCheckList={true}
                name="password"
                label="Password"
                rulesRequired={true}
                calledFrom="registration"
                errorMessageText="Please enter a valid password"
                showRequiredText={true}
                autoComplete="new-password"
                validation={{
                  required: true,
                  pattern:
                    /^(?=.*[a-zA-Z])(?=.*[0-9!#£$%^&*()_+="\-])(?!.*[@#;?<.,>{/}'\\|\[\]]).{8,32}$/,
                }}
              />
              <MarketingPreferences />
              <Button
                data-testid="sign-up-button"
                enableSpinner={loading}
                onClick={handleSubmit((formObj) => handleOnSubmit(formObj))}
                className={style.registrationPageSubmit}
              >
                Save and continue
              </Button>
            </form>
          </FormProvider>
          <QuestionAccordion
            label="Any questions?"
            content={accordionContent}
            calledFrom="registration"
          />
        </GridItem>
      </Grid>
    </ThemeProvider>
  ) : (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>Create an account - Which?</title>
        <script>
          {accountPagesDataLayer({
            content_type: 'signup',
            vertical: 'signup',
            contentGroup: 'join/begin',
          })}
        </script>
      </Helmet>
    </>
  )
}
export default RegistrationPage
