import { getImageSources } from '../../../shared'
import type { AdditionalContentLinks } from '../components/AdditionalContent/AdditionalContent'
import type { ProductHubHardcodedData } from '../ProductHub'

const ratio = 'ar_2to1'
const renditions = [
  '3600x1800',
  '3300x1650',
  '3000x1500',
  '2700x1350',
  '2400x1200',
  '2100x1050',
  '1800x900',
  '1500x750',
  '1200x600',
  '900x450',
  '700x350',
  '500x250',
  '320x160',
]

const productTypesRatio = 'article_image'
const productTypesRenditions = [
  '2340px',
  '1620px',
  '1560px',
  '1080px',
  '960px',
  '780px',
  '640px',
  '540px',
  '320px',
]

const relatedProductSizes =
  '(min-width: 1440px) 199px (min-width: 1024px) 25vw, (min-width: 768px) 25vw, (min-width: 580px) 50vw'

const additionalContentSizes =
  '(min-width: 1440px) 33vw (min-width: 1024px) 50vw, (min-width: 768px) 50vw, (min-width: 580px) 100vw'

const currentYear: string = new Date().getFullYear().toString()

const additionalContentLinksElectrical: AdditionalContentLinks = [
  {
    subheading: 'If it is still useable:',
    links: [
      {
        href: '/reviews/shopping-sustainably/article/second-hand-shopping-online/how-to-sell-second-hand-online-aY8e54S9ikBH',
        title: 'How to sell second hand online',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/89358f0469c0-gm-455c1c60-ea46-4235-84c6-03ab4ea87f1e-selling-second-hand-4.jpg',
          sources: [
            {
              srcset: getImageSources(
                '89358f0469c0-gm-455c1c60-ea46-4235-84c6-03ab4ea87f1e-selling-second-hand-4',
                ratio,
                renditions
              ),
              sizes: additionalContentSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Smartphone being used to take photo of blue shirt',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/shopping-sustainably/article/second-hand-shopping-online/best-and-worst-places-to-shop-second-hand-online-aq4XN0M2mb4B#best-places-to-sell-second-hand-online',
        title: 'Where to sell second hand online',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/b6b1ac2f4e7b-gm-759ff5d2-13a3-4568-8bd3-d8a8bbd1025c-buying-or-selling-second-hand-1.jpg',
          sources: [
            {
              srcset: getImageSources(
                'b6b1ac2f4e7b-gm-759ff5d2-13a3-4568-8bd3-d8a8bbd1025c-buying-or-selling-second-hand-1',
                ratio,
                renditions
              ),
              sizes: additionalContentSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Packing jeans into cardboard box beside laptop',
          dimensions: { height: '', width: '' },
        },
      },
    ],
  },
  {
    subheading: 'If it is broken:',
    links: [
      {
        href: '/reviews/shopping-sustainably/article/how-to-recycle-electrical-items-aLPNl2P37s4y',
        title: 'How to recycle electrical items',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/b3d6dfb79337-gm-bdf236e3-0e59-4153-a5c1-8fe34c0b83e5-leadrecycling-electronics.jpg',
          sources: [
            {
              srcset: getImageSources(
                'b3d6dfb79337-gm-bdf236e3-0e59-4153-a5c1-8fe34c0b83e5-leadrecycling-electronics',
                ratio,
                renditions
              ),
              sizes: additionalContentSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'People putting old electrical appliances into recycling bin',
          dimensions: { height: '', width: '' },
        },
      },
    ],
  },
]

export const productHubData: ProductHubHardcodedData = {
  televisions: {
    productTypesData: {
      sectionTitle: 'Television types',
      productTypes: [
        {
          title: 'LCD',
          description:
            'LCD and LED TVs use backlights shining on liquid crystals to create the picture. They can be found at all price points, but tend to be cheaper than OLEDs and QLEDs. We’ve tested some with glorious picture quality and perfect sound, but read our expert reviews to avoid a dud.',
          image: {
            renditions: [],
            src: 'https://media.which.gpp.io/prod/images/original/7aba447d606a-lcd-tv.jpeg',
            sources: [
              {
                srcset: getImageSources(
                  '7aba447d606a-lcd-tv',
                  productTypesRatio,
                  productTypesRenditions
                ),
                sizes: additionalContentSizes,
                media: '',
                type: '',
              },
            ],
            alt: '',
            id: '',
            caption: '',
            dimensions: { height: '', width: '' },
          },
          secondaryLink: {
            contentHtmlId: 'lcd-television-link',
            href: '/reviews/televisions?filters.screen_type.display_type=LCD&filter.cat=tvs',
            label: 'LCD TV reviews',
          },
        },
        {
          title: 'OLED',
          description:
            'OLED TVs don’t have a backlight unlike LCD and QLED TVs, instead each colour-producing pixel in the display also creates its own light. They are exclusively high-end, and are known for their contrast, paper-thin screens and high price. so check our reviews to make sure you spend your money wisely.',
          image: {
            renditions: [],
            src: 'https://media.which.gpp.io/prod/images/original/db4dffe5dfe8-oled-tv.jpeg',
            sources: [
              {
                srcset: getImageSources(
                  'db4dffe5dfe8-oled-tv',
                  productTypesRatio,
                  productTypesRenditions
                ),
                sizes: additionalContentSizes,
                media: '',
                type: '',
              },
            ],
            alt: '',
            id: '',
            caption: '',
            dimensions: { height: '', width: '' },
          },
          secondaryLink: {
            contentHtmlId: 'oled-television-link',
            href: '/reviews/televisions?filters.screen_type.display_type=OLED&filter.cat=tvs',
            label: 'OLED TV reviews',
          },
        },
        {
          title: 'QLED',
          description:
            'QLED TVs have a backlight like LCD ones, but their displays use quantum dots as well as liquid crystals. These tiny dots are designed to up the brightness and colour vibrancy. They are mostly mid-range while more expensive Neo QLED TVs have advanced miniLED backlights. Read our QLED reviews to see if quantum dots make a difference to quality.',
          image: {
            renditions: [],
            src: 'https://media.which.gpp.io/prod/images/original/863757a6e59d-qled-tv.jpeg',
            sources: [
              {
                srcset: getImageSources(
                  '863757a6e59d-qled-tv',
                  productTypesRatio,
                  productTypesRenditions
                ),
                sizes: additionalContentSizes,
                media: '',
                type: '',
              },
            ],
            alt: '',
            id: '',
            caption: '',
            dimensions: { height: '', width: '' },
          },
          secondaryLink: {
            contentHtmlId: 'qled-television-link',
            href: '/reviews/televisions?filters.screen_type.display_type=QLED&filter.cat=tvs',
            label: 'QLED TV reviews',
          },
        },
      ],
    },
    breadcrumb: {
      heading: {
        href: '/tech-and-appliances',
        text: 'Tech & appliances',
      },
      links: [
        {
          href: '/l/tv-and-home-entertainment',
          text: 'TV and home entertainment',
        },
      ],
      currentPage: {
        href: '/l/tv-and-home-entertainment/televisions',
        text: 'Televisions',
      },
      baseUrl: 'https://www.which.co.uk',
    },
    intro: {
      heading: 'Televisions',
      standFirst:
        'Our television reviews and advice are created by our experts, helping you make the right choices and get the most out of your television',
      image: {
        renditions: [],
        src: 'https://media.product.which.co.uk/prod/images/original/c09c1d4f3a92-televisionsimage3.jpg',
        sources: [
          {
            srcset: getImageSources('c09c1d4f3a92-televisionsimage3', ratio, renditions),
            sizes: '460px',
            media: '',
            type: '',
          },
        ],
        alt: '',
        id: '',
        caption: 'television',
        dimensions: { height: '', width: '' },
      },
    },
    jumpLinks: {
      title: 'What we offer',
      items: [
        {
          text: 'Television reviews',
          path: '#product-reviews',
        },
        {
          text: 'Television buying advice',
          path: '#television-buying-advice',
        },
        {
          text: 'Television guides',
          path: '#how-to-guides',
        },
        {
          text: 'Other reviews',
          path: '#other-reviews',
        },
        {
          text: 'What to do with an old TV',
          path: '#recycle-and-reuse',
        },
        {
          text: 'Your rights if something breaks',
          path: '#your-rights',
        },
      ],
    },
    advice: {
      heading: 'Television help & advice',
      description: 'Clear advice on what to choose, maintenance and product care',
      expertInfo: {
        author: {
          __typename: 'Author',
          id: 1,
          name: 'Martin Pratt',
          jobTitle: '',
          image: {
            alt: 'Martin Pratt, Which? principal researcher',
            caption: '',
            dimensions: {
              height: '',
              width: '',
            },
            id: '',
            renditions: [],
            src: 'https://media.product.which.co.uk/prod/images/original/gm-73da9570-3902-43fa-b36c-c4189828c835-editor-portraits-martin-pratt.png',
            sources: [
              {
                srcset:
                  'https://media.product.which.co.uk/prod/images/50_50/gm-5fda1e63-5eab-4f75-9fb7-c5245c46242e-editor-portraits-martin-pratt.jpg 50w, https://media.product.which.co.uk/prod/images/100_100/gm-7de7a7d8-76e4-4788-baba-1ea197a0c315-editor-portraits-martin-pratt.jpg 100w, https://media.product.which.co.uk/prod/images/150_150/gm-00e95317-447c-48f8-8045-75f8d3c332e0-editor-portraits-martin-pratt.jpg 150w, https://media.product.which.co.uk/prod/images/200_200/gm-de7cc670-6dc0-4eb4-838c-b4316a7fa05b-editor-portraits-martin-pratt.jpg 200w',
                sizes: '(min-width: 1024px) 100px,(min-width: 768px) 55px, 40px',
                type: '',
                media: '',
              },
            ],
          },
          slug: 'martin-pratt',
        },
        title: 'Martin Pratt, Which? principal researcher',
        body: 'High-end TVs tend to do better in our tests, but size isn’t such an important factor. There are good options at every size, so don’t feel the pressure to buy a big TV.',
      },
      sections: [
        {
          title: 'Television buying advice',
          links: [
            {
              text: 'What size TV should I buy?',
              href: '/reviews/televisions/article/what-size-tv-should-i-buy/what-size-tv-should-i-buy-aXiiB3e0zZcW',
            },
            {
              text: `Best TVs for ${currentYear}`,
              href: '/reviews/televisions/article/how-to-buy-the-best-tv-awTTN2d6a0u5',
            },
            {
              text: 'Should you buy a second-hand TV?',
              href: '/reviews/televisions/article/should-you-buy-a-second-hand-tv-aqsfF8I5wAIw',
            },
            {
              text: 'How we test TVs',
              href: '/reviews/televisions/article/how-we-test-televisions-aiIx08B88iPi',
            },
          ],
        },
        {
          title: 'How to guides',
          links: [
            {
              text: 'How to hear your TV better',
              href: '/reviews/televisions/article/how-to-hear-your-tv-better-aFF1F8u79FNV',
            },
            {
              text: 'How to get the best TV picture',
              href: '/reviews/televisions/article/getting-the-best-tv-picture-adbBi9E1jDjX',
            },
            {
              text: 'How to connect your devices to your TV',
              href: '/reviews/televisions/article/how-to-connect-devices-to-your-tv/how-to-connect-audio-and-video-devices-to-your-tv-axSjW0F7v8g9',
            },
            {
              text: 'How to wall mount your TV',
              href: '/reviews/televisions/article/how-to-wall-mount-your-tv-ayG286D3R0cd',
            },
          ],
        },
        {
          title: 'Confused about the technical terms?',
          links: [
            {
              text: 'TV screen technology explained',
              href: '/reviews/televisions/article/tv-screen-technology-explained/what-are-lcd-and-led-tvs-and-what-s-the-difference-ac27g0D50PeT',
            },
            {
              text: 'TV licence explained',
              href: '/reviews/televisions/article/tv-licence-explained-a4ROt3S92d24',
            },
          ],
        },
      ],
      buttonCTAText: 'All television advice',
      buttonCTAHref: '/reviews/televisions/article/guides',
    },
    relatedProducts: [
      {
        href: '/reviews/sound-bars',
        strapline: '',
        title: 'Soundbars',
        image: {
          renditions: [],
          src: 'https://media.product.which.co.uk/prod/images/original/aa166ac390c7-sound-bars.jpg',
          sources: [
            {
              srcset: getImageSources('aa166ac390c7-sound-bars', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'soundbars',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/tv-streamers',
        strapline: '',
        title: 'TV streamers',
        image: {
          renditions: [],
          src: 'https://media.product.which.co.uk/prod/images/original/677a47b190c8-tv-streamers.jpg',
          sources: [
            {
              srcset: getImageSources('677a47b190c8-tv-streamers', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'TV streamers',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/pvrs-and-set-top-boxes',
        strapline: '',
        title: 'PVRs and set-top boxes',
        image: {
          renditions: [],
          src: 'https://media.product.which.co.uk/prod/images/original/5a29edc0f68e-pvr-and-set-top-boxes.jpg',
          sources: [
            {
              srcset: getImageSources('5a29edc0f68e-pvr-and-set-top-boxes', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'PVRs and set-top boxes',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/blu-ray-dvd-players',
        strapline: '',
        title: 'Blu-ray DVD players',
        image: {
          renditions: [],
          src: 'https://media.product.which.co.uk/prod/images/original/72af683ddc62-blu-ray-and-dvd-players.jpg',
          sources: [
            {
              srcset: getImageSources('72af683ddc62-blu-ray-and-dvd-players', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'blu-ray DVD players',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/indoor-aerials',
        strapline: '',
        title: 'Indoor aerials',
        image: {
          renditions: [],
          src: 'https://media.product.which.co.uk/prod/images/original/43bafc3a7a00-indoor-aerials.jpg',
          sources: [
            {
              srcset: getImageSources('43bafc3a7a00-indoor-aerials', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'indoor aerials',
          dimensions: { height: '', width: '' },
        },
      },
    ],
    additionalContent: {
      id: 'recycle-and-reuse',
      heading: 'What to do with an old television',
      additionalContentLinks: additionalContentLinksElectrical,
    },
    rights: {
      heading: 'Your rights if something breaks',
      content: [
        {
          heading: "If you've bought a new television",
          text: "If you've purchased a new television from a retailer, you are covered by the Consumer Rights Act 2015. This means that a product must be as described, or of satisfactory quality and fit for purpose. If not, you could get your money back.",
          links: [
            {
              text: 'How to get a refund, repair or replacement',
              href: '/consumer-rights/advice/what-do-i-do-if-i-have-a-faulty-product-aTTEK2g0YuEy',
            },
            {
              text: "When can I use a manufacturer's warranty or guarantee?",
              href: '/consumer-rights/advice/when-can-i-use-a-manufacturer-s-warranty-or-guarantee-apyZm8F75DFU',
            },
            {
              text: "What are my rights if there's a safety warning or product recall?",
              href: '/consumer-rights/advice/what-are-my-rights-if-there-s-a-safety-warning-or-product-recall-aRAk41z7CLFz',
            },
          ],
        },
        {
          heading: "If you've bought a television second hand",
          text: 'You may have different rights with second hand items depending on where you bought it.',
          links: [
            {
              text: 'Your rights when buying second hand goods',
              href: '/consumer-rights/advice/what-are-my-rights-when-buying-second-hand-goods-apeuz1f3nYUK',
            },
          ],
        },
      ],
    },
    productReviews: {
      productSlug: 'televisions',
      heading: 'Television reviews',
      description:
        "Our independent reviews test and evaluate a wide range of televisions so you don't waste your money",
      buttonCTAText: 'All television reviews',
      buttonCTAHref: '/reviews/televisions',
      memberExclusiveCard: {
        heading: 'Why join Which?',
        description:
          'Our members get the full picture, with all the Best Buy and Don’t Buy products',
        joinLink: {
          text: 'Unlock reviews',
          href: 'https://join.which.co.uk',
        },
        loginLink: {
          text: 'log in',
          href: '/login',
        },
        disclaimer: 'Full Access first month £5, then £10.99 per month, cancel any time',
      },
    },
    brandLinks: {
      heading: 'Find televisions by brand',
      description: 'We test and review televisions from a wide range of brands',
      brandLinks: [
        {
          text: 'Samsung',
          href: '/reviews/televisions/samsung/brand',
        },
        {
          text: 'LG',
          href: '/reviews/televisions/lg/brand',
        },
        {
          text: 'Sony',
          href: '/reviews/televisions/sony/brand',
        },
        {
          text: 'Panasonic',
          href: '/reviews/televisions/panasonic/brand',
        },
        {
          text: 'Hisense',
          href: '/reviews/televisions/hisense/brand',
        },
        {
          text: 'Philips',
          href: '/reviews/televisions/philips/brand',
        },
        {
          text: 'All television brands',
          href: '/reviews/televisions',
        },
      ],
    },
    description: {
      id: 'televisions-description',
      content: `
        <p>Whether you're looking for a big screen for the living room or a more compact size for the bedroom, we've got you covered in your search for the <a data-which-id='producthub-link' data-section='descriptive text' class='sb-link-primary' href='/reviews/televisions/article/how-to-buy-the-best-tv-awTTN2d6a0u5'><span class='sb-link-animation-wrapper'>best TV</span></a>. Our lab tested reviews will give you the confidence to find your perfect match from <a data-which-id='producthub-link' data-section='descriptive text' class='sb-link-primary' href='/reviews/televisions/article/most-reliable-tv-brands-aHn4T3r7VjsM'><span class='sb-link-animation-wrapper'>top tv brands</span></a> such as <a data-which-id='producthub-link' data-section='descriptive text' class='sb-link-primary' href='/reviews/televisions/lg/brand'><span class='sb-link-animation-wrapper'>LG</span></a>, <a data-which-id='producthub-link' data-section='descriptive text' class='sb-link-primary' href='/reviews/televisions/sony/brand'><span class='sb-link-animation-wrapper'>Sony</span></a>, <a data-which-id='producthub-link' data-section='descriptive text' class='sb-link-primary' href='/reviews/televisions/philips/brand'><span class='sb-link-animation-wrapper'>Phillips</span></a>, and <a data-which-id='producthub-link' data-section='descriptive text' class='sb-link-primary' href='/reviews/televisions/samsung/brand'><span class='sb-link-animation-wrapper'>Samsung</span></a>.</p>
        <p>You may need help knowing <a data-which-id='producthub-link' data-section='descriptive text' class='sb-link-primary' href='/reviews/televisions/article/what-size-tv-should-i-buy/what-size-tv-should-i-buy-aXiiB3e0zZcW'><span class='sb-link-animation-wrapper'>which size TV to buy</span></a>. If you want to create a cinema-like experience in your home, we recommend a <a data-which-id='producthub-link' data-section='descriptive text' class='sb-link-primary' href='/reviews/televisions/article/what-size-tv-should-i-buy/top-5-best-55-inch-tvs-aUV2B2n9y9Cf'><span class='sb-link-animation-wrapper'>55-inch TV</span></a> or <a data-which-id='producthub-link' data-section='descriptive text' class='sb-link-primary' href='/reviews/televisions/article/what-size-tv-should-i-buy/top-5-best-60-and-65-inch-tvs-aGdPB3C2ApXg'><span class='sb-link-animation-wrapper'>65-inch TV</span></a>. But if space is an issue, a <a data-which-id='producthub-link' data-section='descriptive text' class='sb-link-primary' href='/reviews/televisions/article/what-size-tv-should-i-buy/top-3-best-32-inch-tvs-a25QX2a5FQkB'><span class='sb-link-animation-wrapper'>32 inch TV</span></a>is perfect for smaller rooms.</p>
        <p>A <a data-which-id='producthub-link' data-section='descriptive text' class='sb-link-primary' href='/reviews/televisions/article/tv-screen-technology-explained/what-is-4k-tv-aA9be0m2H18T'><span class='sb-link-animation-wrapper'>4K TV</span></a> offers sharp images, vibrant colours, and spectacular clarity. If you're looking for something beyond that, consider a <a data-which-id='producthub-link' data-section='descriptive text' class='sb-link-primary' href='/reviews/televisions/article/tv-screen-technology-explained/what-is-qled-tv-asQHo4o9SgcG'><span class='sb-link-animation-wrapper'>QLED<span></a> or <a data-which-id='producthub-link' data-section='descriptive text' class='sb-link-primary' href='/reviews/televisions/article/tv-screen-technology-explained/what-is-oled-tv-aGjI44A1IVQc'><span class='sb-link-animation-wrapper'>OLED</span></a> TV. QLED TVs use a quantum dot film to deliver crystal-clear quality from every angle, while OLED TVs use self-illuminating pixels to emphasise visuals with stronger contrast. If you want a Sky box built-in, read our expert advice about <a data-which-id='producthub-link' data-section='descriptive text' class='sb-link-primary' href='/reviews/televisions/article/sky-glass-tv-how-does-it-work-and-how-much-does-it-cost-aabuI1o5q8aa'><span class='sb-link-animation-wrapper'>Sky Glass TVs</span></a>.</p>
        <p>We've tested and rated televisions across all price ranges to uncover the top <a data-which-id='producthub-link' data-section='descriptive text' class='sb-link-primary' href='/reviews/televisions/article/best-cheap-tvs-a5tli4d9hH1f'><span class='sb-link-animation-wrapper'>cheap tvs<span></a>. Plus, we've rounded up the <a data-which-id='producthub-link' data-section='descriptive text' class='sb-link-primary' href='/reviews/televisions/article/best-tv-deals-a0jqg8E3Saz6'><span class='sb-link-animation-wrapper'>television deals</span></a> available in the UK sales this month. We've also compared <a data-which-id='producthub-link' data-section='descriptive text' class='sb-link-primary' href='/reviews/televisions/article/tv-vs-projector-which-is-best-aGmku9x9erBD'><span class='sb-link-animation-wrapper'>TV vs projector</span></a> to let you know which of the two is best for your space.</p>
        <p>We've also reviewed the latest <a data-which-id='producthub-link' data-section='descriptive text' class='sb-link-primary' href='/reviews/sound-bars/article/how-to-buy-the-best-sound-bar-aDE6e4U00h2Y'><span class='sb-link-animation-wrapper'>sound bars</span></a> which can be used to enhance the audio quality of your television speakers. Some sound bars come with subwoofers for added bass for a more immersive experience.</p>
        <p>For more information on choosing the right <a data-which-id='producthub-link' data-section='descriptive text' class='sb-link-primary' href='/reviews/televisions/article/what-is-smart-tv-aNeqk6F0RAAn'><span class='sb-link-animation-wrapper'>smart TV</span></a>, check out our <a data-which-id='producthub-link' data-section='descriptive text' class='sb-link-primary' href='/reviews/televisions/article/how-to-buy-the-best-tv-awTTN2d6a0u5'><span class='sb-link-animation-wrapper'>TV buying guide</span></a>, where we provide expert advice and detailed information on screen types, features, and accessories.</p>
      `,
    },
  },
  'fridge-freezers': {
    productTypesData: {
      sectionTitle: 'Fridge freezer types',
      productTypes: [
        {
          title: 'Freestanding',
          description:
            'Freestanding fridge freezers aren’t hidden by cupboard doors so they’re easy to find a place for. They’re usually lower priced and low hassle when it comes to servicing, but they won’t blend into your kitchen as seamlessly as integrated models.',
          image: {
            renditions: [],
            src: 'https://media.which.gpp.io/prod/images/original/4a208dc5b56c-freestanding-fridge-freezer.jpeg',
            sources: [
              {
                srcset: getImageSources(
                  '4a208dc5b56c-freestanding-fridge-freezer',
                  productTypesRatio,
                  productTypesRenditions
                ),
                sizes: additionalContentSizes,
                media: '',
                type: '',
              },
            ],
            alt: '',
            id: '',
            caption: '',
            dimensions: { height: '', width: '' },
          },
          secondaryLink: {
            contentHtmlId: 'freestanding-fridge-freezer-link',
            href: '/reviews/fridge-freezers?filters.type.fridge_freezer_type=Freestanding&filter.cat=ff',
            label: 'Freestanding fridge freezer reviews',
          },
        },
        {
          title: 'Integrated',
          description:
            'Integrated fridge freezers can be hidden behind cupboards so your kitchen doesn’t lose its style. They tend to be more expensive than simple freestanding models, but price is no guarantee of quality so you’ll want to read our expert reviews to find the very best.',
          image: {
            renditions: [],
            src: 'https://media.which.gpp.io/prod/images/original/477480434578-integrated-fridge-freezer.jpeg',
            sources: [
              {
                srcset: getImageSources(
                  '477480434578-integrated-fridge-freezer',
                  productTypesRatio,
                  productTypesRenditions
                ),
                sizes: additionalContentSizes,
                media: '',
                type: '',
              },
            ],
            alt: '',
            id: '',
            caption: '',
            dimensions: { height: '', width: '' },
          },
          secondaryLink: {
            contentHtmlId: 'integrated-fridge-freezer-link',
            href: '/reviews/fridge-freezers?filters.type.fridge_freezer_type=Integrated&filter.cat=ff',
            label: 'Integrated fridge freezer reviews',
          },
        },
        {
          title: 'American',
          description:
            'American-style fridge freezers are sometimes called side-by-side models. There are a few different ways they’re built, but they have double doors and they have more capacity than most other fridge freezers. Read our reviews to get a high-performer that doesn’t land you with big energy bills.',
          image: {
            renditions: [],
            src: 'https://media.which.gpp.io/prod/images/original/088383d31e7c-american-style-fridge-freezer.jpeg',
            sources: [
              {
                srcset: getImageSources(
                  '088383d31e7c-american-style-fridge-freezer',
                  productTypesRatio,
                  productTypesRenditions
                ),
                sizes: additionalContentSizes,
                media: '',
                type: '',
              },
            ],
            alt: '',
            id: '',
            caption: '',
            dimensions: { height: '', width: '' },
          },
          secondaryLink: {
            contentHtmlId: 'american-fridge-freezer-link',
            href: '/reviews/fridge-freezers?filters.type.fridge_freezer_type=American&filter.cat=ff',
            label: 'American-style fridge freezer reviews',
          },
        },
      ],
    },
    breadcrumb: {
      heading: {
        href: '/tech-and-appliances',
        text: 'Tech & appliances',
      },
      links: [
        {
          href: '/l/home-appliances',
          text: 'Home appliances',
        },
      ],
      currentPage: {
        href: '/l/home-appliances/fridge-freezers',
        text: 'Fridge freezers',
      },
      baseUrl: 'https://www.which.co.uk',
    },
    intro: {
      heading: 'Fridge Freezers',
      standFirst:
        'Our fridge freezer reviews and advice are created by our experts, helping you make the right choices and get the most out of your fridge freezer',
      image: {
        renditions: [],
        src: 'https://media.which.gpp.io/prod/images/original/5916a06b483b-fridge-freezers.jpg',
        sources: [
          {
            srcset: getImageSources('5916a06b483b-fridge-freezers', ratio, renditions),
            sizes: '460px',
            media: '',
            type: '',
          },
        ],
        alt: '',
        id: '',
        caption: 'fridge-freezer',
        dimensions: { height: '', width: '' },
      },
    },
    jumpLinks: {
      title: 'What we offer',
      items: [
        {
          text: 'Fridge freezer reviews',
          path: '#product-reviews',
        },
        {
          text: 'Fridge freezer buying advice',
          path: '#fridge-freezer-buying-advice',
        },
        {
          text: 'Fridge freezer guides',
          path: '#how-to-guides',
        },
        {
          text: 'Other reviews',
          path: '#other-reviews',
        },
        {
          text: 'What to do with an old fridge freezer',
          path: '#recycle-and-reuse',
        },
        {
          text: 'Your rights if something breaks',
          path: '#your-rights',
        },
      ],
    },
    advice: {
      heading: 'Fridge freezer help & advice',
      description: 'Expert advice on what to choose, maintenance and product care',
      expertInfo: {
        author: {
          id: 2,
          name: 'Christina Woodger',
          jobTitle: '',
          image: {
            renditions: [],
            __typename: 'ImageWithSources',
            alt: 'Christina Woodger, Which? senior researcher',
            caption: '',
            dimensions: {
              height: '',
              width: '',
            },
            id: '',
            src: 'https://media.product.which.co.uk/prod/images/original/09f16559c555-christina-woodger-gilde-portrait.jpg',
            sources: [
              {
                media: '',
                type: '',
                srcset:
                  'https://media.product.which.co.uk/prod/images/ar_1to1_50x50/09f16559c555-christina-woodger-gilde-portrait.jpg 50w, https://media.product.which.co.uk/prod/images/ar_1to1_100x100/09f16559c555-christina-woodger-gilde-portrait.jpg 100w, https://media.product.which.co.uk/prod/images/ar_1to1_150x150/09f16559c555-christina-woodger-gilde-portrait.jpg 150w, https://media.product.which.co.uk/prod/images/ar_1to1_200x200/09f16559c555-christina-woodger-gilde-portrait.jpg 200w',
                sizes: '(min-width: 1024px) 100px,(min-width: 768px) 55px, 40px',
              },
            ],
          },
          slug: 'christina-woodger',
        },
        title: 'Christina Woodger, Which? senior researcher',
        body: "Look for a fridge freezer that chills and freezes quickly - it'll help your food retain more nutrients and flavour. We've also made our energy tests tougher to reward energy-efficient models.",
      },
      sections: [
        {
          title: 'Fridge freezer buying advice',
          links: [
            {
              text: `Best fridge freezers for ${currentYear}`,
              href: '/reviews/fridge-freezers/article/how-to-buy-the-best-fridge-freezer-a0EpY5V0403e',
            },
            {
              text: `Best American fridge freezers for ${currentYear}`,
              href: '/reviews/fridge-freezers/article/how-to-buy-the-best-american-fridge-freezer-aassO2Y5abE6',
            },
            {
              text: 'Best integrated fridge freezers',
              href: '/reviews/fridge-freezers/article/how-to-buy-the-best-integrated-fridge-freezer-aL4RI1h3idpO',
            },
            {
              text: 'How we test fridge freezers',
              href: '/reviews/fridge-freezers/article/how-we-test-fridge-freezers-akb4p8i1ToWy',
            },
          ],
        },
        {
          title: 'How to guides',
          links: [
            {
              text: 'How to defrost your fridge freezer',
              href: '/reviews/fridge-freezers/article/how-to-defrost-your-fridge-freezer-axDiH1G0Zd4i',
            },
            {
              text: 'How to dispose of or recycle a fridge freezer',
              href: '/reviews/fridge-freezers/article/how-to-dispose-of-or-recycle-a-fridge-freezer-aGK6f9c5z1aD',
            },
            {
              text: '10 ways to keep food fresher for longer',
              href: '/reviews/fridge-freezers/article/10-ways-to-keep-food-fresher-for-longer-a4XS05y0gUVJ',
            },
            {
              text: 'Fridge freezer features explained',
              href: '/reviews/fridge-freezers/article/fridge-freezer-features-explained-apfB45k1HzI6',
            },
          ],
        },
      ],
      buttonCTAText: 'All fridge freezer advice',
      buttonCTAHref: '/reviews/fridge-freezers/article/guides',
    },
    additionalContent: {
      id: 'recycle-and-reuse',
      heading: 'What to do with an old fridge freezer',
      additionalContentLinks: additionalContentLinksElectrical,
    },
    rights: {
      heading: 'Your rights if something breaks',
      content: [
        {
          heading: "If you've bought a new fridge freezer",
          text: "If you've purchased a new fridge freezer from a retailer, you are covered by the Consumer Rights Act 2015. This means that a product must be as described, or of satisfactory quality and fit for purpose. If not, you could get your money back.",
          links: [
            {
              text: 'How to get a refund, repair or replacement',
              href: '/consumer-rights/advice/what-do-i-do-if-i-have-a-faulty-product-aTTEK2g0YuEy',
            },
            {
              text: "When can I use a manufacturer's warranty or guarantee?",
              href: '/consumer-rights/advice/when-can-i-use-a-manufacturer-s-warranty-or-guarantee-apyZm8F75DFU',
            },
            {
              text: "What are my rights if there's a safety warning or product recall?",
              href: '/consumer-rights/advice/what-are-my-rights-if-there-s-a-safety-warning-or-product-recall-aRAk41z7CLFz',
            },
          ],
        },
        {
          heading: "If you've bought a fridge freezer second hand",
          text: 'You may have different rights with second hand items depending on where you bought it.',
          links: [
            {
              text: 'Your rights when buying second hand goods',
              href: '/consumer-rights/advice/what-are-my-rights-when-buying-second-hand-goods-apeuz1f3nYUK',
            },
          ],
        },
      ],
    },
    productReviews: {
      productSlug: 'fridge-freezers',
      heading: 'Fridge freezer reviews',
      description:
        "Our independent reviews test and evaluate a wide range of fridge freezers so you don't waste your money",
      buttonCTAText: 'All fridge freezer reviews',
      buttonCTAHref: '/reviews/fridge-freezers',
      memberExclusiveCard: {
        heading: 'Why join Which?',
        description:
          'Our members get the full picture, with all the Best Buy and Don’t Buy products',
        joinLink: {
          text: 'Unlock reviews',
          href: 'https://join.which.co.uk',
        },
        loginLink: {
          text: 'log in',
          href: '/login',
        },
        disclaimer: 'Full Access first month £5, then £10.99 per month, cancel any time',
      },
    },
    brandLinks: {
      heading: 'Find fridge freezers by brand',
      description: 'We test and review fridge freezers from a wide range of brands',
      brandLinks: [
        {
          text: 'Beko',
          href: '/reviews/fridge-freezers/beko/brand',
        },
        {
          text: 'Bosch',
          href: '/reviews/fridge-freezers/bosch/brand',
        },
        {
          text: 'Fridgemaster',
          href: '/reviews/fridge-freezers/fridgemaster/brand',
        },
        {
          text: 'Hisense',
          href: '/reviews/fridge-freezers/hisense/brand',
        },
        {
          text: 'Hoover',
          href: '/reviews/fridge-freezers/hoover/brand',
        },
        {
          text: 'Samsung',
          href: '/reviews/fridge-freezers/samsung/brand',
        },
        {
          text: 'Haier',
          href: '/reviews/fridge-freezers/haier/brand',
        },
        {
          text: 'LG',
          href: '/reviews/fridge-freezers/lg/brand',
        },
        {
          text: 'All fridge freezer brands',
          href: '/reviews/fridge-freezers',
        },
      ],
    },
    relatedProducts: [
      {
        href: '/reviews/fridges',
        strapline: '',
        title: 'Fridges',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/5f69b959ec09-fridge.jpeg',
          sources: [
            {
              srcset: getImageSources('5f69b959ec09-fridge', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Fridges',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/freezers',
        strapline: '',
        title: 'Freezers',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/073f911e4b32-freezer.jpeg',
          sources: [
            {
              srcset: getImageSources('073f911e4b32-freezer', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Freezers',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/dishwashers',
        strapline: '',
        title: 'Dishwashers',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/001aa40ca038-dishwasherspreview.jpg',
          sources: [
            {
              srcset: getImageSources('001aa40ca038-dishwasherspreview', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Dishwashers',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/built-in-ovens',
        strapline: '',
        title: 'Built-in ovens',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/c956dfae1f16-built-in-oven.jpeg',
          sources: [
            {
              srcset: getImageSources('c956dfae1f16-built-in-oven', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Built-in ovens',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/wine-coolers',
        strapline: '',
        title: 'Wine coolers',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/d04aad1d9d5b-wine-coolers.jpeg',
          sources: [
            {
              srcset: getImageSources('d04aad1d9d5b-wine-coolers', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Wine coolers',
          dimensions: { height: '', width: '' },
        },
      },
    ],
    description: {
      id: 'fridge-freezers-description',
      content: `
        <p>Fridge freezers come with a host of features to make your life easier and preserve your food better. But when it comes to finding the <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/fridge-freezers/article/how-to-buy-the-best-fridge-freezer-a0EpY5V0403e"><span class='sb-link-animation-wrapper'>best fridge freezer</span></a> for your kitchen, first consider the space available and your household needs. At Which?, we test a wide range of appliances, including integrated fridge freezers and American fridge freezers.</p>
        <p>If you have a large household, an <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/fridge-freezers/article/how-to-buy-the-best-american-fridge-freezer-aassO2Y5abE6"><span class='sb-link-animation-wrapper'>American-style fridge freezer</span></a> may be the way to go. These freestanding models offer ample space and come with handy compartments to fit even the largest shop. They also offer nice-to-have features such as water and ice dispensers.</p>
        <p>If you prefer not to have your appliances on show, our expert reviews of <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/fridge-freezers/article/how-to-buy-the-best-integrated-fridge-freezer-aL4RI1h3idpO"><span class='sb-link-animation-wrapper'>integrated fridge freezers</span></a> include models that will keep your kitchen sleek while still providing a safe home for your groceries. Keeping your <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/freezers/article/best-freezers-for-your-garage-arWYa8P3qY5V"><span class='sb-link-animation-wrapper'>freezer in the garage</span></a> is also an option if you're trying to save kitchen space or store more food.</p>
        <p>Buying from a reliable <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/fridge-freezers/article/top-fridge-freezer-brands-anUP31D5GeNb"><span class='sb-link-animation-wrapper'>fridge freezer brand</span></a>, will ensure your model gives you years of good service. Our reviews of brands, including <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/fridge-freezers/beko/brand"><span class='sb-link-animation-wrapper'>Beko</span></a>, <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/fridge-freezers/lg/brand"><span class='sb-link-animation-wrapper'>LG</span></a> and <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/fridge-freezers/samsung/brand"><span class='sb-link-animation-wrapper'>Samsung</span></a>, reveal which develop the fewest faults over time and which owners would recommend.</p>
        <p>On a budget? Our experts regularly seek out <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/fridge-freezers/article/top-five-cheap-fridge-freezers-aFeKB4l4RV8H"><span class='sb-link-animation-wrapper'>cheap fridge freezers</span></a> and <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/fridge-freezers/article/best-fridge-freezer-deals-aofpt7C5S337"><span class='sb-link-animation-wrapper'>fridge freezer deals<span></a> to help you save money. If ongoing running costs are a worry, opt for one of our top <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/fridge-freezers/article/energy-efficient-fridge-freezers-aPJbH2c0sNxC"><span class='sb-link-animation-wrapper'>energy efficient fridge freezers</span></a>.</p>
        <p>Whether you're looking for a model that fits seamlessly into your kitchen, saves space, or offers ample space for your weekly shop, our <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/fridge-freezers/article/how-to-buy-the-best-fridge-freezer-a0EpY5V0403e"><span class='sb-link-animation-wrapper'>fridge freezer buying guide</span></a> can help you make the right choice for your needs.</p>
      `,
    },
  },
  'washing-machines': {
    productTypesData: {
      sectionTitle: 'Washing machine types',
      productTypes: [
        {
          title: 'Large capacity',
          description:
            'Buy a large capacity washing machine to clean large laundry loads efficiently. We categorise 9-10kg as ‘large’ and over 10kg as ‘extra large’ and our reviews reveal the maximum capacity of every machine for cotton, synthetic and quick wash cycles.',
          image: {
            renditions: [],
            src: 'https://media.which.gpp.io/prod/images/original/ca832bd944df-large-capacity-washing-machine-type-filter.jpg',
            sources: [
              {
                srcset: getImageSources(
                  'ca832bd944df-large-capacity-washing-machine-type-filter',
                  productTypesRatio,
                  productTypesRenditions
                ),
                sizes: additionalContentSizes,
                media: '',
                type: '',
              },
            ],
            alt: '',
            id: '',
            caption: '',
            dimensions: { height: '', width: '' },
          },
          secondaryLink: {
            contentHtmlId: 'large-capacity-washing-machine-link',
            href: '/reviews/washing-machines?filters.maximum_capacity.from=9&filter.cat=wm',
            label: 'Large capacity washing machine reviews',
          },
        },
        {
          title: 'Integrated',
          description:
            'Integrated washing machines sit underneath worktops and behind cupboard doors, flush to your units, so they don’t stand out like a sore thumb. There aren’t as many to choose from as freestanding models, but our lab tests reveal big gaps between the best and the worst.',
          image: {
            renditions: [],
            src: 'https://media.which.gpp.io/prod/images/original/94ce37e03cc7-integrated-washing-machine-type-filter.jpg',
            sources: [
              {
                srcset: getImageSources(
                  '94ce37e03cc7-integrated-washing-machine-type-filter',
                  productTypesRatio,
                  productTypesRenditions
                ),
                sizes: additionalContentSizes,
                media: '',
                type: '',
              },
            ],
            alt: '',
            id: '',
            caption: '',
            dimensions: { height: '', width: '' },
          },
          secondaryLink: {
            contentHtmlId: 'integrated-washing-machine-link',
            href: '/reviews/washing-machines?filters.type.type=Integrated&filter.cat=wm',
            label: 'Integrated washing machine reviews',
          },
        },
        {
          title: 'Smart',
          description:
            'Smart washing machines connect to your home wi-fi so you can control them with your phone or tablet using a compatible app. Read our expert reviews to find out what functions each machine comes with — and whether they get the basics right too.',
          image: {
            renditions: [],
            src: 'https://media.which.gpp.io/prod/images/original/c68b3fa27223-smart-washing-macine-type-filter.jpg',
            sources: [
              {
                srcset: getImageSources(
                  'c68b3fa27223-smart-washing-macine-type-filter',
                  productTypesRatio,
                  productTypesRenditions
                ),
                sizes: additionalContentSizes,
                media: '',
                type: '',
              },
            ],
            alt: '',
            id: '',
            caption: '',
            dimensions: { height: '', width: '' },
          },
          secondaryLink: {
            contentHtmlId: 'smart-washing-machine-link',
            href: 'https://www.which.co.uk/reviews/washing-machines?filters.smart.smart_compatible=true&filter.cat=wm',
            label: 'Smart washing machine reviews',
          },
        },
      ],
    },

    breadcrumb: {
      heading: {
        href: '/tech-and-appliances',
        text: 'Tech & appliances',
      },
      links: [
        {
          href: '/l/home-appliances',
          text: 'Home appliances',
        },
      ],
      currentPage: {
        href: '/l/home-appliances/washing-machines',
        text: 'Washing machines',
      },
      baseUrl: 'https://www.which.co.uk',
    },
    intro: {
      heading: 'Washing machines',
      standFirst:
        'Our washing machine reviews and advice are created by our experts, helping you make the right choices and get the most out of your washing machine',
      image: {
        renditions: [],
        src: 'https://media.product.which.co.uk/prod/images/original/156a8cc7a4fe-sv-banner-laundrywashing-machinepreview.jpg',
        sources: [
          {
            srcset: getImageSources(
              '156a8cc7a4fe-sv-banner-laundrywashing-machinepreview',
              ratio,
              renditions
            ),
            sizes: '460px',
            media: '',
            type: '',
          },
        ],
        alt: 'Smart washing machine',
        id: '',
        caption: 'washing-machine',
        dimensions: { height: '', width: '' },
      },
    },
    jumpLinks: {
      title: 'What we offer',
      items: [
        {
          text: 'Washing machine reviews',
          path: '#product-reviews',
        },
        {
          text: 'Washing machine buying advice',
          path: '#washing-machine-buying-advice',
        },
        {
          text: 'Washing machine guides',
          path: '#how-to-guides',
        },
        {
          text: 'Other reviews',
          path: '#other-reviews',
        },
        {
          text: 'What to do with an old washing machine',
          path: '#recycle-and-reuse',
        },
        {
          text: 'Your rights if something breaks',
          path: '#your-rights',
        },
      ],
    },
    advice: {
      heading: 'Washing machine help & advice',
      description: 'Expert advice on what to choose, maintenance and product care',
      expertInfo: {
        author: {
          __typename: 'Author',
          id: 1,
          name: 'Rebecca Jakeman',
          jobTitle: '',
          image: {
            renditions: [],
            alt: 'Becki Jakeman, Which? senior researcher',
            caption: '',
            dimensions: {
              height: '',
              width: '',
            },
            id: '',
            src: 'https://media.product.which.co.uk/prod/images/original/0e5b3bb1749d-rebecca-jakeman-gilde-portrait.jpg',
            sources: [
              {
                srcset:
                  'https://media.product.which.co.uk/prod/images/ar_1to1_50x50/0e5b3bb1749d-rebecca-jakeman-gilde-portrait.jpg 50w, https://media.product.which.co.uk/prod/images/ar_1to1_100x100/0e5b3bb1749d-rebecca-jakeman-gilde-portrait.jpg 100w, https://media.product.which.co.uk/prod/images/ar_1to1_150x150/0e5b3bb1749d-rebecca-jakeman-gilde-portrait.jpg 150w, https://media.product.which.co.uk/prod/images/ar_1to1_200x200/0e5b3bb1749d-rebecca-jakeman-gilde-portrait.jpg 200w',
                sizes: '(min-width: 1024px) 100px,(min-width: 768px) 55px, 40px',
                media: '',
                type: '',
              },
            ],
          },
          slug: 'martin-pratt',
        },
        title: 'Becki Jakeman, Which? senior researcher',
        body: "Don't be tempted by washing machines with sky-high spin speeds. We’ve found that costly extras don't necessarily result in cleaner laundry.",
      },
      sections: [
        {
          title: 'Washing machine buying advice',
          links: [
            {
              text: `Best washing machines ${currentYear}`,
              href: '/reviews/washing-machines/article/which-washing-machine-should-you-buy-aafjl7E6UgGx',
            },
            {
              text: 'Best washing machine deals',
              href: '/reviews/washing-machines/article/best-washing-machine-deals-auLql9g0dCqY',
            },
            {
              text: `Best washing machine brands for ${currentYear}`,
              href: '/reviews/washing-machines/article/best-washing-machine-brands-a2Z6T7H782pB',
            },
            {
              text: 'How we test washing machines',
              href: '/reviews/washing-machines/article/how-we-test-washing-machines-aQOn95P2K1rC',
            },
          ],
        },
        {
          title: 'How to guides',
          links: [
            {
              text: '5 laundry habits to stop immediately',
              href: '/reviews/washing-machines/article/five-laundry-habits-to-stop-immediately-ah6Ar8g4SIKz',
            },
            {
              text: 'Washing machine temperature guide',
              href: '/reviews/washing-machines/article/washing-machine-temperature-guide-aLiyf2p96y4d',
            },
            {
              text: 'How to clean a smelly washing machine',
              href: '/reviews/washing-machines/article/how-to-clean-a-smelly-washing-machine-aHXyh7Y8N8u4',
            },
            {
              text: 'Washing symbols explained',
              href: '/reviews/washing-machines/article/washing-symbols-explained-aXkjx1J2v8nK',
            },
          ],
        },
      ],
      buttonCTAText: 'All washing machine advice',
      buttonCTAHref: '/reviews/washing-machines/article/guides',
    },
    additionalContent: {
      id: 'recycle-and-reuse',
      heading: 'What to do with an old washing machine',
      additionalContentLinks: additionalContentLinksElectrical,
    },
    rights: {
      heading: 'Your rights if something breaks',
      content: [
        {
          heading: "If you've bought a new washing machine",
          text: 'If you’ve purchase a new washing machine from a retailer, you are covered by the Consumer Rights Act 2015. This means that a product must be as described, or of satisfactory quality and fit for purpose. If not, you could get your money back.',
          links: [
            {
              text: 'How to get a refund, repair or replacement',
              href: '/consumer-rights/advice/what-do-i-do-if-i-have-a-faulty-product-aTTEK2g0YuEy',
            },
            {
              text: "When can I use a manufacturer's warranty or guarantee?",
              href: '/consumer-rights/advice/when-can-i-use-a-manufacturer-s-warranty-or-guarantee-apyZm8F75DFU',
            },
            {
              text: "What are my rights if there's a safety warning or product recall?",
              href: '/consumer-rights/advice/what-are-my-rights-if-there-s-a-safety-warning-or-product-recall-aRAk41z7CLFz',
            },
          ],
        },
        {
          heading: "If you've bought a washing machine second hand",
          text: 'You may have different rights with second hand items depending on where you bought it.',
          links: [
            {
              text: 'Your rights when buying second hand goods',
              href: '/consumer-rights/advice/what-are-my-rights-when-buying-second-hand-goods-apeuz1f3nYUK',
            },
          ],
        },
      ],
    },
    productReviews: {
      productSlug: 'washing-machines',
      heading: 'Washing machine reviews',
      description:
        "Our independent reviews test and evaluate a wide range of washing machines so you don't waste your money",
      buttonCTAText: 'All washing machine reviews',
      buttonCTAHref: '/reviews/washing-machines',
      memberExclusiveCard: {
        heading: 'Why join Which?',
        description:
          'Our members get the full picture, with all the Best Buy and Don’t Buy products',
        joinLink: {
          text: 'Unlock reviews',
          href: 'https://join.which.co.uk',
        },
        loginLink: {
          text: 'log in',
          href: '/login',
        },
        disclaimer: 'Full Access first month £5, then £10.99 per month, cancel any time',
      },
    },
    brandLinks: {
      heading: 'Find washing machines by brand',
      description: 'We test and review washing machines from a wide range of brands',
      brandLinks: [
        {
          text: 'Bosch',
          href: '/reviews/washing-machines/bosch/brand',
        },
        {
          text: 'Beko',
          href: '/reviews/washing-machines/beko/brand',
        },
        {
          text: 'Hotpoint',
          href: '/reviews/washing-machines/hotpoint/brand',
        },
        {
          text: 'AEG',
          href: '/reviews/washing-machines/aeg/brand',
        },
        {
          text: 'Hoover',
          href: '/reviews/washing-machines/hoover/brand',
        },
        {
          text: 'Samsung',
          href: '/reviews/washing-machines/samsung/brand',
        },
        {
          text: 'LG',
          href: '/reviews/washing-machines/lg/brand',
        },
        {
          text: 'Miele',
          href: '/reviews/washing-machines/miele/brand',
        },
        {
          text: 'All washing machine brands',
          href: '/reviews/washing-machines',
        },
      ],
    },
    relatedProducts: [
      {
        href: '/reviews/tumble-dryers',
        strapline: '',
        title: 'Tumble dryers',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/1c2038def511-tumbledryer.jpeg',
          sources: [
            {
              srcset: getImageSources('1c2038def511-tumbledryer', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Tumble dryers',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/washer-dryers',
        strapline: '',
        title: 'Washer dryers',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/3530db2aed04-sv-banner-laundrywashing-dryerpreview.jpg',
          sources: [
            {
              srcset: getImageSources(
                '3530db2aed04-sv-banner-laundrywashing-dryerpreview',
                ratio,
                renditions
              ),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Washer dryers',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/steam-irons',
        strapline: '',
        title: 'Steam irons',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/cb6dd0d5281a-sv-banner-laundryironpreview.jpg',
          sources: [
            {
              srcset: getImageSources(
                'cb6dd0d5281a-sv-banner-laundryironpreview',
                ratio,
                renditions
              ),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Steam irons',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/clothes-airers/article/best-heated-clothes-airers-a9kG61C6xNs1',
        strapline: '',
        title: 'Clothes airers',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/b22fba9ba080-clothes-airers.jpeg',
          sources: [
            {
              srcset: getImageSources('b22fba9ba080-clothes-airers', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Clothes airers',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/clothes-steamers/article/best-clothes-steamers-a3BMQ1n0HiNq',
        strapline: '',
        title: 'Clothes steamers',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/2db840325ba2-sv-banner-laundrysteamerpreview.jpg',
          sources: [
            {
              srcset: getImageSources(
                '2db840325ba2-sv-banner-laundrysteamerpreview',
                ratio,
                renditions
              ),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Clothes steamers',
          dimensions: { height: '', width: '' },
        },
      },
    ],
    description: {
      id: 'washing-machines-description',
      content: `
        <p>Our reviews of the <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/washing-machines/article/which-washing-machine-should-you-buy-aafjl7E6UgGx"><span class='sb-link-animation-wrapper'>best washing machines</span></a> will help you identify the best-value models that pair great cleaning with reliability and convenient features.</p>
        <p>You'll need to consider the drum size, washing modes and whether you should invest in a <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/washing-machines/article/smart-washing-machines-explained-atSzX5S9PxPE"><span class='sb-link-animation-wrapper'>smart washing machine</span></a>. In our independent lab tests, we cover all kinds, including <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/washing-machines/article/how-to-buy-the-best-integrated-washing-machine-aKLaj1q36EUK"><span class='sb-link-animation-wrapper'>integrated washing machines</span></a> and the most <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/washing-machines/article/energy-efficient-washing-machines-aDUNk3I9sAL4"><span class='sb-link-animation-wrapper'>energy-efficient washing machines</span></a> on the market today.</p>
        <p>We've also rounded up our pick of <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/washing-machines/article/top-five-best-cheap-washing-machines-a00aT7T8Y9Hi"><span class='sb-link-animation-wrapper'>cheap washing machines</span></a> and the latest <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/washing-machines/article/best-washing-machine-deals-auLql9g0dCqY"><span class='sb-link-animation-wrapper'>washing machines deals</span></a> in the sales right now.</p>
        <p>Our experts have surveyed thousands of washing-machine owners to create guides to all the leading <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/washing-machines/article/best-washing-machine-brands-a2Z6T7H782pB"><span class='sb-link-animation-wrapper'>washing machine brands</span></a>. Our reviews are based on rigorous lab tests of models from popular brands including <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/washing-machines/bosch/brand"><span class='sb-link-animation-wrapper'>Bosch</span></a>, <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/washing-machines/miele/brand"><span class='sb-link-animation-wrapper'>Miele</span></a>, <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/washing-machines/beko/brand"><span class='sb-link-animation-wrapper'>Beko</span></a> and <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/washing-machines/hotpoint/brand"><span class='sb-link-animation-wrapper'>Hotpoint</span></a>.</p>
        <p>Head to our <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/washing-machines/article/which-washing-machine-should-you-buy-aafjl7E6UgGx"><span class='sb-link-animation-wrapper'>washing machine buying guide</span></a> for our expert advice on which model is right for you.</p>
      `,
    },
  },
  laptops: {
    productTypesData: {
      sectionTitle: 'Laptop types',
      productTypes: [
        {
          title: 'Windows laptops',
          description:
            'Windows laptops are the most common type of laptop and come in a huge variety of designs and specifications to suit practically any budget. Whether it’s an ultra-cheap model for homework, or a high-end workstation for photo and video editing, Windows laptops have you covered.',
          image: {
            renditions: [],
            src: 'https://media.which.gpp.io/prod/images/original/f17118b2704f-windows-laptop.jpeg',
            sources: [
              {
                srcset: getImageSources(
                  'f17118b2704f-windows-laptop',
                  productTypesRatio,
                  productTypesRenditions
                ),
                sizes: additionalContentSizes,
                media: '',
                type: '',
              },
            ],
            alt: '',
            id: '',
            caption: '',
            dimensions: { height: '', width: '' },
          },
          secondaryLink: {
            contentHtmlId: 'windows-laptop-link',
            href: '/reviews/laptops?filters.laptop_type.type=Windows&filter.cat=lap',
            label: 'Windows laptop reviews',
          },
        },
        {
          title: 'MacBooks',
          description:
            'Available exclusively from Apple, MacBooks run on MacOS. Expect all-metal designs, top-shelf tech specs and pin-sharp screens. MacBooks tend to be the most expensive type of laptop you can buy, although the very cheapest MacBooks do cost less than some Windows models.',
          image: {
            renditions: [],
            src: 'https://media.which.gpp.io/prod/images/original/bf7571c55cec-macbook.jpeg',
            sources: [
              {
                srcset: getImageSources(
                  'bf7571c55cec-macbook',
                  productTypesRatio,
                  productTypesRenditions
                ),
                sizes: additionalContentSizes,
                media: '',
                type: '',
              },
            ],
            alt: '',
            id: '',
            caption: '',
            dimensions: { height: '', width: '' },
          },
          secondaryLink: {
            contentHtmlId: 'macbook-link',
            href: '/reviews/laptops?filters.laptop_type.type=MacBook&filter.cat=lap',
            label: 'MacBook reviews',
          },
        },
        {
          title: 'Chromebooks',
          description:
            'These are some of the cheapest laptops around. They run on ChromeOS, which works brilliantly with web-based apps as well as Android apps from the Google Play store. There are some fancier models available, but the main selling point tends to be price.',
          image: {
            renditions: [],
            src: 'https://media.which.gpp.io/prod/images/original/e643229ba65d-chromebook.jpeg',
            sources: [
              {
                srcset: getImageSources(
                  'e643229ba65d-chromebook',
                  productTypesRatio,
                  productTypesRenditions
                ),
                sizes: additionalContentSizes,
                media: '',
                type: '',
              },
            ],
            alt: '',
            id: '',
            caption: '',
            dimensions: { height: '', width: '' },
          },
          secondaryLink: {
            contentHtmlId: 'chromebook-link',
            href: '/reviews/laptops?filters.laptop_type.type=Chromebook&filter.cat=lap',
            label: 'Chromebook reviews',
          },
        },
      ],
    },
    breadcrumb: {
      heading: {
        href: '/tech-and-appliances',
        text: 'Tech & appliances',
      },
      links: [
        {
          href: '/l/computing',
          text: 'Computing',
        },
      ],
      currentPage: {
        href: '/l/computing/laptops',
        text: 'Laptops',
      },
      baseUrl: 'https://www.which.co.uk',
    },
    intro: {
      heading: 'Laptops',
      standFirst:
        'Our laptop reviews and advice are created by our independent experts, helping you make the right choices and get the most out of your laptop',
      image: {
        renditions: [],
        src: 'https://media.which.gpp.io/prod/images/original/1976da73dfc3-laptops.jpg',
        sources: [
          {
            srcset: getImageSources('1976da73dfc3-laptops', ratio, renditions),
            sizes: '460px',
            media: '',
            type: '',
          },
        ],
        alt: '',
        id: '',
        caption: 'laptop',
        dimensions: { height: '', width: '' },
      },
    },
    jumpLinks: {
      title: 'What we offer',
      items: [
        {
          text: 'Laptop reviews',
          path: '#product-reviews',
        },
        {
          text: 'Laptop buying advice',
          path: '#laptop-buying-advice',
        },
        {
          text: 'Laptop guides',
          path: '#how-to-guides-and-technical-knowledge',
        },
        {
          text: 'Other reviews',
          path: '#other-reviews',
        },
        {
          text: 'What to do with an old laptop',
          path: '#recycle-and-reuse',
        },
        {
          text: 'Your rights if something breaks',
          path: '#your-rights',
        },
      ],
    },
    advice: {
      heading: 'Laptop help & advice',
      description: 'Expert advice on what to choose, maintenance and product care',
      expertInfo: {
        author: {
          __typename: 'Author',
          id: 6,
          name: 'Michael Passingham',
          jobTitle: '',
          image: {
            __typename: 'ImageWithSources',
            alt: 'Michael Passingham, Which? senior researcher',
            caption: '',
            dimensions: {
              height: '',
              width: '',
            },
            id: '',
            renditions: [],
            src: 'https://media.product.which.co.uk/prod/images/original/gm-d2c097c3-e904-4f56-bb17-ee5d2fe930ee-editor-portraits-michael-passingham.png',
            sources: [
              {
                __typename: 'ImageSources',
                srcset:
                  'https://media.product.which.co.uk/prod/images/50_50/gm-ac81f95c-517e-46f7-bcc4-fa4e510a9c52-editor-portraits-michael-passingham.jpg 50w, https://media.product.which.co.uk/prod/images/100_100/gm-9869bc34-f1b4-4664-b7b1-d66cfe0fe0fc-editor-portraits-michael-passingham.jpg 100w, https://media.product.which.co.uk/prod/images/150_150/gm-6fb21daf-cf69-4812-ac68-ab323118f87f-editor-portraits-michael-passingham.jpg 150w, https://media.product.which.co.uk/prod/images/200_200/gm-95c42c45-e55e-47d1-b0af-646f26cab2b8-editor-portraits-michael-passingham.jpg 200w',
                sizes: '(min-width: 1024px) 100px,(min-width: 768px) 55px, 40px',
                media: '',
                type: '',
              },
            ],
          },
          slug: 'michael-passingham',
        },
        title: 'Michael Passingham, Which? senior researcher',
        body: "Laptop speed is important, but it's the everyday annoyances such as a sluggish touchpad or drab screen that will annoy over time.",
      },
      sections: [
        {
          title: 'Laptop buying advice',
          links: [
            {
              text: `Best laptops for ${currentYear} and buying guide`,
              href: '/reviews/laptops/article/how-to-buy-the-best-laptop-aNXXi9d9wmfn',
            },
            {
              text: 'Best cheap laptops under £200, £300 and £500',
              href: '/reviews/laptops/article/best-cheap-laptops-aLsgM2F0QHcg',
            },
            {
              text: `Best laptop deals ${currentYear}`,
              href: '/reviews/laptops/article/best-laptop-deals-a0gLg0i9nmhc',
            },
            {
              text: 'How we test laptops',
              href: '/reviews/laptops/article/how-we-test-laptops-arGXp0K3GO9v',
            },
          ],
        },
        {
          title: 'Laptops by type',
          links: [
            {
              text: `Best MacBooks for ${currentYear}`,
              href: '/reviews/laptops/article/top-laptops-by-type/best-macbooks-abjug7l18D2R',
            },
            {
              text: `Best Chromebooks for ${currentYear}`,
              href: '/reviews/laptops/article/top-laptops-by-type/best-chromebooks-a02kZ9W0KQF4',
            },
            {
              text: 'Best Windows 10 and 11 laptops',
              href: '/reviews/laptops/article/top-laptops-by-type/best-windows-10-laptops-ajKon3C9jxLz',
            },
          ],
        },
        {
          title: 'How to guides and technical knowledge',
          links: [
            {
              text: 'How to speed up a laptop or computer',
              href: '/reviews/laptops/article/how-to-speed-up-a-slow-computer-aKcRy8S1oufr',
            },
            {
              text: 'How to free up disk space',
              href: '/reviews/laptops/article/how-to-free-up-disk-space-amiae8H5KbVs',
            },
            {
              text: 'SSD vs HDD: which is better?',
              href: '/reviews/laptops/article/ssd-vs-hdd-aKidd6x0hlsd',
            },
          ],
        },
      ],
      buttonCTAText: 'All laptop advice',
      buttonCTAHref: '/reviews/laptops/article/guides',
    },
    additionalContent: {
      id: 'recycle-and-reuse',
      heading: 'What to do with an old laptop',
      additionalContentLinks: additionalContentLinksElectrical,
    },
    rights: {
      heading: 'Your rights if something breaks',
      content: [
        {
          heading: "If you've bought a new laptop",
          text: "If you've purchased a new laptop from a retailer, you are covered by the Consumer Rights Act 2015. This means that a product must be as described, or of satisfactory quality and fit for purpose. If not, you could get your money back.",
          links: [
            {
              text: 'How to get a refund, repair or replacement',
              href: '/consumer-rights/advice/what-do-i-do-if-i-have-a-faulty-product-aTTEK2g0YuEy',
            },
            {
              text: "When can I use a manufacturer's warranty or guarantee?",
              href: '/consumer-rights/advice/when-can-i-use-a-manufacturer-s-warranty-or-guarantee-apyZm8F75DFU',
            },
            {
              text: "What are my rights if there's a safety warning or product recall?",
              href: '/consumer-rights/advice/what-are-my-rights-if-there-s-a-safety-warning-or-product-recall-aRAk41z7CLFz',
            },
          ],
        },
        {
          heading: "If you've bought a laptop second hand",
          text: 'You may have different rights with second hand items depending on where you bought it.',
          links: [
            {
              text: 'Your rights when buying second hand goods',
              href: '/consumer-rights/advice/what-are-my-rights-when-buying-second-hand-goods-apeuz1f3nYUK',
            },
          ],
        },
      ],
    },
    productReviews: {
      productSlug: 'laptops',
      heading: 'Laptop reviews',
      description:
        "Our independent reviews test and evaluate a wide range of laptops so you don't waste your money",
      buttonCTAText: 'All laptop reviews',
      buttonCTAHref: '/reviews/laptops',
      memberExclusiveCard: {
        heading: 'Why join Which?',
        description:
          'Our members get the full picture, with all the Best Buy and Don’t Buy products',
        joinLink: {
          text: 'Unlock reviews',
          href: 'https://join.which.co.uk',
        },
        loginLink: {
          text: 'log in',
          href: '/login',
        },
        disclaimer: 'Full Access first month £5, then £10.99 per month, cancel any time',
      },
    },
    brandLinks: {
      heading: 'Find laptops by brand',
      description: 'We test and review laptops from a wide range of brands',
      brandLinks: [
        {
          text: 'Apple',
          href: '/reviews/laptops/apple/brand',
        },
        {
          text: 'Lenovo',
          href: '/reviews/laptops/lenovo/brand',
        },
        {
          text: 'Asus',
          href: '/reviews/laptops/asus/brand',
        },
        {
          text: 'HP',
          href: '/reviews/laptops/hp/brand',
        },
        {
          text: 'Acer',
          href: '/reviews/laptops/acer/brand',
        },
        {
          text: 'Dell',
          href: '/reviews/laptops/dell/brand',
        },
        {
          text: 'Samsung',
          href: '/reviews/laptops/samsung/brand',
        },
        {
          text: 'LG',
          href: '/reviews/laptops/lg/brand',
        },
        {
          text: 'All laptop brands',
          href: '/reviews/laptops',
        },
      ],
    },
    relatedProducts: [
      {
        href: '/reviews/tablets',
        strapline: '',
        title: 'Tablets',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/4167a0dd2096-tablets.jpg',
          sources: [
            {
              srcset: getImageSources('4167a0dd2096-tablets', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Tablets',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/printers-and-ink',
        strapline: '',
        title: 'Printers',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/4e3af4631d87-printers.jpg',
          sources: [
            {
              srcset: getImageSources('4e3af4631d87-printers', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Printers',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/computer-monitors',
        strapline: '',
        title: 'Computer monitors',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/56b241203a8f-computer-monitor.jpg',
          sources: [
            {
              srcset: getImageSources('56b241203a8f-computer-monitor', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Computer monitors',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/broadband',
        strapline: '',
        title: 'Broadband providers',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/52c62ce8aae3-broadband.jpeg',
          sources: [
            {
              srcset: getImageSources('52c62ce8aae3-broadband', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Broadband providers',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/antivirus-software-packages/article/guides',
        strapline: '',
        title: 'Antivirus software packages',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/6fc2578d8d1b-antivirus.jpg',
          sources: [
            {
              srcset: getImageSources('6fc2578d8d1b-antivirus', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Antivirus software packages',
          dimensions: { height: '', width: '' },
        },
      },
    ],
    description: {
      id: 'laptops-description',
      content: `
        <p>When searching for the <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/laptops/article/how-to-buy-the-best-laptop-aNXXi9d9wmfn"><span class='sb-link-animation-wrapper'>best laptop</span></a>, it's worth choosing from the <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/laptops/article/most-reliable-laptop-brands-akQtM7w0zvuR"><span class='sb-link-animation-wrapper'>most reliable laptop brands</span></a> so you can trust your purchase will perform for years to come. We have tested and rated laptops from popular brands such as <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/laptops/apple/brand"><span class='sb-link-animation-wrapper'>Apple</span></a>, <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/laptops/acer/brand"><span class='sb-link-animation-wrapper'>Acer</span></a>, <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/laptops/asus/brand"><span class='sb-link-animation-wrapper'>Asus</span></a>, <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/laptops/hp/brand"><span class='sb-link-animation-wrapper'>HP</span></a> and <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/laptops/lenovo/brand"><span class='sb-link-animation-wrapper'>Lenovo</span></a>.</p>
        <p>Whether you're looking for a device that's suitable for work, play, or both, our laptop reviews have it covered.</p>
        <p>We've tested and reviewed laptops to suit all budgets, uncovering affordable options that can still be relied upon to deliver quality performance. Within our reviews we include our recommended top <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/laptops/article/best-cheap-laptops-aLsgM2F0QHcg"><span class='sb-link-animation-wrapper'>cheap laptops</span></a> - these are still reliable, efficient, and can get the job done. To ensure you don't pay more than you need to, we have rounded up our expert pick of the <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/laptops/article/best-laptop-deals-a0gLg0i9nmhc"><span class='sb-link-animation-wrapper'>laptop deals</span></a> in the sales right now.</p>
        <p>If it's a <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/laptops/article/laptops-for-students-aafOE6Y2pcyL"><span class='sb-link-animation-wrapper'>laptop for a student</span></a> that you're after, we've tested all manner of devices that could suit everybody from children at school to university students, including ultra-flexible 2-in-1 devices that can flip from a laptop to a tablet with ease as well as portable powerhouses for advanced tasks.</p>
        <p>If you're after something easy and convenient to travel with, check out our <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/tablets"><span class='sb-link-animation-wrapper'>tablet reviews</span></a>. A tablet is a great option when you're on the go and want something lightweight and portable - if you just want to sit back with a movie or an eBook, for example.</p>
        <p>Confused about which laptop to buy? Our <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/laptops/article/how-to-buy-the-best-laptop-aNXXi9d9wmfn"><span class='sb-link-animation-wrapper'>laptop buying guide</span></a> is here to help you get the right one for your needs.</p>
      `,
    },
  },
  mattresses: {
    /*productTypesData: {
      sectionTitle: 'Mattress types',
      productTypes: [
        {
          title: 'Memory foam',
          description:
            'Memory foam mattresses relieve pressure on joints because they’re made with temperature sensitive
            elastic that moulds to your body shape. Usually pricier than pocket-sprung mattresses and
            on the warmer side, we’ve reviewed ‘pure memory foam’ as well as pocket-sprung mattresses
            with a memory foam layer on top.',
          image: {
            renditions: [],
            src: 'https://media.which.gpp.io/prod/images/original/541803d87847-memory-foam-mattress.jpeg',
            sources: [
              {
                srcset: getImageSources(
                  '541803d87847-memory-foam-mattress',
                  productTypesRatio,
                  productTypesRenditions
                ),
                sizes: additionalContentSizes,
                media: '',
                type: '',
              },
            ],
            alt: '',
            id: '',
            caption: '',
            dimensions: { height: '', width: '' },
          },
          secondaryLink: {
            contentHtmlId: 'memory-foam-mattress-link',
            href: '/reviews/mattresses?filters.mattress_type.type=Memory+foam%2CMemory+foam+and+springs&filter.cat=mf',
            label: 'Memory foam mattress reviews',
          },
        },
        {
          title: 'Pocket sprung',
          description:
            'If memory foam that wraps around your body isn’t your cup of tea then a pocket sprung mattress
            that scored well in our test lab will be your preference. These come at all sorts of costs but our
            expert reviews reveal Best Buys across the price spectrum.',
          image: {
            renditions: [],
            src: 'https://media.which.gpp.io/prod/images/original/c82f21bfc580-pocket-sprung-mattress.jpeg',
            sources: [
              {
                srcset: getImageSources(
                  'c82f21bfc580-pocket-sprung-mattress',
                  productTypesRatio,
                  productTypesRenditions
                ),
                sizes: additionalContentSizes,
                media: '',
                type: '',
              },
            ],
            alt: '',
            id: '',
            caption: '',
            dimensions: { height: '', width: '' },
          },
          secondaryLink: {
            contentHtmlId: 'pocket-sprung-mattress-link',
            href: '/reviews/mattresses?filters.mattress_type.type=Pocket+sprung&filter.cat=mf',
            label: 'Pocket sprung mattress reviews ',
          },
        },
      ],
    },*/
    breadcrumb: {
      heading: {
        href: '/home-and-garden',
        text: 'Home and garden',
      },
      links: [
        {
          href: '/l/bedroom',
          text: 'Bedroom',
        },
      ],
      currentPage: {
        href: '/l/bedroom/mattresses',
        text: 'Mattresses',
      },
      baseUrl: 'https://www.which.co.uk',
    },
    intro: {
      heading: 'Mattresses',
      standFirst:
        'Our mattress reviews and advice are created by our independent experts, helping you make the right choices and get the most out of your mattress',
      image: {
        renditions: [],
        src: 'https://media.which.gpp.io/prod/images/original/3c89407f361c-mattresses.jpeg',
        sources: [
          {
            srcset: getImageSources('3c89407f361c-mattresses', ratio, renditions),
            sizes: '460px',
            media: '',
            type: '',
          },
        ],
        alt: '',
        id: '',
        caption: 'mattress',
        dimensions: { height: '', width: '' },
      },
    },
    jumpLinks: {
      title: 'What we offer',
      items: [
        {
          text: 'Mattress reviews',
          path: '#product-reviews',
        },
        {
          text: 'Mattress buying advice',
          path: '#mattress-buying-advice',
        },
        {
          text: 'Mattress guides',
          path: '#how-to-guides',
        },
        {
          text: 'Other reviews',
          path: '#other-reviews',
        },
        {
          text: 'Your rights if something breaks',
          path: '#your-rights',
        },
      ],
    },
    advice: {
      heading: 'Mattress help & advice',
      description: 'Expert advice on what to choose, maintenance and product care',
      expertInfo: {
        author: {
          __typename: 'Author',
          id: 4,
          name: 'Steph Kipling',
          jobTitle: '',
          image: {
            __typename: 'ImageWithSources',
            alt: 'Dr Steph Kipling, Which? science team manager',
            caption: '',
            dimensions: {
              height: '',
              width: '',
            },
            id: '',
            renditions: [],
            src: 'https://media.which.gpp.io/prod/images/original/ed9b2761e69d-stephanie-kipling-gilde-portrait.jpg',
            sources: [
              {
                __typename: 'ImageSources',
                srcset:
                  'https://media.which.gpp.io/prod/images/ar_1to1_50x50/ed9b2761e69d-stephanie-kipling-gilde-portrait.jpg 50w, https://media.which.gpp.io/prod/images/ar_1to1_100x100/ed9b2761e69d-stephanie-kipling-gilde-portrait.jpg 100w, https://media.which.gpp.io/prod/images/ar_1to1_150x150/ed9b2761e69d-stephanie-kipling-gilde-portrait.jpg 150w, https://media.which.gpp.io/prod/images/ar_1to1_200x200/ed9b2761e69d-stephanie-kipling-gilde-portrait.jpg 200w',
                sizes: '(min-width: 1024px) 100px,(min-width: 768px) 55px, 40px',
                type: '',
                media: '',
              },
            ],
          },
          slug: 'steph-kipling',
        },
        title: 'Dr Steph Kipling, Which? science team manager',
        body: "Try out the mattress in a shop to find out how well it suits you. If you can't, opt for a mattress with a long trial period, but check the T&Cs about returns in case you're not happy.",
      },
      sections: [
        {
          title: 'Mattress buying advice',
          links: [
            {
              text: `Best mattresses for ${currentYear}`,
              href: '/reviews/mattresses/article/how-to-buy-the-best-mattress-atlAf8V9fiZN',
            },
            {
              text: `Best mattress deals ${currentYear}`,
              href: '/reviews/mattresses/article/best-mattress-deals-ar6DL1l7Cb9Z',
            },
            {
              text: 'UK mattress sizes: bed and mattress size guide',
              href: '/reviews/mattresses/article/uk-mattress-sizes-bed-mattress-size-guide-aDx4r9b4T99o',
            },
            {
              text: 'Best mattress brands',
              href: '/reviews/mattresses/article/best-mattress-brands/which-mattress-brands-asOxA9x6qgsU',
            },
            {
              text: 'How we test mattresses',
              href: '/reviews/mattresses/article/how-we-test-mattresses-aKTG81m1hH9f',
            },
          ],
        },
        {
          title: 'How to guides',
          links: [
            {
              text: 'How to clean a mattress',
              href: '/reviews/mattresses/article/how-to-clean-a-mattress-abnpZ8R3Sz2J',
            },
            {
              text: 'How to dispose of a mattress',
              href: '/reviews/mattresses/article/how-to-dispose-of-a-mattress-avTxg0t48NMG',
            },
            {
              text: 'How to return a mattress',
              href: '/reviews/mattresses/article/how-to-return-a-mattress-ak2pA0L2M9PF',
            },
          ],
        },
      ],
      buttonCTAText: 'All mattress advice',
      buttonCTAHref: '/reviews/mattresses/article/guides',
    },
    rights: {
      heading: 'Your rights if something breaks',
      content: [
        {
          heading: "If you've bought a new mattress",
          text: "If you've purchased a new mattress from a retailer, you are covered by the Consumer Rights Act 2015. This means that a product must be as described, or of satisfactory quality and fit for purpose. If not, you could get your money back.",
          links: [
            {
              text: 'How to get a refund, repair or replacement',
              href: '/consumer-rights/advice/what-do-i-do-if-i-have-a-faulty-product-aTTEK2g0YuEy',
            },
            {
              text: "When can I use a manufacturer's warranty or guarantee?",
              href: '/consumer-rights/advice/when-can-i-use-a-manufacturer-s-warranty-or-guarantee-apyZm8F75DFU',
            },
            {
              text: "What are my rights if there's a safety warning or product recall?",
              href: '/consumer-rights/advice/what-are-my-rights-if-there-s-a-safety-warning-or-product-recall-aRAk41z7CLFz',
            },
          ],
        },
        {
          heading: "If you've bought a mattress second hand",
          text: 'You may have different rights with second hand items depending on where you bought it.',
          links: [
            {
              text: 'Your rights when buying second hand goods',
              href: '/consumer-rights/advice/what-are-my-rights-when-buying-second-hand-goods-apeuz1f3nYUK',
            },
          ],
        },
      ],
    },
    productReviews: {
      productSlug: 'mattresses',
      heading: 'Mattress reviews',
      description:
        "Our independent reviews test and evaluate a wide range of mattresses so you don't waste your money",
      buttonCTAText: 'All mattress reviews',
      buttonCTAHref: '/reviews/mattresses',
      memberExclusiveCard: {
        heading: 'Why join Which?',
        description:
          'Our members get the full picture, with all the Best Buy and Don’t Buy products',
        joinLink: {
          text: 'Unlock reviews',
          href: 'https://join.which.co.uk',
        },
        loginLink: {
          text: 'log in',
          href: '/login',
        },
        disclaimer: 'Full Access first month £5, then £10.99 per month, cancel any time',
      },
    },
    brandLinks: {
      heading: 'Find mattresses by brand',
      description: 'We test and review mattresses from a wide range of brands',
      brandLinks: [
        {
          text: 'IKEA',
          href: '/reviews/mattresses/ikea/brand',
        },
        {
          text: 'Silentnight',
          href: '/reviews/mattresses/silentnight/brand',
        },
        {
          text: 'Eve',
          href: '/reviews/mattresses/eve/brand',
        },
        {
          text: 'Dunlopillo',
          href: '/reviews/mattresses/dunlopillo/brand',
        },
        {
          text: 'Sleepeezee',
          href: '/reviews/mattresses/sleepeezee/brand',
        },
        {
          text: 'Next',
          href: '/reviews/mattresses/next/brand',
        },
        {
          text: 'Emma',
          href: '/reviews/mattresses/emma/brand',
        },
        {
          text: 'All mattress brands',
          href: '/reviews/mattresses',
        },
      ],
    },
    relatedProducts: [
      {
        href: '/reviews/mattress-toppers',
        strapline: '',
        title: 'Mattress toppers',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/3ea543d6bf82-mattress-topper.jpeg',
          sources: [
            {
              srcset: getImageSources('3ea543d6bf82-mattress-topper', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Mattress toppers',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/pillows/article/choosing-the-best-type-of-pillow-awJ4c7G4f111',
        strapline: '',
        title: 'Pillows',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/090e9717c717-pillows.jpeg',
          sources: [
            {
              srcset: getImageSources('090e9717c717-pillows', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Pillows',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/duvets/article/choosing-the-best-type-of-duvet-aXUX91B9AImk',
        strapline: '',
        title: 'Duvets',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/49cedf838323-duvet.jpeg',
          sources: [
            {
              srcset: getImageSources('49cedf838323-duvet', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Duvets',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/mattresses/article/best-electric-blankets-and-heated-throws-avrLG0b2886v',
        strapline: '',
        title: 'Electric blankets and heated throws',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/c9ff12971a32-svheatedblanket.jpg',
          sources: [
            {
              srcset: getImageSources('c9ff12971a32-svheatedblanket', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Electric blankets and heated throws',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/adjustable-beds/article/how-to-buy-the-best-adjustable-bed-asjNZ1J3Zs7I',
        strapline: '',
        title: 'Adjustable beds',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/918b98afcab9-adjustable-bed.jpeg',
          sources: [
            {
              srcset: getImageSources('918b98afcab9-adjustable-bed', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Adjustable beds',
          dimensions: { height: '', width: '' },
        },
      },
    ],
    description: {
      id: 'mattresses-description',
      content: `
        <p>Buying the <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/mattresses/article/how-to-buy-the-best-mattress-atlAf8V9fiZN"><span class='sb-link-animation-wrapper'>best mattress</span></a> for your needs is a highly personal decision. At Which?, expert testing helps us provide unbiased reviews which will help you make an informed choice.</p>
        <p>Whether you need good support, temperature regulation, or the comfort of memory foam, we test a range of mattress types. <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/mattresses/article/top-pocket-sprung-mattresses-arCsR6Z9qbYB"><span class='sb-link-animation-wrapper'>Pocket sprung mattresses</span></a> are popular for their support and cool feel. <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/mattresses/article/top-memory-foam-mattresses-a6D2J3D67Pex"><span class='sb-link-animation-wrapper'>Memory foam mattresses</span></a> mould to your body shape and are great for keeping warm on chilly nights.</p>
        <p>If you're happy not to "try before you buy", shopping online for your new mattress can save time. We review <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/mattresses/article/top-bed-in-a-box-mattresses-aWpPZ0p2eVhL"><span class='sb-link-animation-wrapper'>boxed mattresses</span></a> from popular brands including <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/mattresses/emma/brand"><span class='sb-link-animation-wrapper'>Emma</span></a>, <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/mattresses/eve/brand"><span class='sb-link-animation-wrapper'>Eve</span></a> and <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/mattresses/simba/brand"><span class='sb-link-animation-wrapper'>Simba</span></a>, and dig into the fine print of their sleep trials to ensure you get a fair deal.</p>
        <p>A little time spent deciding <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/mattresses/article/best-mattress-brands/which-mattress-brands-asOxA9x6qgsU"><span class='sb-link-animation-wrapper'>which mattress brand to buy</span></a> will help ensure your mattress stays comfortable for as long as possible. We've tested mattresses from the leading brands including <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/mattresses/ikea/brand"><span class='sb-link-animation-wrapper'>Ikea</span></a> and <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/mattresses/silentnight/brand"><span class='sb-link-animation-wrapper'>Silentnight</span></a>. For our mattress shop survey, we ask more than 2,500 UK mattress owners about their shopping experiences to reveal the <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/mattresses/article/best-mattress-stores-and-websites-a6Czv1M84Suw"><span class='sb-link-animation-wrapper'>best mattress stores and websites</span></a>.</p>
        <p>Whether you're on a tight budget or not, getting a good <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/mattresses/article/best-mattress-deals-ar6DL1l7Cb9Z"><span class='sb-link-animation-wrapper'>mattress deal</span></a> is important, too. To ensure you get a decent mattress at a decent price, our experts round-up <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/mattresses/article/best-cheap-mattresses-aZWOw4S5ScLs"><span class='sb-link-animation-wrapper'>cheap mattresses</span></a> that did well in our tests. You can also extend the life of an older mattress by investing in one of our recommended <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/mattress-toppers/article/choosing-the-best-type-of-mattress-topper-awRRS5f5gyKs"><span class='sb-link-animation-wrapper'>mattress toppers</span></a>.</p>
        <p>Our <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/mattresses/article/how-to-buy-the-best-mattress-atlAf8V9fiZN"><span class='sb-link-animation-wrapper'>mattress buying guide</span></a> has plenty of useful advice on mattress types, mattress sizes, and mattress care. Follow our tips and sleep soundly, knowing you've made the right choice.</p>
      `,
    },
  },
  'child-car-seats': {
    breadcrumb: {
      heading: {
        href: '/baby-and-child',
        text: 'Baby and child',
      },
      links: [
        {
          href: '/l/baby-transport',
          text: 'Baby transport',
        },
      ],
      currentPage: {
        href: '/l/baby-transport/child-car-seats',
        text: 'Child car seats',
      },
      baseUrl: 'https://www.which.co.uk',
    },
    intro: {
      heading: 'Child car seats',
      standFirst:
        'Our child car seat reviews and advice are created by our independent experts, helping you make the right choices and get the most out of your car seat',
      image: {
        renditions: [],
        src: 'https://media.which.gpp.io/prod/images/original/98f03f8408db-child-car-seats.jpeg',
        sources: [
          {
            srcset: getImageSources('98f03f8408db-child-car-seats', ratio, renditions),
            sizes: '460px',
            media: '',
            type: '',
          },
        ],
        alt: '',
        id: '',
        caption: 'child car seats',
        dimensions: { height: '', width: '' },
      },
    },
    jumpLinks: {
      title: 'What we offer',
      items: [
        {
          text: 'Child car seat reviews',
          path: '#product-reviews',
        },
        {
          text: 'Child car seat buying advice',
          path: '#child-car-seat-buying-advice',
        },
        {
          text: 'Laws and safety',
          path: '#laws-and-safety',
        },
        {
          text: 'Other reviews',
          path: '#other-reviews',
        },
        {
          text: 'What to do with an old car seat',
          path: '#recycle-and-reuse',
        },
        {
          text: 'Your rights if something breaks',
          path: '#your-rights',
        },
      ],
    },
    advice: {
      heading: 'Child car seat help & advice',
      description: 'Expert advice on what to choose, laws and keeping your child safe',
      legalCopy: `
        <p>You must use a child car seat that meets UK legal requirements when transporting babies and children in your car to keep them safe.</p>
        <p>In the UK, it’s mandatory that children under 135cm/4ft 5in tall or under 12 years old (whichever comes first), must use a car seat. But we recommend that any child under 150cm/4ft 11in should use a car seat for additional protection in a crash.</p>
        <p>Your child car seat must bear a label showing it's approved to UN R129 OR ECE R44/04. It should be fitted according to the manufacturer's instructions and it needs to be the right fit for your child's height or weight.</p>
        <p>Read more in our guide to <a data-which-id="producthub-link" data-section="legal advice" class="sb-link-primary" href="/reviews/child-car-seats/article/child-car-seat-laws-uk-and-abroad/child-car-seat-laws-in-the-uk-ay4Sj1h8HGhU"><span class='sb-link-animation-wrapper'>child car seat laws in the UK</span></a>.</p>
        <p>If you're going abroad, find out the <a data-which-id="producthub-link" data-section="legal advice" class="sb-link-primary" href="/reviews/child-car-seats/article/child-car-seat-laws-uk-and-abroad/child-car-seats-laws-around-the-world-aKQx68K6rsbK"><span class='sb-link-animation-wrapper'>child car seat laws around the world</span></a> you need to know.</p>
      `,
      expertInfo: {
        author: {
          __typename: 'Author',
          id: 1,
          name: 'Hannah Fox',
          jobTitle: '',
          image: {
            alt: 'Hannah Fox, Which? senior researcher',
            caption: '',
            dimensions: {
              height: '',
              width: '',
            },
            id: '',
            renditions: [],
            src: 'https://media.product.which.co.uk/prod/images/original/6495aca9ec2c-hannah-fox.jpg',
            sources: [
              {
                __typename: 'ImageSources',
                srcset:
                  'https://media.product.which.co.uk/prod/images/ar_1to1_50x50/6495aca9ec2c-hannah-fox.jpg 50w, https://media.product.which.co.uk/prod/images/ar_1to1_100x100/6495aca9ec2c-hannah-fox.jpg 100w, https://media.product.which.co.uk/prod/images/ar_1to1_150x150/6495aca9ec2c-hannah-fox.jpg 150w, https://media.product.which.co.uk/prod/images/ar_1to1_200x200/6495aca9ec2c-hannah-fox.jpg 200w',
                sizes: '(min-width: 1024px) 100px,(min-width: 768px) 55px, 40px',
                media: '',
                type: '',
              },
            ],
          },
          slug: 'martin-pratt',
        },
        title: 'Hannah Fox, Which? senior researcher',
        body: "Make sure you have all the information you need to make the buying decision easier, from your child's weight, height and age to the vehicle it will be used in and the most you are prepared to spend.",
      },
      sections: [
        {
          title: 'Child car seat buying advice',
          links: [
            {
              text: `Best baby or child car seats ${currentYear}`,
              href: '/reviews/child-car-seats/article/how-to-buy-the-best-car-seat-aGqe18f0GeQB',
            },
            {
              text: 'Car seat weight groups explained',
              href: '/reviews/child-car-seats/article/car-seat-weight-groups-aKNNS4n1r8f8',
            },
            {
              text: 'I-Size child car seats explained',
              href: '/reviews/child-car-seats/article/i-size-child-car-seats-explained-aTtO35C7FGbr',
            },
            {
              text: 'How we test baby and child car seats',
              href: '/reviews/child-car-seats/article/how-we-test-baby-and-child-car-seats-auHwy4G7CaHA',
            },
          ],
        },
        {
          title: 'Child car seat safety',
          links: [
            {
              text: "Which Don't Buy child car seats",
              href: '/reviews/child-car-seats/article/which-dont-buy-child-car-seats-adDS07f6KrWI',
            },
            {
              text: 'What are the benefits of an extended rear-facing baby car seat?',
              href: '/reviews/child-car-seats/article/best-extended-rear-facing-baby-or-child-car-seats-aFotf0W2b3e8',
            },
            {
              text: 'Car seats for older children: high-back vs backless booster seats',
              href: '/reviews/child-car-seats/article/booster-seats-are-you-breaking-the-law-ai0Sm4c3meO0',
            },
            {
              text: "Baby and child car seat accessories: what's safe?",
              href: '/reviews/child-car-seats/article/child-car-seat-accessories-afYV16y7R8OC',
            },
          ],
        },
      ],
      buttonCTAText: 'All child car seat advice',
      buttonCTAHref: '/reviews/child-car-seats/article/guides',
    },
    rights: {
      heading: 'Your rights if something breaks',
      content: [
        {
          heading: "If you've bought a new child car seat",
          text: "If you've purchased a new child car seat from a retailer, you are covered by the Consumer Rights Act 2015. This means that a product must be as described, or of satisfactory quality and fit for purpose. If not, you could get your money back.",
          links: [
            {
              text: 'How to get a refund, repair or replacement',
              href: '/consumer-rights/advice/what-do-i-do-if-i-have-a-faulty-product-aTTEK2g0YuEy',
            },
            {
              text: "When can I use a manufacturer's warranty or guarantee?",
              href: '/consumer-rights/advice/when-can-i-use-a-manufacturer-s-warranty-or-guarantee-apyZm8F75DFU',
            },
            {
              text: "What are my rights if there's a safety warning or product recall?",
              href: '/consumer-rights/advice/what-are-my-rights-if-there-s-a-safety-warning-or-product-recall-aRAk41z7CLFz',
            },
          ],
        },
        {
          heading: "If you've bought a child car seat second hand",
          text: 'You may have different rights with second hand items depending on where you bought it.',
          links: [
            {
              text: 'Your rights when buying second hand goods',
              href: '/consumer-rights/advice/what-are-my-rights-when-buying-second-hand-goods-apeuz1f3nYUK',
            },
          ],
        },
      ],
    },
    additionalContent: {
      id: 'recycle-and-reuse',
      heading: 'Useful next steps',
      additionalContentLinks: [
        {
          subheading: 'Installation:',
          links: [
            {
              href: '/reviews/child-car-seats/article/how-to-fit-a-car-seat-ajmVG2c1tNqK',
              title: 'How to fit a baby or child car seat',
              image: {
                renditions: [],
                src: 'https://media.which.gpp.io/prod/images/original/gm_medium_list_thumbnail/acb8fc3d27a7-329449aba940-car-seats5.webp',
                sources: [
                  {
                    srcset: getImageSources(
                      'acb8fc3d27a7-329449aba940-car-seats5',
                      ratio,
                      renditions
                    ),
                    sizes: additionalContentSizes,
                    media: '',
                    type: '',
                  },
                ],
                alt: '',
                id: '',
                caption: 'Child in car seat as woman puts on belt',
                dimensions: { height: '', width: '' },
              },
            },
          ],
        },
        {
          subheading: 'Recycling:',
          links: [
            {
              href: '/reviews/child-car-seats/article/what-should-i-do-with-my-old-baby-car-seat-aWIIP7f2jSxf',
              title: 'What should I do with my old baby car seat?',
              image: {
                renditions: [],
                src: 'https://media.which.gpp.io/prod/images/original/gm_medium_list_thumbnail/aa80fc31a6a3-gm-12c7706e-5adf-410f-8d0f-7ee0b867ea5d-lead.jpg',
                sources: [
                  {
                    srcset: getImageSources(
                      'aa80fc31a6a3-gm-12c7706e-5adf-410f-8d0f-7ee0b867ea5d-lead',
                      ratio,
                      renditions
                    ),
                    sizes: additionalContentSizes,
                    media: '',
                    type: '',
                  },
                ],
                alt: '',
                id: '',
                caption: 'Woman strapping baby into car seat',
                dimensions: { height: '', width: '' },
              },
            },
          ],
        },
      ],
    },
    productReviews: {
      productSlug: 'child-car-seats',
      heading: 'Child car seat reviews',
      description:
        "Our independent reviews test and evaluate a wide range of child car seats so you don't waste your money",
      buttonCTAText: 'All child car seat reviews',
      buttonCTAHref: '/reviews/child-car-seats',
      memberExclusiveCard: {
        heading: 'Why join Which?',
        description:
          'Our members get the full picture, with all the Best Buy and Don’t Buy products',
        joinLink: {
          text: 'Unlock reviews',
          href: 'https://join.which.co.uk',
        },
        loginLink: {
          text: 'log in',
          href: '/login',
        },
        disclaimer: 'Full Access first month £5, then £10.99 per month, cancel any time',
      },
    },
    brandLinks: {
      heading: 'Find child car seats by brand',
      description: 'We test and review child car seats from a wide range of brands',
      brandLinks: [
        {
          text: 'Maxi Cosi',
          href: '/reviews/child-car-seats/maxi-cosi/brand',
        },
        {
          text: 'Britax Römer',
          href: '/reviews/child-car-seats/britax-romer/brand',
        },
        {
          text: 'Cybex',
          href: '/reviews/child-car-seats/cybex/brand',
        },
        {
          text: 'Joie',
          href: '/reviews/child-car-seats/joie/brand',
        },
        {
          text: 'Besafe',
          href: '/reviews/child-car-seats/besafe/brand',
        },
        {
          text: 'Jane',
          href: '/reviews/child-car-seats/jane/brand',
        },
        {
          text: 'Nuna',
          href: '/reviews/child-car-seats/nuna/brand',
        },
        {
          text: 'Graco',
          href: '/reviews/child-car-seats/graco/brand',
        },
        {
          text: 'All child car seat brands',
          href: '/reviews/child-car-seats',
        },
      ],
    },
    relatedProducts: [
      {
        href: '/reviews/pushchairs',
        strapline: '',
        title: 'Pushchairs',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/a9a8263479fa-pushchairs.jpeg',
          sources: [
            {
              srcset: getImageSources('a9a8263479fa-pushchairs', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'pushchairs',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/baby-carriers-and-baby-slings',
        strapline: '',
        title: 'Baby carriers and baby slings',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/acb5c5715f13-babycarriers-and-slings.jpeg',
          sources: [
            {
              srcset: getImageSources('acb5c5715f13-babycarriers-and-slings', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Baby carriers and baby slings',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/baby-monitors',
        strapline: '',
        title: 'Baby monitors',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/4dde5219d92b-baby-monitors.jpeg',
          sources: [
            {
              srcset: getImageSources('4dde5219d92b-baby-monitors', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Baby monitors',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/stair-gates',
        strapline: '',
        title: 'Stair gates',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/b5645ab62df6-stair-gate.jpeg',
          sources: [
            {
              srcset: getImageSources('b5645ab62df6-stair-gate', ratio, renditions),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Stair gates',
          dimensions: { height: '', width: '' },
        },
      },
      {
        href: '/reviews/digital-thermometers',
        strapline: '',
        title: 'Digital thermometers',
        image: {
          renditions: [],
          src: 'https://media.which.gpp.io/prod/images/original/d58da858b42e-subverticalbaby-and-child-digital-thermometerspreview.jpg',
          sources: [
            {
              srcset: getImageSources(
                'd58da858b42e-subverticalbaby-and-child-digital-thermometerspreview',
                ratio,
                renditions
              ),
              sizes: relatedProductSizes,
              media: '',
              type: '',
            },
          ],
          alt: '',
          id: '',
          caption: 'Digital thermometers',
          dimensions: { height: '', width: '' },
        },
      },
    ],
    description: {
      id: 'child-car-seats-description',
      content: `
        <p>Your child's safety is always a top priority, which is why our expert reviews of child car seats focus on safety, comfort, and convenience. When looking for the <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/child-car-seats/article/how-to-buy-the-best-car-seat-aGqe18f0GeQB"><span class='sb-link-animation-wrapper'>best child car seat</span></a>, consider how much you should spend, whether you should go for a group seat or <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/child-car-seats/article/i-size-child-car-seats-explained-aTtO35C7FGbr"><span class='sb-link-animation-wrapper'>i-size</span></a> and find out why car seat jargon, such as <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/child-car-seats/article/are-isofix-baby-car-seats-safer-a4b3J2T1GF3a"><span class='sb-link-animation-wrapper'>Isofix</span></a>, should matter to you.</p>
        <p>Whether you're a new parent or you're looking to upgrade your current car seat, choosing the right type for your baby or child can be confusing. Buying a seat in the correct weight or height group is vital for keeping your child safe in the car, so our experts have created a handy guide on '<a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/child-car-seats/article/car-seat-weight-groups-aKNNS4n1r8f8"><span class='sb-link-animation-wrapper'>car seat weight groups explained</span></a>'.</p>
        <p>We understand that buying the right <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/child-car-seats/article/which-baby-or-child-car-seat-brand-afYao7a9A8bD"><span class='sb-link-animation-wrapper'>car seat brand</span></a> is important to a lot of parents too. We've tested products from leading brands such as <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/child-car-seats/maxi-cosi/brand"><span class='sb-link-animation-wrapper'>Maxi Cosi</span></a>, <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/child-car-seats/cybex/brand"><span class='sb-link-animation-wrapper'>Cybex</span></a>, <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/child-car-seats/graco/brand"><span class='sb-link-animation-wrapper'>Graco</span></a> and <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/child-car-seats/joie/brand"><span class='sb-link-animation-wrapper'>Joie</span></a>.</p>
        <p>Understanding the <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/child-car-seats/article/child-car-seat-laws-uk-and-abroad/child-car-seat-laws-in-the-uk-ay4Sj1h8HGhU"><span class='sb-link-animation-wrapper'>child car seat laws</span></a> is also crucial when looking to buy a new one. The use of a car seat is a legal requirement in the UK but the rules can be complicated, so we've pulled together everything you need to know so you don't get pulled over and fined. For example, if you're travelling with an older child you may be looking to use a <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/child-car-seats/article/booster-seats-are-you-breaking-the-law-ai0Sm4c3meO0"><span class='sb-link-animation-wrapper'>booster seat</span></a>, but you could be breaking the law under newer legislation.</p>
        <p>There's also features, car compatibility and fitting to consider. Read our expert <a data-which-id="producthub-link" data-section="descriptive text" class='sb-link-primary' href="/reviews/child-car-seats/article/how-to-buy-the-best-car-seat-aGqe18f0GeQB"><span class='sb-link-animation-wrapper'>baby and child car seat buying advice</span></a> to make sure you're making the right decision for both you and your child. Rest assured that all of our car seats have been expertly tested to ensure maximum safety and comfort for your child.</p>
      `,
    },
  },
}

export const productHubDataLayer = {
  televisions: {
    title: 'Televisions - TVs',
    desc: "TVs at Which? Expert buying tips and independent reviews for the latest televisions so you don't waste your money. Find out about the latest TV screen technology, how to connect your devices to your TV and more.",
    vertical: 'tech-and-appliances',
    sub_vertical: 'tv-and-home-entertainment',
    category: 'televisions',
    category_id: 'tvs',
  },
  'fridge-freezers': {
    title: 'Fridge freezers',
    desc: 'Fridge freezers at Which? Read our independent reviews and expert advice on types, sizes, energy efficiency and running costs.',
    vertical: 'tech-and-appliances',
    sub_vertical: 'home-appliances',
    category: 'fridge-freezers',
    category_id: 'fridge freezers',
  },
  'washing-machines': {
    title: 'Washing machines',
    desc: 'Washing machines at Which? Independent reviews and expert advice on latest deals, buying advice, best brands and your rights if yours breaks.',
    vertical: 'tech-and-appliances',
    sub_vertical: 'home-appliances',
    category: 'washing-machines',
    category_id: 'washing machines',
  },
  laptops: {
    title: 'Laptops',
    desc: 'Laptops at Which? Independent reviews of MacBooks, Chromebooks and Windows laptops. Find the latest deals and technical advice to help you speed up your laptop or computer.',
    vertical: 'tech-and-appliances',
    sub_vertical: 'computing',
    category: 'laptops',
    category_id: 'laptops',
  },
  mattresses: {
    title: 'Mattresses',
    desc: 'Mattresses at Which? Independent reviews and expert buying advice for memory foam mattresses, pocket sprung ones and more. Find out the best brands, top deals, and see our mattress size guide.',
    vertical: 'home-and-garden',
    sub_vertical: 'bedroom',
    category: 'mattresses',
    category_id: 'mattresses',
  },
  'child-car-seats': {
    title: 'Child car seats',
    desc: 'Child car seats at Which? Independent reviews and expert buying advice based on UK laws and safety for baby and child car seats of different sizes and weight groups.',
    vertical: 'baby-and-child',
    sub_vertical: 'baby-transport',
    category: 'child-car-seats',
    category_id: 'child car seats',
  },
}
