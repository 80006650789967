import type { ComponentPropsWithoutRef, FunctionComponent } from 'react'
import React from 'react'
import type { LinkProps } from '@which/seatbelt'
import { ButtonLink, ChevronRightIcon, StandardLink, Typography } from '@which/seatbelt'

import classnames from 'classnames'

import styles from './BreadcrumbMenuContent.module.scss'

export const BreadcrumbMenuContent: FunctionComponent<Props> = ({
  currentUrl,
  className,
  links,
  viewAllLink,
  blockLink,
  ...rest
}) => {
  const mainLinks = viewAllLink ? [...links, viewAllLink] : links

  return (
    <div
      className={classnames(styles.breadcrumbMenuContent, className)}
      data-testid="breadcrumb-menu-content"
      {...rest}
    >
      <div className={styles.breadcrumbMenuContentGrid} data-testid="breadcrumb-menu-content-links">
        {mainLinks.map(({ label, href }, index) => (
          <StandardLink
            href={href}
            key={`link-${index}`}
            linkStyle="secondary"
            className={classnames(
              styles.breadcrumbMenuContentLink,
              currentUrl === href && styles.breadcrumbMenuContentCurrentLink
            )}
            data-testid={`link-${index}`}
          >
            {label}
          </StandardLink>
        ))}
      </div>
      {blockLink && (
        <div className={styles.breadcrumbMenuContentBlockLink}>
          <Typography tag="h4" className={styles.breadcrumbMenuContentBlockLinkHeading}>
            {blockLink.heading}
          </Typography>
          <Typography className={styles.breadcrumbMenuContentBlockLinkText}>
            {blockLink.text}
          </Typography>
          <ButtonLink className={styles.breadcrumbMenuContentBlockLinkButton} href={blockLink.href}>
            <ChevronRightIcon />
            {blockLink.buttonLabel}
          </ButtonLink>
        </div>
      )}
    </div>
  )
}

///////// IMPLEMENTATION /////////

export interface Props extends ComponentPropsWithoutRef<'div'> {
  currentUrl?: string
  className?: string
  links: BreadcrumbMenuLinkProps[]
  viewAllLink?: BreadcrumbMenuLinkProps | null
  blockLink?: {
    heading: string
    text: string
    href: string
    buttonLabel: string
  }
}

export type BreadcrumbMenuLinkProps = LinkProps & { label?: string }
