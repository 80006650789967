export const dataProviderUrl = (preview = false) => {
  const localIpAddress = process.env.LOCAL_IP_ADDRESS
  const originUsingLocalIpRegex = new RegExp(`${localIpAddress}:3(0|2)00`)

  if (typeof window === 'undefined') {
    return (
      (preview ? process.env.DATA_PROVIDER_PREVIEW_GRAPHQL : process.env.DATA_PROVIDER_GRAPHQL) ||
      ''
    )
  } else if (window.location?.origin.includes('localhost')) {
    return preview ? 'http://localhost:3101/graphql' : 'http://localhost:3100/graphql'
  } else if (originUsingLocalIpRegex.test(window.location?.origin)) {
    const localDataProvider = process.env.LOCAL_DATA_PROVIDER_USING_IP_ADDRESS || ''

    return preview ? localDataProvider?.replace('3100', '3101') : localDataProvider
  }

  return `${window.location?.origin}/data-provider${preview ? '-preview' : ''}/graphql`
}
