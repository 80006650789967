import { matchPath } from 'react-router-dom'

import { routes } from '../../routes'
import { isPreProd, isProd } from '.'

const isProduction = isProd()
const isPreProduction = isPreProd()

export const checkRoute = (href: string) => {
  const matchedRoute = routes.find((route) => matchPath(href, route))

  if (!matchedRoute || !matchedRoute.live) {
    return false
  }

  // return true if non-prod and live
  if (!isProduction && !isPreProduction) {
    return true
  }

  // return true if pre-prod, live and not disabled
  const livePreprod = isPreProduction && (matchedRoute.preprod ?? true)

  if (livePreprod) {
    return true
  }

  // return true if prod, live and not disabled
  const liveProd = isProduction && (matchedRoute.prod ?? true)

  if (liveProd) {
    return true
  }

  return false
}
