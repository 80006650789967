import { createContext } from 'react'

export const FullUrlContext = createContext<FullUrlContextType>({ getFullUrl: () => '' })
export const FullUrlProvider = FullUrlContext.Provider

///////// IMPLEMENTATION /////////

type FullUrlContextType = {
  getFullUrl: () => string
}
