import type { FunctionComponent } from 'react'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import type { ProductHubSlug } from '@which/shared'

import type { Breadcrumb } from '../../generated/frontend'
import { useProductHubPageQuery } from '../../generated/frontend'
import type { PageParams } from '../../routes'
import { Error, Loader, PageError, ThemeProvider } from '../../shared'
import type { AdditionalContentProps } from './components/AdditionalContent/AdditionalContent'
import type { ProductHubAdviceProps } from './components/ProductHubAdvice/ProductHubAdvice'
import type { ProductHubBrandLinksProps } from './components/ProductHubBrandLinks/ProductHubBrandLinks'
import type { ProductHubIntroProps } from './components/ProductHubIntro/ProductHubIntro'
import type { ProductHubJumpLinksProps } from './components/ProductHubJumpLinks/ProductHubJumpLinks'
import { ProductHubPage } from './components/ProductHubPage'
import type { ProductTypesDataProps } from './components/ProductHubProductTypes/ProductHubProductTypes'
import type { ProductHubReviewsHardcodedProps } from './components/ProductHubReviews/ProductHubReviews'
import type { ProductHubRightsProps } from './components/ProductHubRights/ProductHubRights'
import type { RelatedProducts } from './components/RelatedProducts'
import { productHubData, productHubDataLayer } from './constants/product-hub'
import { useProductHubPageMetaTags } from './hooks/useProductHubPageMetaTags'

const ProductHub: FunctionComponent = () => {
  const { getMetaTags } = useProductHubPageMetaTags()
  const { productSlug } = useParams<PageParams>()
  const { loading, error, data } = useProductHubPageQuery({ variables: { productSlug } })

  const hardcodedData = productHubData[productSlug]

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <Error error={error} />
  }

  if (!data || !hardcodedData) {
    return <PageError pageName="Product Hub" />
  }

  const { productHubPage } = data

  const productHubDataLayerData = productHubDataLayer[productSlug]

  return (
    <ThemeProvider>
      <Helmet>
        {getMetaTags({
          meta: {
            dataLayer: [
              {
                loginStatus: productHubPage.isLoggedIn ? 'loggedIn' : 'loggedOut',
                paidAccess: productHubPage.isLoggedIn ? 'paid' : 'free',
                sub_vertical: productHubDataLayerData.sub_vertical,
                category: productHubDataLayerData.category,
                category_id: productHubDataLayerData.category_id,
              },
            ],
            desc: productHubDataLayerData.desc,
            fbAppId: '',
            title: productHubDataLayerData.title,
            pageType: 'product-hub',
            taxonomyHierarchy: {
              vertical: productHubDataLayerData.vertical,
              subVertical: productHubDataLayerData.sub_vertical,
              category: productHubDataLayerData.category,
              superCategory: null,
            },
            canonical: `https://www.which.co.uk/l/${productHubDataLayerData.sub_vertical}/${productSlug}`,
            __typename: 'Meta',
          },
          imageUrl: hardcodedData.intro.image.src,
          imageAlt: productHubDataLayerData.title,
          twitterCard: 'summary_large_image',
        })}
      </Helmet>
      <ProductHubPage {...{ ...hardcodedData, ...productHubPage }} />
    </ThemeProvider>
  )
}

export default ProductHub

export type ProductHubHardcodedData = Record<ProductHubSlug, ProductHubHardcodedProps>

export type ProductHubHardcodedProps = {
  intro: ProductHubIntroProps
  jumpLinks: ProductHubJumpLinksProps
  breadcrumb: Breadcrumb
  productReviews: ProductHubReviewsHardcodedProps
  brandLinks: ProductHubBrandLinksProps
  relatedProducts: RelatedProducts
  advice: ProductHubAdviceProps
  additionalContent?: AdditionalContentProps
  rights: ProductHubRightsProps
  description: AdditionalContentProps
  productTypesData?: ProductTypesDataProps
}
