import type { FunctionComponent } from 'react'
import React, { useState } from 'react'
import type { ImageSource, LinksListExpandable } from '@which/seatbelt'
import { ToolSignpost } from '@which/seatbelt'
import { isExternalLink } from '@which/shared'

import uniqueId from 'lodash/uniqueId'

import type { Link } from '../../../generated/frontend'
import styles from './ToolCta.module.scss'

export const ToolCTA: FunctionComponent<Props> = ({
  button,
  className,
  description,
  image,
  link,
  title,
  widgetImageAspectRatio,
  captionText,
  linksListExpandableData,
}) => {
  const [contentHtmlId] = useState<string>(uniqueId('tool-cta-links-list-'))

  const externalLinkProps = { target: '_blank', rel: 'nofollow noopener noreferrer' }

  let buttonProps: ToolCtaLink = { text: '', href: '' }
  let linkProps: ToolCtaLink = { text: '', href: '' }

  if (button?.href) {
    buttonProps = isExternalLink(button.href) ? { ...button, ...externalLinkProps } : button
  }

  if (link?.href) {
    linkProps = isExternalLink(link.href) ? { ...link, ...externalLinkProps } : link
  }

  return (
    <div data-testid="tool-cta" className={styles.toolCTA}>
      <ToolSignpost
        button={buttonProps}
        {...(linksListExpandableData && {
          linksListExpandableData: { ...linksListExpandableData, contentHtmlId },
        })}
        className={className}
        description={description}
        image={image}
        link={linkProps}
        title={title}
        imageAspectRatio={getImageAspectRatio(widgetImageAspectRatio)}
        captionText={captionText}
      />
    </div>
  )
}

///////// IMPLEMENTATION /////////

const getImageAspectRatio = (widgetImageAspectRatio: WidgetImageAspectRatio) =>
  ({
    ar_1to1_: 'one-to-one' as const,
    ar_2to1_: 'two-to-one' as const,
  })[widgetImageAspectRatio]

type ToolCtaLink = Link & {
  target?: string
  rel?: string
}

type WidgetImageAspectRatio = 'ar_1to1_' | 'ar_2to1_'

export type Props = {
  button: Link
  className?: string
  description?: string
  image?: {
    src: string
    alt: string
    sizes?: ImageSource[]
  }
  link?: Link
  title: string
  widgetImageAspectRatio: WidgetImageAspectRatio
  captionText?: string
  linksListExpandableData?: Omit<Parameters<typeof LinksListExpandable>[0], 'contentHtmlId'>
}
