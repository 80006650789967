import type { FunctionComponent } from 'react'
import React from 'react'
import { ConvoSignpost as SBConvoSignpost, NavigationLink } from '@which/seatbelt'

import type { ConvoSignpost as ConvoSignpostProps } from '../../../../generated/frontend'

export const ConvoSignpost: FunctionComponent<ConvoSignpostProps> = ({
  description,
  text,
  href,
  image,
}) => {
  if (!text || !href) {
    return null
  }

  return (
    <article>
      <SBConvoSignpost
        description={description}
        image={image?.default}
        sources={image?.mobile ? [{ srcset: image?.mobile, media: '(max-width: 767px)' }] : []}
      />
      <NavigationLink href={href}>{text}</NavigationLink>
    </article>
  )
}
