import type { FunctionComponent } from 'react'
import React from 'react'
import { Hr, StandardLink, Typography } from '@which/seatbelt/'

import styles from './WhichConnectMessage.module.scss'

export const WhichConnectMessage: FunctionComponent<Props> = ({ showMessage }) => {
  if (!showMessage) {
    return null
  }

  return (
    <div data-testid="which-connect-message" className={styles.whichConnectMessage}>
      <Hr />
      <Typography textStyle="small-print-compact">
        This article uses insights from the Which? Connect panel, collected from research activities
        with our members.{' '}
        <StandardLink href="https://www.which.co.uk/about-which/get-involved/which-connect-survey-panel">
          Find out how to get involved
        </StandardLink>
      </Typography>
      <Hr />
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  showMessage: boolean
}
