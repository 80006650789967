import type { FunctionComponent } from 'react'
import React, { useState } from 'react'
import { ExternalLinkIcon, Link, LinkButton, Typography } from '@which/seatbelt'

import { MarketingConsent } from './MarketingConsent'
import style from './MarketingPreferences.module.scss'

export const MarketingPreferences: FunctionComponent = () => {
  const [showEmailTerms, setShowEmailTerms] = useState(false)

  const handleShowTerms = () => {
    setShowEmailTerms(!showEmailTerms)
  }

  const accessibleText = 'link opens in a new window'
  return (
    <>
      <Typography textStyle="small-print-medium">
        Keep me updated about products and services from the{' '}
        <Link
          href="https://www.which.co.uk/help/terms-and-conditions/1948/general-terms"
          target="_blank"
          title={accessibleText}
          aria-label={`Which? Group ${accessibleText}`}
        >
          Which? Group
        </Link>{' '}
        <ExternalLinkIcon alt={accessibleText} /> and the work it does to make life simpler, safer
        and fairer for UK consumers.
      </Typography>
      <div className={style.marketingConsentHeading}>
        <LinkButton
          aria-expanded={showEmailTerms ? 'true' : 'false'}
          aria-controls="email-terms-more-info"
          data-testid="show-more-link"
          onClick={handleShowTerms}
        >
          {showEmailTerms ? 'Show less' : 'Show more'}
        </LinkButton>
        {showEmailTerms && (
          <Typography
            className={'sb-padding-top-2 sb-padding-bottom-2'}
            data-testid="privacy-terms"
            textStyle="very-small-print"
            id="email-terms-more-info"
          >
            The{' '}
            <Link href="https://www.which.co.uk/help/general-terms-aYhBS9Y7TTtE">Which? group</Link>{' '}
            is the Consumers' Association (a charity) that owns Which? Ltd. It promotes and protects
            consumer interests through information, policy work, campaigns and legal actions. Which?
            Ltd provides most of the group's commercial services, including legal, wills and
            switching services. Most of the content on{' '}
            <Link href="https://www.which.co.uk">which.co.uk</Link> is provided by Which? Ltd, with
            some content and services provided by Consumers' Association including consumer rights
            pages, and information on its work for consumers.{' '}
            <Link href="https://www.which.co.uk/about-which/who-we-are">
              Read more about the Which? group
            </Link>
            .
          </Typography>
        )}
      </div>
      <MarketingConsent radioType="email" />
      <MarketingConsent radioType="post" />
      <Typography className={style.marketingConsentFooter}>
        By creating an account you agree with our{' '}
        <Link
          href="https://www.which.co.uk/help/terms-and-conditions/1955/your-which-membership"
          target="_blank"
          title={accessibleText}
          aria-label={`terms and conditions ${accessibleText}`}
        >
          terms and conditions
        </Link>{' '}
        <ExternalLinkIcon alt={accessibleText} /> , and we will process your details in accordance
        with our{' '}
        <Link
          href="https://www.which.co.uk/help/our-policies-and-standards/1975/which-privacy-notice"
          target="_blank"
          title={accessibleText}
          aria-label={`privacy notice ${accessibleText}`}
        >
          privacy notice
        </Link>{' '}
        <ExternalLinkIcon alt={accessibleText} />.
      </Typography>
    </>
  )
}
