import type { FunctionComponent } from 'react'
import React from 'react'
import { Grid, GridItem } from '@which/seatbelt'

import classnames from 'classnames'

import type { ProductHubPage as ProductHubPageType } from '../../../generated/frontend'
import { Breadcrumb } from '../../../shared'
import { LatestNews } from '../../article/components'
import type { ProductHubHardcodedProps } from '../ProductHub'
import { AdditionalContent } from './AdditionalContent/AdditionalContent'
import { ProductHubAdvice } from './ProductHubAdvice/ProductHubAdvice'
import { ProductHubBrandLinks } from './ProductHubBrandLinks/ProductHubBrandLinks'
import { ProductHubIntro } from './ProductHubIntro'
import { ProductHubJumpLinks } from './ProductHubJumpLinks'
import styles from './ProductHubPage.module.scss'
import { ProductHubProductTypes } from './ProductHubProductTypes/ProductHubProductTypes'
import { ProductHubReviews } from './ProductHubReviews/ProductHubReviews'
import { ProductHubRights } from './ProductHubRights/ProductHubRights'
import { RelatedProducts } from './RelatedProducts'

export const ProductHubPage: FunctionComponent<ProductHubPageProps> = ({
  intro,
  breadcrumb,
  jumpLinks,
  advice,
  additionalContent,
  rights,
  productReviews,
  isLoggedIn,
  products,
  brandLinks,
  relatedProducts,
  description,
  latestNews,
  productTypesData,
}) => (
  <main id="main-content">
    {breadcrumb && <Breadcrumb {...breadcrumb} />}
    {intro && (
      <Grid className={styles.maxWidth}>
        <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
          <ProductHubIntro {...intro} className={styles.sectionSpacing} />
        </GridItem>
      </Grid>
    )}
    {jumpLinks && (
      <section className={classnames(styles.sectionGrey, styles.sectionSpacing)}>
        <Grid className={styles.maxWidth}>
          <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
            <ProductHubJumpLinks {...jumpLinks} />
          </GridItem>
        </Grid>
      </section>
    )}

    {productReviews && (
      <Grid className={styles.maxWidth}>
        <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
          <ProductHubReviews {...productReviews} isLoggedIn={isLoggedIn} products={products} />
        </GridItem>
      </Grid>
    )}

    {brandLinks && (
      <Grid className={styles.maxWidth}>
        <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
          <ProductHubBrandLinks {...brandLinks} />
        </GridItem>
      </Grid>
    )}

    {productTypesData && (
      <Grid className={styles.maxWidth}>
        <GridItem
          className={styles.sectionSpacing}
          span={{ medium: 10, large: 10 }}
          columnStart={{ medium: 2, large: 2 }}
        >
          <ProductHubProductTypes {...productTypesData} />
        </GridItem>
      </Grid>
    )}

    {advice && (
      <Grid className={styles.maxWidth}>
        <GridItem
          className={styles.sectionSpacing}
          span={{ medium: 10, large: 10 }}
          columnStart={{ medium: 2, large: 2 }}
        >
          <ProductHubAdvice {...advice} />
        </GridItem>
      </Grid>
    )}

    {relatedProducts && (
      <Grid className={styles.maxWidth}>
        <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
          <RelatedProducts className={styles.sectionSpacing} relatedProducts={relatedProducts} />
        </GridItem>
      </Grid>
    )}

    {additionalContent && (
      <Grid className={styles.maxWidth}>
        <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
          <AdditionalContent {...additionalContent} borderBlock={false} />
        </GridItem>
      </Grid>
    )}

    <Grid className={styles.maxWidth}>
      <GridItem
        className={classnames(styles.sectionSpacing, {
          [styles.topOnly]: description,
        })}
        span={{ medium: 10, large: 10 }}
        columnStart={{ medium: 2, large: 2 }}
      >
        {rights && <ProductHubRights {...rights} />}
      </GridItem>
    </Grid>

    {latestNews && (
      <Grid className={styles.maxWidth}>
        <GridItem
          className={styles.sectionSpacing}
          span={{ medium: 10, large: 10 }}
          columnStart={{ medium: 2, large: 2 }}
        >
          <LatestNews
            news={{ ...latestNews, name: intro.heading }}
            className={classnames(styles.latestNews)}
          />
        </GridItem>
      </Grid>
    )}

    {description && (
      <Grid className={styles.maxWidth}>
        <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
          <AdditionalContent {...description} borderBlock={true} />
        </GridItem>
      </Grid>
    )}
  </main>
)

///////// IMPLEMENTATION /////////

export type ProductHubPageProps = ProductHubHardcodedProps & ProductHubPageType
