import type { FunctionComponent } from 'react'
import React, { useState } from 'react'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  LinkButton,
  NavigationLink,
  Typography,
} from '@which/seatbelt'
import type { Rest } from '@which/shared'

import classnames from 'classnames'

import styles from './ProductHubJumpLinks.module.scss'

export const ProductHubJumpLinks: FunctionComponent<ProductHubJumpLinksProps> = ({
  items,
  className,
  title = 'What we offer',
  ...rest
}) => {
  const maxSize = 8
  const linksLength = items.length
  const isExcessive = linksLength > maxSize
  const [isExpanded, setIsExpanded] = useState(false)

  const navigateToSection = (event: Event, sectionPath: string) => {
    event.preventDefault()

    const targetSection = document.getElementById(sectionPath.substring(1))
    targetSection && targetSection.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <section
      className={classnames(styles.container, className)}
      data-testid="jumplinks"
      {...rest}
      aria-labelledby="product-hub-jump-link-title"
    >
      <Typography
        className={styles.title}
        tag="h2"
        textStyle="title-500"
        id="product-hub-jump-link-title"
      >
        {title}
      </Typography>

      <ul className={styles.links} aria-atomic aria-live="polite">
        {items.slice(0, isExpanded ? Infinity : maxSize).map(({ text, path }, index) => (
          <li key={`link_${text}_${index}`} className={styles.link}>
            <NavigationLink
              href={path}
              onClick={(e) => navigateToSection(e, path)}
              icon="arrowDown"
              data-testid="producthub-jumplinks-link"
              data-which-id="anchor-link"
            >
              {text}
            </NavigationLink>
          </li>
        ))}
      </ul>

      {isExcessive && (
        <div className={styles.all}>
          <LinkButton
            onClick={() => setIsExpanded(!isExpanded)}
            icon={isExpanded ? ChevronUpIcon : ChevronDownIcon}
            aria-atomic
            aria-pressed={isExpanded}
            data-testid="jumplinks-toggle-link"
          >
            {isExpanded ? 'View fewer links' : 'View more links'}
          </LinkButton>
        </div>
      )}
    </section>
  )
}

///////// IMPLEMENTATION /////////

export type ProductHubJumpLinksProps = {
  items: Array<{
    text: string
    path: string
    onClick?: ((event: MouseEvent) => void) | (() => void)
  }>
  className?: string
  title?: string
} & Rest
