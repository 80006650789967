import type { FunctionComponent } from 'react'
import React, { useMemo } from 'react'

import loadable from '@loadable/component'
import newrelic from 'newrelic'

import { Loader } from '../../shared'
import { PagePropsContext } from '../../shared/usePageProps'
import type { PageProps } from '../'

export const Page: FunctionComponent<PageProps> = ({
  pagePath,
  template,
  rootCollectionName,
  context,
}) => {
  const MatchedPage = useMemo(
    () =>
      loadable(() => import(`../../pages/${pagePath}`), {
        fallback: <Loader />,
      }),
    [pagePath]
  )

  if (typeof newrelic !== 'undefined') {
    newrelic.setTransactionName(pagePath)
  }

  return (
    <PagePropsContext.Provider
      value={{
        template,
        rootCollectionName,
        context,
      }}
    >
      <MatchedPage />
    </PagePropsContext.Provider>
  )
}
