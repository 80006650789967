import type { FunctionComponent } from 'react'
import React from 'react'
import { ExternalLinkIcon, Typography } from '@which/seatbelt'

import mindDesktopJPG from './assets/mind-desktop.jpg'
import mindDesktopWEBP from './assets/mind-desktop.webp'
import mindMobileJPG from './assets/mind-mobile.jpg'
import mindMobileWEBP from './assets/mind-mobile.webp'
import mindTabletJPG from './assets/mind-tablet.jpg'
import mindTabletWEBP from './assets/mind-tablet.webp'
import victimSupportDesktopJPG from './assets/victim-support-desktop.jpg'
import victimSupportDesktopWEBP from './assets/victim-support-desktop.webp'
import victimSupportMobileJPG from './assets/victim-support-mobile.jpg'
import victimSupportMobileWEBP from './assets/victim-support-mobile.webp'
import victimSupportTabletJPG from './assets/victim-support-tablet.jpg'
import victimSupportTabletWEBP from './assets/victim-support-tablet.webp'
import styles from './CRScamsEmotionalSupport.module.scss'

export const CRScamsEmotionalSupport: FunctionComponent = () => (
  <div
    className={styles.scamEmotionalSupport}
    data-which-section="Emotional support"
    id="emotional-support"
    data-testid="CRScamsEmotionalSupport"
  >
    <Typography tag="h2" textStyle="title-500" className={styles.supportHeading}>
      Emotional support after a scam
    </Typography>
    <Typography tag="p" className={styles.supportDescription}>
      Being scammed can take a huge toll on your mental health. You might find it helpful to talk to
      someone about what you’re going through. It’s not your fault, and there are plenty of
      non-judgemental advice lines you can call who will understand.
    </Typography>
    <div className={styles.supportCards}>
      <div className={styles.supportCard} data-testid="mind-card">
        <picture>
          <source srcSet={mindDesktopWEBP} type="image/webp" media="(min-width: 960px)" />
          <source srcSet={mindDesktopJPG} type="image/jpg" media="(min-width: 960px)" />
          <source srcSet={mindTabletWEBP} type="image/webp" media="(min-width: 768px)" />
          <source srcSet={mindTabletJPG} type="image/jpg" media="(min-width: 768px)" />
          <source srcSet={mindMobileWEBP} type="image/webp" media="(max-width: 767px)" />
          <img src={mindMobileJPG} alt="Mind" sizes="100vw" className={styles.supportCardImage} />
        </picture>
        <Typography tag="h3" textStyle="title-500" className={styles.supportHeading}>
          Mind
        </Typography>
        <Typography tag="p" className={styles.supportCardDescription}>
          Mind has a confidential information and support line, Mind Infoline, available on{' '}
          <a href="tel:03001233393">0300 123 3393</a> (lines open 9am - 6pm, Monday - Friday).
        </Typography>
        <a
          href="https://www.mind.org.uk/"
          className={styles.supportExternalLink}
          target="_blank"
          rel="noreferrer"
        >
          <ExternalLinkIcon className={styles.externalLinkIcon} />
          Visit Mind
        </a>
      </div>
      <div className={styles.supportCard} data-testid="victim-card">
        <picture className={styles.scamPhonePicture}>
          <source srcSet={victimSupportDesktopWEBP} type="image/webp" media="(min-width: 960px)" />
          <source srcSet={victimSupportDesktopJPG} type="image/jpg" media="(min-width: 960px)" />
          <source srcSet={victimSupportTabletWEBP} type="image/webp" media="(min-width: 768px)" />
          <source srcSet={victimSupportTabletJPG} type="image/jpg" media="(min-width: 768px)" />
          <source srcSet={victimSupportMobileWEBP} type="image/webp" media="(max-width: 767px)" />
          <img
            src={victimSupportMobileJPG}
            alt="Victim support"
            sizes="100vw"
            className={styles.supportCardImage}
          />
        </picture>
        <Typography tag="h3" textStyle="title-500" className={styles.supportHeading}>
          Victim Support
        </Typography>
        <Typography tag="p" className={styles.supportCardDescription}>
          Victim Support has a free helpline where you can speak to someone confidentially available
          on <a href="tel:08081689111">0808 16 89 111</a> (lines open 24/7).
        </Typography>
        <a
          href="https://www.victimsupport.org.uk/"
          className={styles.supportExternalLink}
          target="_blank"
          rel="noreferrer"
        >
          <ExternalLinkIcon className={styles.externalLinkIcon} />
          Visit Victim Support
        </a>
      </div>
    </div>
  </div>
)
