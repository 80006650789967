import type { FunctionComponent } from 'react'
import React from 'react'
import { ArticleCard, CardRow } from '@which/seatbelt'

import isNil from 'lodash/isNil'

import type { ConsumerRegulations as ConsumerRegulationsType } from '../../../generated/frontend'

export const ConsumerRegulations: FunctionComponent<Props> = ({ data }) => {
  if (isNil(data)) {
    return null
  }

  return (
    <CardRow title={data.title} link={data.all}>
      {data.items.map((item) => (
        <ArticleCard
          key={`article_${item.title}`}
          title={item.title}
          strapline={item?.standfirst ?? ''}
          href={item.href}
          authorName={item.authors[0]?.name}
          authorImage={item.authors[0]?.image?.src ?? ''}
          authorImageSources={item.authors[0]?.image?.sources}
          display="scroll-row"
        />
      ))}
    </CardRow>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  data: ConsumerRegulationsType
}
