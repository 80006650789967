import type { FunctionComponent } from 'react'
import React from 'react'
import { Typography } from '@which/seatbelt'
import { createSlugGenerator } from '@which/shared'

import { CardedDynamicTable } from '../CardedDynamicTable'
import type {
  ArticleProductCardRestrictedProps,
  CardedDynamicTableProduct,
} from '../CardedDynamicTable/CardedDynamicTable'
import styles from '../CardedDynamicTable/CardedDynamicTable.module.scss'
import { GrowthBookWrapper } from './GrowthBookWrapper'

export const SmartProductPicker: FunctionComponent<Props> = ({
  title,
  description,
  logInCTA,
  products = [],
  signUpCTA,
  restricted,
}) => {
  if (!products.length) {
    return null
  }

  const slugGenerator = createSlugGenerator()

  return (
    <div data-testid="smart-product-picker" className={styles.container}>
      {title && (
        <Typography
          tag="h2"
          textStyle="title-600"
          className="h2-article"
          id={slugGenerator(title, true)}
        >
          {title}
        </Typography>
      )}

      {description && (
        <div data-testid="spp-description" dangerouslySetInnerHTML={{ __html: description }} />
      )}

      {restricted?.revealProductABTest ? (
        <GrowthBookWrapper
          products={products}
          logInCTA={logInCTA}
          signUpCTA={signUpCTA}
          restricted={restricted}
        />
      ) : (
        <CardedDynamicTable
          products={products}
          logInCTA={logInCTA}
          signUpCTA={signUpCTA}
          trackingPrefix="spp"
          restricted={restricted}
        />
      )}
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  description?: string
  logInCTA?: { url: string; label: string; copy: string }
  title?: string
  products: CardedDynamicTableProduct[]
  restricted?: ArticleProductCardRestrictedProps
  signUpCTA?: { url: string; label: string; smallPrint?: string }
}
