import React from 'react'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { useFullUrl } from '../../hooks/useFullUrl'
import type { Props } from './AssociatedArticles'
import { AssociatedArticles } from './AssociatedArticles'
import { AssociatedArticlesWithShowMore } from './AssociatedArticlesWithShowMore'

// @TODO: poj-005-sidebar - remove file after test and tidy up
// logic in here targets the broadband providers article for A/B test
export const getIsBroadbandProvidersArticlePage = (url: string) =>
  url.includes('reviews/broadband/article/broadband-provider-reviews/best-broadband-providers')

const AssociatedArticlesABTest: React.FC<Props> = (props) => {
  const poj005SidebarFeatureOn = useFeatureIsOn('poj-005-sidebar')

  return poj005SidebarFeatureOn ? (
    <AssociatedArticlesWithShowMore {...props} />
  ) : (
    <AssociatedArticles {...props} />
  )
}

export const AssociatedArticlesExperiment: React.FC<Props> = (props) => {
  const { getFullUrl } = useFullUrl()
  const isBroadbandProvidersArticle = getIsBroadbandProvidersArticlePage(getFullUrl())

  return isBroadbandProvidersArticle ? (
    <AssociatedArticlesABTest {...props} />
  ) : (
    <AssociatedArticles {...props} />
  )
}
