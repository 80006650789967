import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import type { WidgetSnippet } from '@which/glide-ts-types'
import { GridItem } from '@which/seatbelt'

import { loadScript } from '../../../shared/utils'
import { HideOnArticleScroll } from '../HideOnScroll'

// @TODO: Could improve types in this file
export const HTMLSnippet: FunctionComponent<Props> = ({ className, body = '', resources = [] }) => {
  const [resourcesObject, setResourceObject] = useState({
    scripts: [],
    others: [],
  })

  useEffect(() => {
    const resourcesPerType = createResourcesObject(resources)

    setResourceObject(resourcesPerType)

    const { scripts } = resourcesPerType

    // If resources have scripts items, load them with utility
    if (scripts.length > 0) {
      scripts.forEach((script) => {
        loadScript({
          src: script.src,
          async: script.async,
        })
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (body === '') {
    return null
  }

  let WrapperComponent = className?.includes('griditem') ? GridItem : React.Fragment

  WrapperComponent = className?.includes('hideOnArticleScroll')
    ? HideOnArticleScroll
    : WrapperComponent

  const { others } = resourcesObject

  return (
    <WrapperComponent>
      {others?.map(parseResources)}
      <div className={className} dangerouslySetInnerHTML={{ __html: body }} />
    </WrapperComponent>
  )
}

///////// IMPLEMENTATION /////////

type Props = WidgetSnippet & {
  className?: string
  isGridItem?: boolean
}

const createResourcesObject = (resources) =>
  resources.reduce(
    (acc, resource) => {
      const { resource_type: resourceType } = resource

      if (resourceType === 'SCRIPT' && !!resource.src) {
        return {
          ...acc,
          scripts: [
            ...acc.scripts,
            {
              src: resource.src,
              async: resource.async,
            },
          ],
        }
      } else {
        return {
          ...acc,
          others: [...acc.others, resource],
        }
      }
    },
    {
      scripts: [],
      others: [],
    }
  )

const parseResources = (resource, ind) => {
  const { resource_type: resourceType = '', body, async, src } = resource

  if (resourceType === '') {
    return null
  }

  return resourceType === 'SCRIPT' ? (
    <Helmet key={`${resourceType}-${ind}`}>
      <script async={async} defer>
        {body}
      </script>
    </Helmet>
  ) : (
    <Helmet key={`${resourceType}-${ind}`}>
      {src ? <link href={src} rel="stylesheet" /> : <style>{body}</style>}
    </Helmet>
  )
}
