import type { FunctionComponent } from 'react'
import React from 'react'
import { Typography } from '@which/seatbelt'
import { Author as SBAuthor } from '@which/seatbelt'

import classnames from 'classnames'

import type { Author } from '../../../../../generated/frontend'
import styles from './Fact.module.scss'

// To be Replaced by Seatbelt component
export const Fact: FunctionComponent<Props> = ({ title, body, author }) => {
  return (
    <div className={classnames(styles.container)} data-testid="fact">
      <SBAuthor
        name=""
        className={styles.pre}
        src={author.image?.src}
        sources={author.image?.sources}
      />

      <Typography tag="p" textStyle="title-600" className={styles.title}>
        {title}
      </Typography>

      <Typography tag="p" textStyle="body" className={styles.body}>
        {body}
      </Typography>
    </div>
  )
}

///IMPLEMENTATION////

type Props = {
  title: string
  body: string
  author: Author
}
