import type { FunctionComponent, ReactElement } from 'react'
import React, { forwardRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import { Grid, GridItem } from '@which/seatbelt'
import { gql, useQuery } from '@apollo/client'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { HTMLSnippet, templateRenderer, ThemeProvider } from '..'
import {
  CRScamsChecklist,
  CRToolEntryPoint,
  Error,
  GamPanel,
  IframeEmbed,
  Loader,
  ToolCTA,
} from '../components'
import { getIsBroadbandProvidersArticlePage } from '../components/AssociatedArticles/AssociatedArticlesExperiment'
import { useFullUrl } from '../hooks/useFullUrl'
import type { PageInfo } from '../types'
import { usePageProps } from '../usePageProps'

export const PageTemplate = forwardRef<HTMLDivElement, Props>(
  ({ className, components, metaTags, gamConfig }: Props, ref) => {
    const { template: templateName } = usePageProps()
    const { getFullUrl } = useFullUrl()
    const url = getFullUrl()
    const { loading, error, data } = useQuery(PAGE_TEMPLATE_QUERY, {
      variables: { templateName },
    })

    if (!templateName) {
      return null
    }

    if (loading) {
      return <Loader />
    }

    if (error) {
      return <Error error={error} />
    }

    const { pageTemplate: templateData } = data

    return (
      <ThemeProvider>
        <Helmet>{metaTags}</Helmet>
        <div
          className={className}
          data-glide-template={templateName}
          ref={ref}
          data-testid="template-portal"
        >
          {templateRenderer({
            // @ts-expect-error needs fixing
            components: addTemplateComponents(components, url, gamConfig), // Remove url param after A/B test
            templateName,
            templateData,
          })}
        </div>
      </ThemeProvider>
    )
  }
)

///////// IMPLEMENTATION /////////

// Temp code for poj-005-sidebar A/B test clean up after test
// Disables GAM panel on broadband providers article when flag is active
// Only works on prod as using snippet id
type HTMLSnippetProps = Parameters<typeof HTMLSnippet>[0]

const HtmlSnippetABTest: React.FC<HTMLSnippetProps> = (props) => {
  const poj005SidebarFeatureOn = useFeatureIsOn('poj-005-sidebar')
  const disableHTMLSnippet = poj005SidebarFeatureOn && (props.id === 2 || props.id === 3)

  return disableHTMLSnippet ? null : <HTMLSnippet {...props} />
}

const HtmlSnippetExperiment: React.FC<{
  url: string
  snippetProps: HTMLSnippetProps
}> = ({ url, snippetProps }) => {
  const isBroadbandProvidersArticle = getIsBroadbandProvidersArticlePage(url)

  return isBroadbandProvidersArticle ? (
    <HtmlSnippetABTest {...snippetProps} />
  ) : (
    <HTMLSnippet {...snippetProps} />
  )
}

const addTemplateComponents = (
  components: Props['components'],
  url: string, // Remove after A/B test
  gamConfig?: GamConfig
) => ({
  ...components,
  HTMLSnippet: (props: HTMLSnippetProps) => {
    const { search } = useLocation()
    const queryParamString = new URLSearchParams(search).toString()
    const isQualtricsForm = props.body?.includes('qualtrics.com')
    const queryParamsContainEmail = queryParamString.includes('%40')

    if (isQualtricsForm && queryParamsContainEmail) {
      return null
    }

    return props.isGridItem ? (
      <GridItem>
        <HTMLSnippet {...props} />
      </GridItem>
    ) : (
      // Remove after A/B test replacing with <HTMLSnippet {...props} />
      <HtmlSnippetExperiment url={url} snippetProps={props} />
    )
  },
  IframeEmbed: (props: Parameters<typeof IframeEmbed>[0]) => <IframeEmbed {...props} />,
  ToolCTA: (props: Parameters<typeof ToolCTA>[0]) => (
    <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
      <ToolCTA {...props} />
    </GridItem>
  ),
  CRToolEntryPoint: (props: Parameters<typeof CRToolEntryPoint>[0]) => (
    <Grid>
      <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
        <CRToolEntryPoint {...props} />
      </GridItem>
    </Grid>
  ),
  CRScamsChecklist: (props: Parameters<typeof CRScamsChecklist>[0]) => (
    <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
      <CRScamsChecklist {...props} />
    </GridItem>
  ),
  GamPanel: (props: Parameters<typeof GamPanel>[0]) => (
    <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
      <GamPanel {...props} {...gamConfig} />
    </GridItem>
  ),
})

type Props = {
  className?: string
  components: Record<string, FunctionComponent<any>>
  metaTags: ReactElement[]
  gamConfig?: GamConfig
}

type GamConfig = {
  pageInfo: PageInfo
  login?: { loginStatus: string }
}

export const PAGE_TEMPLATE_QUERY = gql`
  query pageTemplate($templateName: String!) {
    pageTemplate(templateName: $templateName) {
      json_tree
      label
      additionalData {
        htmlSnippets {
          id
          body
          resources {
            id
            inline
            src
            body
            async
            resource_type
          }
        }
        images {
          id
          src
          sources {
            srcset
            sizes
          }
        }
      }
    }
  }
`
