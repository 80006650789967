import type { FunctionComponent } from 'react'
import React from 'react'
import { StandardLink, Typography } from '@which/seatbelt'

import styles from './AffiliateDisclaimer.module.scss'

export const AffiliateDisclaimer: FunctionComponent<Props> = ({ affiliateDisclaimer }) => {
  if (!affiliateDisclaimer) {
    return null
  }

  return (
    <div className={styles.affiliateDisclaimerWrapper}>
      <Typography
        className={styles.affiliateLinksDisclaimer}
        data-testid="affiliate-links-disclaimer"
        textStyle="very-small-print"
      >
        By clicking a retailer link you consent to third party{' '}
        <StandardLink
          className={styles.affiliateLinksDisclaimerLink}
          href="/help/our-cookies-policy-your-cookies-preferences-aAuoo0H8Zif6"
          textStyle="very-small-print"
        >
          cookies
        </StandardLink>{' '}
        that track your onward journey. If you make a purchase, Which? will receive an{' '}
        <StandardLink
          className={styles.affiliateLinksDisclaimerLink}
          href="/help/which-affiliate-activity-aPA408A93lxh"
          textStyle="very-small-print"
        >
          affiliate commission
        </StandardLink>{' '}
        which supports our mission to be the{' '}
        <StandardLink
          className={styles.affiliateLinksDisclaimerLink}
          href="/about-which/who-we-are-azQ8t7R93VDg#the-which-group"
          textStyle="very-small-print"
        >
          UK's consumer champion
        </StandardLink>
        .
      </Typography>
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  affiliateDisclaimer: boolean
}
