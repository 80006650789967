import type { FunctionComponent } from 'react'
import React from 'react'
import { Grid, GridItem, Link, List, ListItem, Picture, Typography } from '@which/seatbelt'

import imageJPG from '../News404Error/Image.jpg'
import imageWEBP from '../News404Error/Image.webp'
import repeatedStyles from '../News404Error/News404Error.module.scss'
import { Status } from '../StatusContext'
import styles from './ToolFramework404Error.module.scss'

export const ToolFramework404Error: FunctionComponent<Props> = ({ message }) => {
  return (
    <Status code="404">
      <main className="w-page-wrapper">
        <Grid className={repeatedStyles.grid}>
          <GridItem
            span={{ small: 2, medium: 6, large: 6, xlarge: 5 }}
            columnStart={{ medium: 7, large: 7, xlarge: 7 }}
          >
            <Picture
              {...imageProps}
              className={repeatedStyles.image}
              aspectRatioMain="one-to-one"
              alt=""
            />
          </GridItem>
          <GridItem
            span={{ small: 2, medium: 6, large: 6, xlarge: 5 }}
            columnStart={{ xlarge: 2 }}
            className={repeatedStyles.textContainer}
          >
            <Typography tag="h1" textStyle="title-600" className={repeatedStyles.heading}>
              This page can't be found
            </Typography>
            <Typography
              textStyle="body-intro"
              className={repeatedStyles.errorCode}
              data-error={message}
            >
              Error: Invalid_request
            </Typography>
            <Typography textStyle="body" className={repeatedStyles.text}>
              You may not be able to visit this page because of:
            </Typography>
            <List>
              <ListItem className={styles.toolFrameworkErrorBulletBlack}>
                <Typography textStyle="body">A mistyped address</Typography>
              </ListItem>
              <ListItem className={styles.toolFrameworkErrorBulletBlack}>
                <Typography textStyle="body">An out-of-date listing for this site</Typography>
              </ListItem>
              <ListItem className={styles.toolFrameworkErrorBulletBlack}>
                <Typography textStyle="body">You have no access to this page</Typography>
              </ListItem>
            </List>
            <Typography textStyle="body" className={styles.toolFrameworkErrorText}>
              Please re-try to click the link in your email.
            </Typography>
            <Typography textStyle="body" className={repeatedStyles.text}>
              If difficulties persist, <Link href="/about-which/contact-us">contact us</Link> about
              the problem.
            </Typography>
          </GridItem>
        </Grid>
      </main>
    </Status>
  )
}

///////// IMPLEMENTATION /////////

const imageProps = {
  src: imageJPG,
  sources: [
    {
      srcset: imageWEBP,
      type: 'image/webp',
    },
    {
      srcset: imageJPG,
      type: 'image/jpeg',
    },
  ],
}

type Props = {
  message: string
}
