import type { TableBodyContent } from '../CsvTableVariation/CsvTableVariation'

export const getVariantSettings = (
  testVariant: string | undefined,
  tableContent: TableBodyContent[][]
) => {
  if (!tableContent.length || !testVariant) {
    return {
      colSpan: 1,
      rowSpan: 1,
    }
  }

  // Assuming all rows have same cells length
  const [singleRow] = tableContent

  const columnsLength = singleRow.length
  const rowsLength = tableContent.length

  const settingsMapper = {
    scoresLocked: {
      colSpan: columnsLength - 1,
      rowSpan: rowsLength,
    },
    providersLocked: {
      colSpan: 1,
      rowSpan: rowsLength,
    },
  }

  return settingsMapper[testVariant]
}
