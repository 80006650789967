import { getCookie } from '@which/shared'
import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache } from '@apollo/client'

import newrelic from 'newrelic'

import { dataProviderUrl } from './data-provider-url'

const graphQlUri = dataProviderUrl()
const graphQlUriPreview = dataProviderUrl(true)

export const apolloClient = ({
  target,
  cookie = '',
  userAgent = '',
  reqUrl = '',
  correlationId = '',
  preview = false,
}: ApolloClientProps) => {
  const sharedLinkConfig = {
    uri: preview ? graphQlUriPreview : graphQlUri,
    credentials: 'include',
  }
  const link =
    target === 'client'
      ? createHttpLink({
          ...sharedLinkConfig,
          headers: {
            // @TODO: This doesn't work - it will always be the original SSR request URL
            // ie it doesn't change on client side routing where we ask for data from data-provider again
            'req-url': window.location.href,
          },
        })
      : createHttpLink({
          ...sharedLinkConfig,
          headers: {
            cookie,
            'user-agent': userAgent,
            'req-url': reqUrl,
            'X-Correlation-Id': correlationId,
          },
        })

  const cache =
    target === 'client' ? new InMemoryCache().restore(window.__APOLLO_STATE__) : new InMemoryCache()

  return new ApolloClient({
    ssrMode: target === 'server',
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
      },
    },
    link: from([authorizationLink, newrelicLink, link]),
    cache,
    ssrForceFetchDelay: 100,
  })
}

///////// IMPLEMENTATION /////////

type ApolloClientProps = {
  target: 'client' | 'server'
  cookie?: string
  userAgent?: string
  reqUrl?: string
  correlationId?: string
  preview?: boolean
}

/**
 * This custom link extracts the blaize_session from the browsers cookies
 * and appends it under the authorization header to all GraphQL request context.
 * This is required as non-prod .internal urls are scoped to .internal and
 * data provider is currently not, therefor all request cookies are omitted.
 * This ensures data-provider has access to the blaize_session as long as it
 * exists in the browser.
 */
const authorizationLink = new ApolloLink((operation, forward) => {
  if (typeof window !== 'undefined') {
    const sessionId = getCookie(document.cookie, 'blaize_session')

    operation.setContext({
      headers: {
        'x-blaize-session': sessionId,
      },
    })
  }

  return forward(operation)
})

const newrelicLink = new ApolloLink((operation, forward) => {
  if (typeof newrelic !== 'undefined') {
    return newrelic.startSegment(
      `apollo-client: ${operation.operationName}`,
      false,
      (callback) =>
        forward(operation).map((data) => {
          if (typeof callback === 'function') {
            callback()
          }
          return data
        }),
      () => 'done'
    )
  }
  return forward(operation)
})
