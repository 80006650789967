import type { FunctionComponent } from 'react'
import React, { useState } from 'react'
import { Jumplinks as SBJumplinks } from '@which/seatbelt'

import { JumpLinkHeaderBar } from '../../../../shared/components/JumpLinkHeaderBar'
import type { ArticleReviewsPageData } from '../../article-page-types'
import styles from './JumpLinks.module.scss'

export const JumpLinks: FunctionComponent<Props> = ({
  links,
  handleOnClick,
  jumpLinkBarConfig,
}) => {
  const [jumpLinkBarHeight, setJumpLinkBarHeight] = useState(0)

  if (links.length <= 3) {
    return null
  }

  const scrollTo = (slug: string) => {
    const selector = `#${slug}`
    const header = document.querySelector(selector)

    if (header) {
      const scrollPos = window.scrollY + header.getBoundingClientRect().top

      window.scrollTo({
        top: jumpLinkBarConfig ? scrollPos - jumpLinkBarHeight : scrollPos,
        behavior: 'smooth',
      })
      window.history.pushState(null, '', selector)
    }
  }

  const RenderLinks = () => (
    <div className={styles.jumpLinksWrapper}>
      <SBJumplinks
        items={links.map(({ name, slug }) => ({
          text: name,
          path: `#${slug}`,
          onClick: (event: MouseEvent): void => {
            event.preventDefault()

            if (handleOnClick) {
              handleOnClick()

              setTimeout(() => {
                scrollTo(slug)
              }, 0)
            } else {
              scrollTo(slug)
            }
          },
        }))}
      />
    </div>
  )

  return (
    <>
      {jumpLinkBarConfig ? (
        <JumpLinkHeaderBar
          links={links}
          title={jumpLinkBarConfig.heading}
          articleType={jumpLinkBarConfig.articleType}
          jumpLinkBarHeightCallback={setJumpLinkBarHeight}
        >
          <RenderLinks />
        </JumpLinkHeaderBar>
      ) : (
        <RenderLinks />
      )}
    </>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  links: ArticleReviewsPageData['jumpLinks']
  jumpLinkBarConfig?: {
    heading: string
    articleType: string
  }
  handleOnClick?: () => void
}
