import type { FunctionComponent, ReactElement } from 'react'
import React, { useCallback, useState } from 'react'
import { SignUp, Table as t } from '@which/seatbelt'

import isPlainObject from 'lodash/isPlainObject'

// Reusing the images from the reviews pages for this test
// TODO: Move to shared location where both reviews and tables can use it
import { modalImageProps } from '../../../../../pages/reviews/shared/fixtures/signup'
import type { ShowMoreContentRenderer } from '../../../ShowMore/ShowMore'
import { SinglePadlockCta } from '../../../SinglePadlockCta'
import type { featureTableVariation } from '../../CsvTable'
import styles from '../../CsvTable.module.scss'
import { getVariantSettings } from '../utils/getVariantSettings'

export const CsvTableVariation: FunctionComponent<Props> = (data) => {
  const { testVariant, featureTableVariation, ...tableData } = data
  const defaultTableData = getInitialTableData(tableData)
  const [sortableTableData] = useState<CsvTableState>(defaultTableData)
  const { headings, sortedTableBody } = sortableTableData
  const [modalOpen, setModalOpen] = useState(false)

  const tableRenderer = useCallback<ShowMoreContentRenderer>(
    (children) => {
      const bodyContent = children as TableBodyContent[][]

      const { colSpan, rowSpan } = getVariantSettings(testVariant, bodyContent)

      return (
        <t.Table tableClassName="tableVariation" tableId={tableData.id}>
          <t.Head>
            <t.Row>
              {Object.values(headings).map((heading: Heading, columnIndex: number) => {
                return <t.Header key={`header_${columnIndex}`}>{heading.value}</t.Header>
              })}
            </t.Row>
          </t.Head>

          {testVariant === 'scoresLocked' ? (
            <t.Body>
              {bodyContent.map((row, rowIndex) =>
                isValid(row) ? (
                  <t.Row key={`row_${rowIndex}`}>
                    {row.map((bodyRowData: string | ArticleElement, cellIndex) => {
                      if (cellIndex === 0) {
                        return (
                          <t.Cell
                            key={`cell_${rowIndex}_${cellIndex}`}
                            align={getCellAlignment(bodyRowData)}
                          >
                            {isPlainObject(bodyRowData)
                              ? tableData.renderArticleElements(
                                  extendElementProps({
                                    bodyContent,
                                    rowIndex,
                                    bodyRowData: bodyRowData as ArticleElement,
                                    cellIndex,
                                    row,
                                  })
                                )
                              : bodyRowData}
                          </t.Cell>
                        )
                      }
                      if (rowIndex === 0 && cellIndex === 1) {
                        return (
                          <t.Cell
                            key={`cell_${rowIndex}_${cellIndex}`}
                            colSpan={colSpan}
                            rowSpan={rowSpan}
                            className="lockCtaCell scoresLocked"
                          >
                            <SinglePadlockCta
                              type="desktop"
                              setModalOpen={setModalOpen}
                              featureVariation={featureTableVariation}
                            />
                          </t.Cell>
                        )
                      }
                    })}
                  </t.Row>
                ) : null
              )}
            </t.Body>
          ) : (
            <t.Body>
              {bodyContent.map((row, rowIndex) =>
                isValid(row) ? (
                  <t.Row key={`row_${rowIndex}`}>
                    {row.map((bodyRowData: string | ArticleElement, cellIndex) => {
                      if (rowIndex === 0 && cellIndex === 0) {
                        return (
                          <t.Cell
                            key={`cell_${rowIndex}_${cellIndex}`}
                            colSpan={colSpan}
                            rowSpan={rowSpan}
                            className="lockCtaCell"
                          >
                            <SinglePadlockCta
                              type="desktop"
                              setModalOpen={setModalOpen}
                              featureVariation={featureTableVariation}
                            />
                          </t.Cell>
                        )
                      }

                      if (cellIndex > 0) {
                        return (
                          <t.Cell
                            key={`cell_${rowIndex}_${cellIndex}`}
                            align={getCellAlignment(bodyRowData)}
                          >
                            {isPlainObject(bodyRowData)
                              ? tableData.renderArticleElements(
                                  extendElementProps({
                                    bodyContent,
                                    rowIndex,
                                    bodyRowData: bodyRowData as ArticleElement,
                                    cellIndex,
                                    row,
                                  })
                                )
                              : bodyRowData}
                          </t.Cell>
                        )
                      }
                    })}
                  </t.Row>
                ) : null
              )}
            </t.Body>
          )}
        </t.Table>
      )
    },
    [featureTableVariation, headings, tableData, testVariant]
  )

  return (
    <div className={styles.csvTable}>
      <>
        {tableRenderer(sortedTableBody)}
        <SinglePadlockCta setModalOpen={setModalOpen} featureVariation={featureTableVariation} />
      </>

      {tableData?.notes && (
        <div
          data-testid="csv-table-note"
          className={styles.tableNotes}
          dangerouslySetInnerHTML={{ __html: tableData?.notes || '' }}
        />
      )}
      {modalOpen && (
        <SignUp
          className={styles.modal}
          image={modalImageProps}
          overline="Recommended"
          title="Full access"
          closeModal={() => setModalOpen(false)}
          data-testid="sign-up-modal"
          modalLinkDatalayerTrackingId="nstunlockedprovider"
        />
      )}
    </div>
  )
}

///////// IMPLEMENTATION /////////

const getCellAlignment = (bodyRowData: string | ArticleElement) => {
  if (typeof bodyRowData === 'string') {
    return 'auto'
  }

  return bodyRowData?.props?.['data-center-align'] === true ? 'centre' : 'auto'
}

export type ArticleElement = {
  sortableValue?: number | string
  tagName: string
  props?: Record<string, unknown>
  innerText?: string
  children?: ArticleElement[]
  component?: string
}

export type TableBodyContent = string | ArticleElement

type Props = {
  headings: string[]
  columnDataTypes: SortType[]
  tableBody: TableBodyContent[][]
  renderArticleElements: (x: ArticleElement[]) => ReactElement
  enableSearch?: boolean
  id?: string
  notes?: string
  testVariant?: string
  featureTableVariation?: featureTableVariation
}

type SortOption = 'default' | 'ascending' | 'descending'
type SortType = 'text' | 'stars' | 'percentage' | 'rich_cell'
type TableData = {
  headings: string[]
  columnDataTypes: SortType[]
  tableBody: TableBodyContent[][]
}

type Heading = {
  value: string
  columnDataType: SortType
  sortDirection: SortOption
}

type CsvTableState = {
  headings: {
    [x: number]: Heading
  }
  sortedTableBody: TableBodyContent[][]
}

type ExtendElementPropsArgs = {
  bodyRowData: ArticleElement
  rowIndex: number
  bodyContent: TableBodyContent[][]
  cellIndex: number
  row: TableBodyContent[]
}

const getInitialTableData = (tableData: TableData) => {
  const { headings, tableBody, columnDataTypes } = tableData

  const indexedHeadings = headings?.reduce((headingData, heading, index) => {
    return {
      ...headingData,
      [index]: {
        value: heading,
        columnDataType: columnDataTypes[index],
        sortDirection: 'default',
      },
    }
  }, {})

  return {
    headings: indexedHeadings,
    sortedTableBody: tableBody,
  }
}

const tooltipWillDisplayAbove = (index: number, bodyContent: TableBodyContent[][]) =>
  index + 1 > bodyContent.length / 2

const selectHorizontalAlignment = (index: number, rowData: TableBodyContent[]) => {
  if (index === 0) {
    return 'left'
  }

  if (index + 1 === rowData.length) {
    return 'right'
  }

  return undefined
}

const extendElementProps = ({
  bodyRowData,
  rowIndex,
  bodyContent,
  cellIndex,
  row,
}: ExtendElementPropsArgs) => [
  {
    ...bodyRowData,
    props: {
      ...bodyRowData.props,
      isTooltipDisplayedAboveOverride: tooltipWillDisplayAbove(rowIndex, bodyContent),
      tooltipAlignmentOverride: selectHorizontalAlignment(cellIndex, row),
    },
  },
]

const isValid = (row: TableBodyContent[]) => {
  const [firstElement] = row

  if (!firstElement) {
    return false
  }

  if (typeof firstElement !== 'string') {
    return !!firstElement.innerText || !!firstElement.props
  }

  return true
}
