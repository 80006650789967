import type { FunctionComponent } from 'react'
import React from 'react'
import { TagClickable, Typography } from '@which/seatbelt'

import type { Tag } from '../../../../generated/frontend'
import styles from './ArticleTags.module.scss'

export const ArticleTags: FunctionComponent<Props> = ({ tags, dataWhichId }) => {
  if (!tags?.length) {
    return null
  }

  return (
    <nav aria-labelledby="article-tags-label" data-testid="article-tags" className={styles.tags}>
      <Typography
        id="article-tags-label"
        tag="span"
        className={styles.label}
        textStyle={'small-print-compact'}
      >
        Tagged as:
      </Typography>
      <ul className={styles.list}>
        {tags.map(({ slug, label }) => {
          return (
            <li key={slug} className={styles.item}>
              <TagClickable href={`/news/tag/${slug}`} text={label} data-which-id={dataWhichId} />
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

///////// IMPLEMENTATION /////////

export type Props = { tags: Tag[] } & { dataWhichId?: string }
