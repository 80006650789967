import { CameraIcon, CarIcon, ServiceIcon, WashingMachineIcon, WifiIcon } from '@which/seatbelt'

export const faultyGoodsData = [
  {
    icon: WashingMachineIcon,
    label: 'A product',
    example: '(e.g furniture, TV, clothes)',
    type: 'product',
    url: '/tool/faulty-goods?type=product',
  },
  {
    icon: WifiIcon,
    label: 'A digital product',
    example: '(e.g films, games, apps)',
    type: 'digital',
    url: '/tool/faulty-goods?type=digital',
  },
  {
    icon: CarIcon,
    label: 'A car',
    example: '(new or used)',
    type: 'car',
    url: '/consumer-rights/advice/the-car-i-bought-has-a-problem-what-are-my-rights-aAnMC5b0ZzJb',
  },
]

export const s75Data = [
  {
    icon: CameraIcon,
    label: 'A product',
    example: '(e.g furniture, TV, clothes)',
    type: 'product',
    url: '/tool/section-75-and-chargeback?type=product',
  },
  {
    icon: ServiceIcon,
    label: 'A service',
    example: '(e.g carpet fitting)',
    type: 'service',
    url: '/tool/section-75-and-chargeback?type=service',
  },
]
