import './styles/index.scss'

import React from 'react'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import { cache, ReactRouterProvider, SSRProvider as ReactAriaSSRIdProvider } from '@which/seatbelt'
import { ApolloProvider } from '@apollo/client'

import { CacheProvider } from '@emotion/react'
import { loadableReady } from '@loadable/component'

import { App } from './App'
import * as serviceWorker from './serviceWorker'
import { apolloClient } from './shared/apollo-client'
import {
  ExperimentProviderWrapper,
  getExperimentInstanceClient,
  PreviewIndicator,
  WCDStatus,
} from './shared/components'
import { FullUrlProvider } from './shared/FullUrlContext'
import { getQueryString, isProd } from './shared/utils'
import { checkRoute } from './shared/utils/check-route'

const previewMode = getQueryString('preview') === 'true'
const client = apolloClient({ target: 'client', preview: previewMode })
const seatbeltLinkContext = {
  checkRoute,
  Link,
}
const getFullUrl = () => window.location.href
const allowedHosts = new RegExp(
  `^(localhost|(.*).which.co.uk|${process.env.LOCAL_IP_ADDRESS})$`,
  'gi'
)
allowedHosts.test(window.location.hostname) &&
  loadableReady(async () => {
    const featuresStr = window.__EXPERIMENTATION_FEATURES__
    const attributesStr = window.__EXPERIMENTATION_ATTRIBUTES__

    const experimentObj = getExperimentInstanceClient({
      featuresStr,
      attributesStr,
    })

    const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate

    renderMethod(
      <React.StrictMode>
        <HelmetProvider context={{}}>
          <CacheProvider value={cache}>
            <ExperimentProviderWrapper experimentObj={experimentObj}>
              <FullUrlProvider value={{ getFullUrl }}>
                <ReactRouterProvider value={seatbeltLinkContext}>
                  <ApolloProvider client={client}>
                    <Router>
                      <ReactAriaSSRIdProvider>
                        <App />
                      </ReactAriaSSRIdProvider>
                    </Router>
                  </ApolloProvider>
                </ReactRouterProvider>
              </FullUrlProvider>
              {!isProd() && typeof window !== 'undefined' && <WCDStatus />}
              <PreviewIndicator preview={previewMode} />
            </ExperimentProviderWrapper>
          </CacheProvider>
        </HelmetProvider>
      </React.StrictMode>,
      document.getElementById('root')
    )
  })

/*
 * Ensure all service workers are unregistered
 */
serviceWorker.unregister()
