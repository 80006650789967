import type { FunctionComponent, ReactElement } from 'react'
import React, { useCallback, useState } from 'react'
import { Table as t } from '@which/seatbelt'

import isPlainObject from 'lodash/isPlainObject'

import { useTableSearch } from '../../hooks/useTableSearch'
import { ShowMore } from '../ShowMore'
import type { ShowMoreContentRenderer } from '../ShowMore/ShowMore'
import styles from './CsvTable.module.scss'

export const CsvTableOriginal: FunctionComponent<Props> = (tableData) => {
  const defaultTableData = getInitialTableData(tableData)
  const [sortableTableData, setSortedBodyTable] = useState<CsvTableState>(defaultTableData)
  const [renderFullContent, setRenderFullContent] = useState<boolean>(false)
  const { headings, sortedTableBody } = sortableTableData
  const { TableSearch } = useTableSearch(tableData?.id, tableData.enableSearch)

  const onSort = useCallback(
    (columnIndex: number, currentSortState: SortOption) => {
      const sortStates: SortOption[] = ['descending', 'ascending']
      const currentSortStateIndex = sortStates.indexOf(currentSortState)
      const newSortState = sortStates[(currentSortStateIndex + 1) % 2]
      const targetHeading = headings[columnIndex]
      const newState = {
        ...sortableTableData,
        headings: {
          ...defaultTableData.headings,
          [columnIndex]: {
            ...targetHeading,
            sortDirection: newSortState,
          },
        },
        sortedTableBody: sortTable({
          columnIndex,
          tableBody: sortedTableBody,
          sortType: targetHeading.columnDataType as SortType,
          sortDirection: newSortState,
        }),
      }

      setSortedBodyTable(newState)
    },
    [defaultTableData.headings, headings, sortableTableData, sortedTableBody]
  )

  const tableRenderer = useCallback<ShowMoreContentRenderer>(
    (children) => {
      const bodyContent = children as TableBodyContent[][]

      return (
        <t.Table tableClassName="scrollable" tableId={tableData.id}>
          <t.Head>
            <t.Row>
              {Object.values(headings).map((heading: Heading, columnIndex: number) => (
                <t.Header
                  key={`header_${columnIndex}`}
                  sortDirection={heading.sortDirection}
                  onSort={() => {
                    onSort(columnIndex, heading.sortDirection)
                    setRenderFullContent(true)
                  }}
                >
                  {heading.value}
                </t.Header>
              ))}
            </t.Row>
          </t.Head>

          <t.Body>
            {bodyContent.map((row, rowIndex) =>
              isValid(row) ? (
                <t.Row key={`row_${rowIndex}`}>
                  {row.map((bodyRowData: string | ArticleElement, cellIndex) => (
                    <t.Cell
                      key={`cell_${rowIndex}_${cellIndex}`}
                      align={getCellAlignment(bodyRowData)}
                    >
                      {isPlainObject(bodyRowData)
                        ? tableData.renderArticleElements(
                            extendElementProps({
                              bodyContent,
                              rowIndex,
                              bodyRowData: bodyRowData as ArticleElement,
                              cellIndex,
                              row,
                            })
                          )
                        : bodyRowData}
                    </t.Cell>
                  ))}
                </t.Row>
              ) : null
            )}
          </t.Body>
        </t.Table>
      )
    },
    [headings, onSort, tableData]
  )

  return (
    <div className={styles.csvTable}>
      {tableData.enableSearch && (
        <div className={styles.csvTableSearchWrapper}>
          <TableSearch
            tableId={tableData.id}
            className={styles.csvTableSearchInput}
            setRenderFullContent={setRenderFullContent}
            renderFullContent={renderFullContent}
          />
        </div>
      )}

      <ShowMore
        className={styles.csvTableShowMoreWrapper}
        contentRenderer={tableRenderer}
        buttonLabel="Show all rows"
        renderFullContent={renderFullContent}
      >
        {sortedTableBody}
      </ShowMore>
      {tableData?.notes && (
        <div
          data-testid="csv-table-note"
          className={styles.tableNotes}
          dangerouslySetInnerHTML={{ __html: tableData?.notes || '' }}
        />
      )}
    </div>
  )
}

///////// IMPLEMENTATION /////////

const getCellAlignment = (bodyRowData: string | ArticleElement) => {
  if (typeof bodyRowData === 'string') {
    return 'auto'
  }

  return bodyRowData?.props?.['data-center-align'] === true ? 'centre' : 'auto'
}

type ArticleElement = {
  sortableValue?: number | string
  tagName: string
  props?: Record<string, unknown>
  innerText?: string
  children?: ArticleElement[]
}
type TableBodyContent = string | ArticleElement
type Props = {
  headings: string[]
  columnDataTypes: SortType[]
  tableBody: TableBodyContent[][]
  renderArticleElements: (x: ArticleElement[]) => ReactElement
  enableSearch?: boolean
  id?: string
  notes?: string
}

type SortOption = 'default' | 'ascending' | 'descending'
type SortType = 'text' | 'stars' | 'percentage' | 'rich_cell'
type TableData = {
  headings: string[]
  columnDataTypes: SortType[]
  tableBody: TableBodyContent[][]
}

type Heading = {
  value: string
  columnDataType: SortType
  sortDirection: SortOption
}

type CsvTableState = {
  headings: {
    [x: number]: Heading
  }
  sortedTableBody: TableBodyContent[][]
}

type SortTableArgs = {
  columnIndex: number
  tableBody: TableBodyContent[][]
  sortType: SortType
  sortDirection: SortOption
}

type ExtendElementPropsArgs = {
  bodyRowData: ArticleElement
  rowIndex: number
  bodyContent: TableBodyContent[][]
  cellIndex: number
  row: TableBodyContent[]
}

const getInitialTableData = (tableData: TableData) => {
  const { headings, tableBody, columnDataTypes } = tableData

  const indexedHeadings = headings?.reduce((headingData, heading, index) => {
    return {
      ...headingData,
      [index]: {
        value: heading,
        columnDataType: columnDataTypes[index],
        sortDirection: 'default',
      },
    }
  }, {})

  return {
    headings: indexedHeadings,
    sortedTableBody: tableBody,
  }
}

const sortTable = ({ columnIndex, tableBody, sortType, sortDirection }: SortTableArgs) => {
  const sortedTableBody = [...tableBody]
  const sortValue = sortType.toLowerCase()

  switch (sortValue) {
    case 'text': {
      const collator = Intl.Collator('en', { numeric: true, sensitivity: 'base' })

      return sortedTableBody.sort((a, b) => {
        const leftValue = getSortableValue(a[columnIndex], sortType)
        const rightValue = getSortableValue(b[columnIndex], sortType)

        return sortDirection === 'ascending'
          ? collator.compare(leftValue, rightValue)
          : collator.compare(rightValue, leftValue)
      })
    }
    case 'rich_cell': {
      return sortedTableBody.sort((a, b) => {
        const leftValue = getSortableValue(a[columnIndex], sortType)
        const rightValue = getSortableValue(b[columnIndex], sortType)

        if (leftValue === rightValue) {
          return 0
        }

        if (sortDirection === 'ascending') {
          return leftValue > rightValue ? 1 : -1
        }

        if (sortDirection === 'descending') {
          return leftValue < rightValue ? 1 : -1
        }

        return -1
      })
    }
    default: {
      return sortDirection === 'ascending'
        ? sortedTableBody.sort(
            (a, b) =>
              getSortableValue(a[columnIndex], sortType) -
              getSortableValue(b[columnIndex], sortType)
          )
        : sortedTableBody.sort(
            (a, b) =>
              getSortableValue(b[columnIndex], sortType) -
              getSortableValue(a[columnIndex], sortType)
          )
    }
  }
}

const getSortableValue = (value: TableBodyContent, sortType: SortType) => {
  if (value && (value['sortableValue'] || value['sortableValue'] === '')) {
    if (sortType === 'stars' && typeof value['sortableValue'] !== 'number') {
      return 0
    }

    return value['sortableValue']
  }

  switch (sortType) {
    case 'percentage':
      return value ? parseFloat(value as string) : ''
    case 'stars':
      return value ? (value as string).length : 0
    case 'text':
      return value ? (value as string).toLowerCase() : ''
    default:
      return value
  }
}

const tooltipWillDisplayAbove = (index: number, bodyContent: TableBodyContent[][]) =>
  index + 1 > bodyContent.length / 2

const selectHorizontalAlignment = (index: number, rowData: TableBodyContent[]) => {
  if (index === 0) {
    return 'left'
  }

  if (index + 1 === rowData.length) {
    return 'right'
  }

  return undefined
}

const extendElementProps = ({
  bodyRowData,
  rowIndex,
  bodyContent,
  cellIndex,
  row,
}: ExtendElementPropsArgs) => [
  {
    ...bodyRowData,
    props: {
      ...bodyRowData.props,
      isTooltipDisplayedAboveOverride: tooltipWillDisplayAbove(rowIndex, bodyContent),
      tooltipAlignmentOverride: selectHorizontalAlignment(cellIndex, row),
    },
  },
]

const isValid = (row: TableBodyContent[]) => {
  const [firstElement] = row

  if (!firstElement) {
    return false
  }

  if (typeof firstElement !== 'string') {
    return !!firstElement.innerText || !!firstElement.props
  }

  return true
}
