import type { FC } from 'react'
import React from 'react'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import type {
  ArticleProductCardRestrictedProps,
  CardedDynamicTableProduct,
} from '../CardedDynamicTable/CardedDynamicTable'
import { CardedDynamicTable } from '../CardedDynamicTable/CardedDynamicTable'

interface Props {
  products: CardedDynamicTableProduct[]
  logInCTA?: { url: string; label: string; copy: string }
  signUpCTA?: { url: string; label: string; smallPrint?: string }
  restricted?: ArticleProductCardRestrictedProps
}

export const GrowthBookWrapper: FC<Props> = ({ products, logInCTA, signUpCTA, restricted }) => {
  const showProductFeature = useFeatureIsOn('spp-show-product')

  return (
    <CardedDynamicTable
      products={products}
      logInCTA={logInCTA}
      signUpCTA={signUpCTA}
      trackingPrefix="spp"
      restricted={restricted}
      showProductFeature={showProductFeature}
    />
  )
}
