import type { ImageWithSources } from '@which/shared'

export const defaultImage: ImageWithSources = {
  src: 'https://media.product.which.co.uk/prod/images/original/d1de24e7da35-broadband-switch-new2x.png',
  alt: 'Compare broadband deals',
  sources: [
    {
      srcset:
        'https://media.product.which.co.uk/prod/images/ar_2to1_320x160/d1de24e7da35-broadband-switch-new2x.webp 320w, https://media.product.which.co.uk/prod/images/ar_2to1_500x250/d1de24e7da35-broadband-switch-new2x.webp 500w, https://media.product.which.co.uk/prod/images/ar_2to1_700x350/d1de24e7da35-broadband-switch-new2x.webp 700w, https://media.product.which.co.uk/prod/images/ar_2to1_900x450/d1de24e7da35-broadband-switch-new2x.webp 900w, https://media.product.which.co.uk/prod/images/ar_2to1_1200x600/d1de24e7da35-broadband-switch-new2x.webp 1200w, https://media.product.which.co.uk/prod/images/ar_2to1_1500x750/d1de24e7da35-broadband-switch-new2x.webp 1500w, https://media.product.which.co.uk/prod/images/ar_2to1_1800x900/d1de24e7da35-broadband-switch-new2x.webp 1800w, https://media.product.which.co.uk/prod/images/ar_2to1_2100x1050/d1de24e7da35-broadband-switch-new2x.webp 2100w, https://media.product.which.co.uk/prod/images/ar_2to1_2400x1200/d1de24e7da35-broadband-switch-new2x.webp 2400w',
      sizes:
        '(min-width: 1440px) 370px, (min-width: 1024px) 25vw, (min-width: 901px) 61vw, (min-width: 768px) 75vw, 90vw',
      type: '',
      media: '',
    },
  ],
}
