import type { FunctionComponent } from 'react'
import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import { GridItem } from '@which/seatbelt'

import { Resizer } from '../../resizeIFrame'

export const IframeEmbed: FunctionComponent<Props> = ({ content }) => {
  const iframeRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const fragment = document.createRange().createContextualFragment(content)
    iframeRef?.current?.append(fragment)
  }, [content])

  const resizeIframes = useCallback(() => {
    new Resizer('iframe.js-iframe-resizer')
  }, [])

  useLayoutEffect(() => {
    window.addEventListener('load', resizeIframes)

    return () => {
      window.removeEventListener('load', resizeIframes)
    }
  }, [resizeIframes])

  /**
   * Trigger resizeIframes a second time just in case to address flaky resizing
   * due to rendering race conditions and in particular on slower connections
   */
  useEffect(() => {
    setTimeout(resizeIframes, 5000)
  }, [resizeIframes])

  return (
    <GridItem>
      {content.includes('class="tableauPlaceholder"') ? (
        <div ref={iframeRef} />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: content }} />
      )}
    </GridItem>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  content: string
}
