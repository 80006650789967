import React from 'react'

import styles from './WCDStatus.module.scss'

export const CacheStatus = ({ cacheData }: Props) => (
  <details>
    <summary>Cache</summary>
    <div className={styles.content}>
      {cacheData?.error ? (
        <div className={styles.error}>
          Error fetching from cache-control status endpoint: {cacheData.error}
        </div>
      ) : (
        <>
          <div>
            Last full refresh - <strong>{cacheData.lastEnded}</strong>
          </div>
          <div>
            <strong>Glide events:</strong>
          </div>
          <ol>
            {cacheData.eventLog &&
              cacheData.eventLog.map((event, index) => {
                const updatedInfo = new Date(event.updatedAt).toISOString().split('T')
                return (
                  <li key={`${event.action}-${index}`}>
                    <details>
                      <summary>
                        {event.entity} {event.action} - {updatedInfo[0]}{' '}
                        {updatedInfo[1].slice(0, 8)}
                      </summary>
                      <div>
                        <pre>{JSON.stringify(event.entityBody, null, 2)}</pre>
                      </div>
                    </details>
                  </li>
                )
              })}
          </ol>
          <div>
            <strong>Full refresh summary:</strong>
          </div>
          <div>
            {cacheData.refreshStatus?.glide ? (
              Object.keys(cacheData.refreshStatus.glide).map((dataType, index) => {
                return (
                  <details key={`${dataType}-${index}`}>
                    <summary>{dataType}</summary>
                    <div>
                      <pre>{JSON.stringify(cacheData.refreshStatus?.glide[dataType], null, 2)}</pre>
                    </div>
                  </details>
                )
              })
            ) : (
              <div className={styles.error}>Glide object not found</div>
            )}
            {cacheData.refreshStatus?.globalFragments ? (
              <details>
                <summary>fragments</summary>
                <div>
                  <pre>{JSON.stringify(cacheData.refreshStatus.globalFragments, null, 2)}</pre>
                </div>
              </details>
            ) : (
              <span className={styles.error}>Fragments object not found</span>
            )}
          </div>
        </>
      )}
    </div>
  </details>
)

///////// IMPLEMENTATION /////////

type Props = {
  cacheData: CacheData
}

export type CacheData = {
  error?: string
  lastEnded?: string
  eventLog?: {
    entity: string
    action: string
    entityBody: Record<string, any>
    updatedAt: number
  }[]
  refreshStatus?: {
    glide: Record<string, CacheStatusData>
    globalFragments: CacheStatusData
  }
}

type CacheStatusData = {
  resultCount: number
  timeTakenMs: number
  timeUpdated: string
  status: Record<string, string>
}
