import type { FunctionComponent } from 'react'
import React from 'react'
import { CardContainer, Link, Picture, Typography } from '@which/seatbelt'

import type { ImageWithSources } from '../../../../generated/frontend'
import styles from './ProductHubProductTypes.module.scss'

export const ProductHubProductTypes: FunctionComponent<ProductTypesDataProps> = ({
  productTypes,
  sectionTitle,
}) => (
  <section aria-labelledby="product-types" data-testid="product-types" className="">
    <Typography
      tag="h2"
      textStyle="title-500"
      id="product-types"
      className={styles.productTypeTitle}
    >
      {sectionTitle}
    </Typography>
    <div className={styles.productTypeContainer}>
      {productTypes &&
        productTypes.map((productType, index) => {
          const {
            image,
            title: cardTitle,
            description: cardDescription,
            secondaryLink: { href, label, contentHtmlId },
          } = productType

          return (
            <div className={styles.productTypeCard} key={`product-type-${index + 1}`}>
              <CardContainer>
                <div className={styles.cardImg} data-testid="content-card-image">
                  <Picture {...image} lazyLoad />
                </div>
                <Typography
                  className={styles.cardTitle}
                  tag="h3"
                  textStyle="title-400"
                  data-testid="content-card-title"
                >
                  {cardTitle}
                </Typography>
                <Typography
                  className={styles.cardDesc}
                  textStyle="small-print-regular"
                  data-testid="content-card-description"
                >
                  {cardDescription}
                </Typography>
                <div className={styles.linksListContent} id={contentHtmlId}>
                  <Link
                    href={href}
                    containIcon
                    data-which-id="producthub-card"
                    data-section={sectionTitle}
                    data-subsection={label}
                    data-index={`${index + 1}`}
                  >
                    {label}
                  </Link>
                </div>
              </CardContainer>
            </div>
          )
        })}
    </div>
  </section>
)

export type ProductTypes = {
  title: string
  description: string
  image: ImageWithSources
  secondaryLink: {
    contentHtmlId: string
    href: string
    label: string
  }
}[]

export type ProductTypesDataProps = {
  sectionTitle: string
  productTypes: ProductTypes
}
