import type { FunctionComponent } from 'react'
import React from 'react'

import classnames from 'classnames'

import styles from './GridArticle.module.scss'

export const GridArticle: FunctionComponent<GridArticleProps> = ({ className, children }) => (
  <section data-testid="grid-article" className={classnames(styles.grid, className)}>
    {children}
  </section>
)

///////// IMPLEMENTATION /////////

export type GridArticleProps = {
  className?: string
}
