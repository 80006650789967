import type { FunctionComponent } from 'react'
import React from 'react'
import { StandardLink } from '@which/seatbelt'

import styles from './ThirdPartyCTA.module.scss'

export const ThirdPartyCTA: FunctionComponent<Props> = ({ linkText, linkUrl, children }) => {
  if (!children) {
    return null
  }

  return (
    <div className={styles.CTAContainer}>
      {children}
      {linkText && linkUrl ? (
        <StandardLink href={linkUrl} target="_blank">
          {linkText}
        </StandardLink>
      ) : null}
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  linkText?: string
  linkUrl?: string
}
