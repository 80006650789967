import type { FunctionComponent } from 'react'
import React from 'react'
import { Grid, GridItem, Typography } from '@which/seatbelt'

import { PIMoreFromItem } from '../PIMoreFromItem'
import type { PIMoreFromItemProps } from '../PIMoreFromItem/PIMoreFromItem'
import styles from './PIMoreFrom.module.scss'

export const PIMoreFrom: FunctionComponent<Props> = ({ title, cards }) => {
  const itemStartPosition = (index: number) => {
    if (cards.length === 1) {
      return 4
    } else if (cards.length === 2) {
      return index % 2 === 0 ? 2 : 7
    } else if ((index + 1) % 3 === 0) {
      return 9
    } else if ((index + 1) % 2 === 0) {
      return 5
    }
    return 1
  }
  const itemSpan = () => {
    if (cards.length === 1) {
      return 6
    }
    return cards.length > 2 ? 4 : 5
  }
  return (
    <Grid className={styles.piMoreFrom}>
      <GridItem span={{ large: 10 }} columnStart={{ large: 2 }}>
        <Typography
          data-testid="pi-more-from-title"
          tag="h1"
          textStyle="title-600"
          className={styles.piMoreFromTitle}
        >
          {title}
        </Typography>
      </GridItem>
      {cards.map((card, index) => (
        <GridItem
          span={{ large: itemSpan() }}
          columnStart={{
            large: itemStartPosition(index),
          }}
          key={`${card.title} ${index}`}
          data-testid="pi-more-from-item"
          className={styles.piMoreFromItemWrapper}
        >
          <PIMoreFromItem
            title={card.title}
            standfirst={card.standfirst}
            image={card.image}
            url={`policy-and-insight/${card.url}`}
          />
        </GridItem>
      ))}
    </Grid>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  title: string
  cards: PIMoreFromItemProps[]
}
