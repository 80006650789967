import type { ComponentPropsWithoutRef, ElementType, FunctionComponent } from 'react'
import React from 'react'
import { Button, PlusIcon } from '@which/seatbelt'

import classnames from 'classnames'

import styles from './MoreButton.module.scss'

export const MoreButton: FunctionComponent<Props> = ({
  dataTestId = 'more-button',
  hideButton,
  onClick,
  icon: Icon,
  isLoading = false,
  align = 'left',
  buttonLabel = 'Show more',
  className,
  ...rest
}) => {
  if (hideButton) {
    return null
  }

  const ButtonIcon = Icon || PlusIcon

  return (
    <div
      className={classnames(
        styles.moreButtonWrapper,
        styles[`moreButtonWrapper-${align}`],
        className
      )}
    >
      <Button
        data-testid={dataTestId}
        appearance="secondary"
        enableSpinner={isLoading}
        onClick={onClick}
        className={styles.moreButtonWrapperButton}
        {...rest}
      >
        <ButtonIcon className="sb-color-interactive-blue" />
        {buttonLabel}
      </Button>
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Alignment = 'left' | 'center'

type Props = {
  dataTestId?: string
  hideButton: boolean
  icon?: ElementType
  onClick: () => void
  isLoading?: boolean
  buttonLabel?: string
  align?: Alignment
} & ComponentPropsWithoutRef<'button'>
